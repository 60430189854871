import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Nav from "../navigation/Nav";
import Menu from "../navigation/Menu";
import "moment/locale/nl";
import { AiOutlineLeft } from "react-icons/ai";
import "../../scss/customers/create_customer.scss";
import "../../scss/customers/customer.scss";
import _ from "lodash";

//Api functions
import { create_customer, upload_image } from "../../functions/api.js";
import { BsPlusLg } from "react-icons/bs";

function EditCustomer() {
  const navigate = useNavigate();
  const location = useLocation();
  // Creating all the necessary states

  const [isSave, setIsSave] = useState(false);
  const [objectKeys] = useState({
    naam: "",
    nummer: "",
    telefoonnummer: "",
    bouwjaar: "",
    locatie: {
      adres: "",
      huisnummer: "",
      postcode: "",
      plaats: "",
    },
    contactpersoon: {
      naam: "",
      telefoon: "",
      email: "",
    },
    media: {
      mediaid: "",
      naam: "",
      url: "",
      website: "",
    },
  });

  let [validationError, setValdationError] = useState([]);
  const [objectenArr, setObjectenArr] = useState([]);

  useEffect(() => {
    const { customer } = location.state;
    setObjectenArr(customer.objecten);
    for (let key in customer) {
      if (_.isObject(customer[key])) {
        for (let sKey in customer[key]) {
          addUserInput(sKey, customer[key][sKey]);
        }
      } else {
        addUserInput(key, customer[key]);
      }
    }
  }, [location]);

  let [validationMessage] = useState({
    telefoon: new Set(),
    email: new Set(),
    klantnaam: new Set(),
    bedrijfsnaam: new Set(),
    website: new Set(),
    huisnummer: new Set(),
    adres: new Set(),
    postcode: new Set(),
    plaats: new Set(),
  });

  let [userInput, setUserInput] = useState({
    id: "",
    active: "",
    telefoon: "",
    email: "",
    website: "",
    klantnaam: "",
    bedrijfsnaam: "",
    adres: "",
    huisnummer: "",
    postcode: "",
    plaats: "",
    locatieid: "",
  });

  const addInput = () => {
    setObjectenArr((s) => {
      return [
        ...s,
        {
          naam: "",
          nummer: "",
          telefoonnummer: "",
          bouwjaar: "",
          klantid: userInput.id,
          objectid: "",
          locatie: {
            locatieid: "",
            locatienaam: "",
            adres: userInput.adres,
            huisnummer: userInput.huisnummer,
            postcode: userInput.postcode,
            plaats: userInput.plaats,
          },
          contactpersoon: {
            naam: userInput.klantnaam,
            telefoon: userInput.telefoon,
            email: userInput.email,
            contactpersoonid: "",
          },
          media: {
            mediaid: "",
            naam: "",
            url: "",
            website: "",
          },
        },
      ];
    });
  };

  let removeFormFields = (index) => {
    let newFormValues = [...objectenArr];
    newFormValues.splice(index, 1);
    let newValidationError = validationError;
    let validationErr = [];
    for (let i = 0; i < newValidationError.length; i++) {
      const element = newValidationError[i];
      if (_.isObject(element)) {
        let elementKey = Object.keys(element)[0];
        if (!elementKey.includes(index)) {
          validationErr.push(element);
        }
      } else {
        validationErr.push(element);
      }
    }
    setValdationError(validationErr);
    setObjectenArr(newFormValues);
  };

  let removeImage = (object, index) => {
    const img = document.getElementById(`media-url-${index}`);
    const imgWrapper = document.querySelector("#media-wrapper-" + index);
    imgWrapper.classList.add("input-color");
    imgWrapper.classList.remove("validationSuccess");
    imgWrapper.classList.remove("validationError");

    img.removeAttribute("src");
    let newFormValues = objectenArr;
    object.media = {
      mediaid: "",
      naam: "",
      url: "",
      website: "",
    };

    // newFormValues[index] = object
    // setUserInput((prevState) => { return { ...prevState, [inputName]: inputValue } });

    setObjectenArr([...newFormValues, (newFormValues[index] = object)]);
  };

  let validateEmptyObj = (objArray) => {
    let count = 0;
    for (let i = 0; i < objArray.length; i++) {
      const element = objArray[i];
      for (let k in objectKeys) {
        if (k === "telefoonnummer" || k === "telefoon") {
          continue;
        }
        if (_.isObject(k)) {
          for (let l in objectKeys[k]) {
            if (element[k][l] === "") {
              count += 1;
            }
          }
        }
        if (element[k] === "") {
          count += 1;
        }
      }
    }

    if (count > 0) {
      return true;
    } else {
      return false;
    }
  };

  const addUserInput = (inputName, inputValue) => {
    try {
      setUserInput((prevState) => {
        return { ...prevState, [inputName]: inputValue };
      });
    } catch (error) {
      console.warn("ff", error);
    }
  };

  const addNewObjectInput = (name, value, index, type) => {
    const list = [...objectenArr];
    if (type) {
      list[index][type] = { ...list[index][type], [name]: value };
    } else {
      list[index][name] = value;
    }

    setObjectenArr(list);
  };

  const submitData = async () => {
    setIsSave(true);
    inputValidation(1);

    if (validationError.length === 0) {
      setIsSave(false);
      // let isObjEmpty = validateEmptyObj(objectenArr);

      let object = {
        id: userInput.id,
        active: userInput.active,
        telefoon: userInput.telefoon,
        email: userInput.email ?? "",
        website: userInput.website,
        klantnaam: userInput.klantnaam,
        bedrijfsnaam: userInput.bedrijfsnaam,
        locatie: {
          locatieid: userInput.locatieid,
          locatienaam: "",
          adres: userInput.adres,
          huisnummer: userInput.huisnummer,
          postcode: userInput.postcode,
          plaats: userInput.plaats,
        },
        objecten: objectenArr,
      };

      await create_customer(object);
      return navigate("/customers");
    } else {
      inputValidation(1);
    }
  };

  const addValidationError = (inputName) => {};

  const inputValidation = (step) => {};

  const uploadPhoto = async (event, index, type) => {
    const img = event.target.files[0];

    const response = upload_image(img);

    response.then((data) => {
      addNewObjectInput(type, data.response, index);
      imgValidation(index);
    });
  };

  const imgValidation = (index) => {
    const imgWrapper = document.querySelector("#media-wrapper-" + index);
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (imgWrapper === null) return;
    setTimeout(() => {
      let imagePath = imgWrapper.firstChild.value;
      if (!allowedExtensions.exec(imagePath)) {
        imgWrapper.classList.remove("input-color");
        imgWrapper.classList.remove("validationSuccess");
        imgWrapper.classList.add("validationError");
        return;
      }
      if (objectenArr[index].media.naam !== "") {
        imgWrapper.classList.remove("input-color");
        imgWrapper.classList.remove("validationError");
        imgWrapper.classList.add("validationSuccess");
        document.getElementById("media-mediaid-" + index).innerHTML = "";
      } else {
        imgWrapper.classList.add("input-color");
        imgWrapper.classList.remove("validationSuccess");
        imgWrapper.classList.remove("validationError");
      }

      if (validationBorder("media-mediaid-" + index)) {
        imgWrapper.classList.remove("input-color");
        imgWrapper.classList.add("validationError");
      }
    }, 100);
  };

  function findPosition(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));

      return [currenttop - 250];
    }
  }

  function scrolldiv(textId) {
    window.scroll(0, findPosition(document.getElementById(textId)));
  }

  const setValidationErrorMessage = (step, errorKeyAndValue) => {
    const form_data_1_message = validationMessage;
    // const form_data_2_message = validationMessage.form_step_2_message;

    if (step === 1) {
      if (errorKeyAndValue && errorKeyAndValue !== undefined) {
        form_data_1_message[Object.keys(errorKeyAndValue)].add(
          errorKeyAndValue[Object.keys(errorKeyAndValue)]
        );
      }
    }
  };

  const displayValidationErrorMessage = (step, textId) => {
    // if (
    //   textId !== "telefoon" &&
    //   textId !== "telefoonnummer" &&
    //   textId !== "website" &&
    //   textId !== "bouwjaar"
    // ) {
    //   const form_data_1_message = validationMessage;
    //   let validationErrorForm1 = form_data_1_message[textId];
    //   if (
    //     document.getElementById(textId) === undefined ||
    //     document.getElementById(textId === null)
    //   ) {
    //     return;
    //   }
    //   setTimeout(() => {
    //     if (form_data_1_message[textId] !== undefined) {
    //       if (step === 1) {
    //         document.getElementById(textId).innerHTML = "";
    //         if (validationErrorForm1 === undefined) {
    //           return;
    //         }
    //         const validationObject = validationErrorForm1.values();
    //         const validationValues = validationObject.next();
    //         if (validationValues.value === undefined) {
    //           return;
    //         }
    //         document.getElementById(textId).innerHTML = validationValues.value;
    //         if (isSave) {
    //           scrolldiv(textId);
    //           setIsSave(false);
    //         }
    //       }
    //     }
    //   }, 100);
    // }
  };

  const validationBorder = (key, index) => {
    if (
      key.split("-")[0] !== "telefoonnummer" ||
      key.split("-")[0] !== "telefoon" ||
      key.split("-")[1] !== "telefoon" ||
      key.split("-")[0] !== "bouwjaar"
    ) {
      let data = validationError.filter((e) => {
        if (e[key]) {
          return e;
        }
      });

      if (data.length > 0) {
        document.getElementById(key).innerHTML = data[0][key];
        if (isSave) {
          scrolldiv(key);
          setIsSave(false);
        }
        return true;
      } else {
        let ele = document.getElementById(key);
        if (ele) {
          document.getElementById(key).innerHTML = "";
          return false;
        }
      }
    }
  };

  return (
    <div className="select-customer-container-main">
      <Nav page="Rapportages" />
      <div className="page-wrap-row">
        <Menu className="menu-container" />
        <div className="create-customer">
          <Link
            className="fixed bottom-0 left-0 ml-4 mb-4"
            to="/customers"
            style={{
              textDecoration: "none",
              color: "#757575",
              width: "85px",
            }}
          >
            <p className="text-base flex flex-row">
              <AiOutlineLeft className="mt-1" />
              terug
            </p>
          </Link>
          <h1 className="customer-header mt-12 md:mt-10 lg:mt-8">Klant</h1>
          <hr className="hr2" />
          <div>
            <div className="input-section-row">
              <div className="input-wrapper">
                <label className="label-customer" htmlFor="">
                  Naam
                </label>
                <div
                  className={
                    validationError.includes("klantnaam")
                      ? "validationError"
                      : "input-color"
                  }
                >
                  <input
                    type="text"
                    id="klant-naam"
                    value={userInput.klantnaam}
                    onChange={(e) =>
                      addUserInput("klantnaam", e.target.value, e)
                    }
                  />
                </div>
                <p className="validationMessage" id="klantnaam">
                  {displayValidationErrorMessage(1, "klantnaam")}
                </p>
              </div>
              <div className="input-wrapper">
                <label className="label-customer" htmlFor="">
                  Bedrijfsnaam
                </label>
                <div
                  className={
                    validationError.includes("bedrijfsnaam")
                      ? "validationError"
                      : "input-color"
                  }
                >
                  <input
                    type="text"
                    id="bedrijfs-naam"
                    value={userInput.bedrijfsnaam}
                    onChange={(e) =>
                      addUserInput("bedrijfsnaam", e.target.value, e)
                    }
                    autoComplete="on"
                  />
                </div>
                <p className="validationMessage" id="bedrijfsnaam">
                  {displayValidationErrorMessage(1, "bedrijfsnaam")}
                </p>
              </div>
            </div>
            <div className="customer-input-section">
              <label className="label-customer" htmlFor="">
                Adres (straat)
              </label>
              <div
                className={
                  validationError.includes("adres")
                    ? "validationError"
                    : "input-color"
                }
              >
                <input
                  type="text"
                  id="adres-straat"
                  value={userInput.adres}
                  onChange={(e) => addUserInput("adres", e.target.value, e)}
                  autoComplete="on"
                />
              </div>
              <p className="validationMessage" id="adres">
                {displayValidationErrorMessage(1, "adres")}
              </p>
            </div>
            <div className="input-section-row">
              <div className="input-wrapper">
                <label className="label-customer" htmlFor="">
                  Postcode
                </label>
                <div
                  className={
                    validationError.includes("postcode")
                      ? "validationError"
                      : "input-color"
                  }
                >
                  <input
                    type="text"
                    id="post-code"
                    value={userInput.postcode}
                    onChange={(e) =>
                      addUserInput("postcode", e.target.value, e)
                    }
                    autoComplete="on"
                  />
                </div>
                <p className="validationMessage" id="postcode">
                  {displayValidationErrorMessage(1, "postcode")}
                </p>
              </div>
              <div className="input-wrapper">
                <label className="label-customer" htmlFor="">
                  Huisnummer
                </label>
                <div
                  className={
                    validationError.includes("huisnummer")
                      ? "validationError"
                      : "input-color"
                  }
                >
                  <input
                    type="text"
                    name="huisnummer"
                    value={userInput.huisnummer}
                    onChange={(e) =>
                      addUserInput("huisnummer", e.target.value, e)
                    }
                    autoComplete="on"
                  />
                </div>
                <p className="validationMessage" id="huisnummer">
                  {displayValidationErrorMessage(1, "huisnummer")}
                </p>
              </div>
              <div className="input-wrapper">
                <label className="label-customer" htmlFor="">
                  Plaats
                </label>
                <div
                  className={
                    validationError.includes("plaats")
                      ? "validationError"
                      : "input-color"
                  }
                >
                  <input
                    type="text"
                    name="plaats"
                    value={userInput.plaats}
                    onChange={(e) => addUserInput("plaats", e.target.value)}
                    autoComplete="on"
                  />
                </div>
                <p className="validationMessage" id="plaats">
                  {displayValidationErrorMessage(1, "plaats")}
                </p>
              </div>
            </div>
            <div className="input-section-row">
              <div className="input-wrapper">
                <label className="label-customer" htmlFor="">
                  Telefoon
                </label>
                <div
                  className={
                    validationError.includes("telefoon")
                      ? "validationError"
                      : "input-color"
                  }
                >
                  <input
                    type="text"
                    name="telefoon"
                    value={userInput.telefoon}
                    onChange={(e) => addUserInput("telefoon", e.target.value)}
                    autoComplete="on"
                  />
                </div>
                <p className="validationMessage" id="telefoon">
                  {displayValidationErrorMessage(1, "telefoon")}
                </p>
              </div>
              <div className="input-wrapper">
                <label className="label-customer" htmlFor="">
                  Email
                </label>
                <div
                  className={
                    validationError.includes("email")
                      ? "validationError"
                      : "input-color"
                  }
                >
                  <input
                    type="text"
                    name="email"
                    value={userInput.email}
                    onChange={(e) => addUserInput("email", e.target.value)}
                    autoComplete="on"
                  />
                </div>
                <p className="validationMessage" id="email">
                  {displayValidationErrorMessage(1, "email")}
                </p>
              </div>
            </div>
            <div className="customer-input-section">
              <label className="label-customer" htmlFor="">
                Website
              </label>
              <div
                className={
                  validationError.includes("website")
                    ? "validationError"
                    : "input-color"
                }
              >
                <input
                  type="text"
                  name="website"
                  value={userInput.website}
                  onChange={(e) => addUserInput("website", e.target.value)}
                  autoComplete="on"
                />
              </div>
              <p className="validationMessage" id="website">
                {displayValidationErrorMessage(1, "website")}
              </p>
            </div>
            <div className="object-wrapper">
              <div
                className="new-object"
                style={{ cursor: "pointer" }}
                onClick={(e) => addInput()}
              >
                <BsPlusLg size={25} />
                <div className="new-object-text">
                  <h2>Nieuw object</h2>
                </div>
              </div>
            </div>
            <hr className="hr2" />
            {objectenArr.length > 0 ? (
              <div>
                <h1 className="customer-header">New Object</h1>
                {objectenArr.map((_, index) => {
                  return (
                    <>
                      <div className="remove-customer">
                        <button
                          style={{ marginBottom: 10 }}
                          className="submit-customer"
                          onClick={() => removeFormFields(index)}
                        >
                          Verwijder
                        </button>
                      </div>
                      <div className="input-section-row">
                        <div className="input-wrapper">
                          <label className="label-customer">
                            Object Foto (JPEG,JPG,PNG)
                          </label>
                          <div
                            id={"media-wrapper-" + index}
                            className="input-color"
                          >
                            <input
                              type="file"
                              accept=".jpeg, .png, .jpg"
                              value={objectenArr[index].media.name}
                              onChange={(event) =>
                                uploadPhoto(event, index, "media")
                              }
                              id={"customer-media-id"}
                              aria-label="File browser example"
                            />
                            <span className="file-custom" />
                          </div>
                          <p
                            className="validationMessage"
                            id={"media-mediaid-" + index}
                          >
                            {validationBorder("media-mediaid-" + index)}
                            {imgValidation(index)}
                          </p>
                        </div>
                      </div>
                      {objectenArr[index].media.url !== "" ? (
                        <>
                          <div className="input-section-row">
                            <div className="input-wrapper-image">
                              <img
                                id={"media-url-" + index}
                                src={`https://api.ezinspect.nl/${objectenArr[index].media.url}`}
                              ></img>
                            </div>
                          </div>

                          <div className="input-section-row">
                            <div className="input-wrapper">
                              <button
                                style={{ marginBottom: 10 }}
                                className="submit-customer"
                                onClick={() =>
                                  removeImage(objectenArr[index], index)
                                }
                              >
                                Verwijder
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="input-section-row">
                        <div className="input-wrapper">
                          <label className="label-customer" htmlFor="">
                            Object naam
                          </label>
                          <div
                            className={
                              validationBorder(
                                "naam-" + index
                              ) /* validationError.includes("naam-" + index) */
                                ? "validationError"
                                : "input-color"
                            }
                          >
                            <input
                              type="text"
                              id={`objecten-naam`}
                              value={objectenArr[index].naam}
                              onChange={(e) =>
                                addNewObjectInput("naam", e.target.value, index)
                              }
                              autoComplete="on"
                            />
                          </div>
                          <p className="validationMessage" id={"naam-" + index}>
                            {validationBorder("naam-" + index)}
                          </p>
                        </div>
                        <div className="input-wrapper">
                          <label className="label-customer" htmlFor="">
                            Object nummer
                          </label>
                          <div
                            className={
                              validationBorder("nummer-" + index)
                                ? "validationError"
                                : "input-color"
                            }
                          >
                            <input
                              type="text"
                              id="object-nummer"
                              value={objectenArr[index].nummer}
                              onChange={(e) =>
                                addNewObjectInput(
                                  "nummer",
                                  e.target.value,
                                  index
                                )
                              }
                              autoComplete="on"
                            />
                          </div>
                          <p
                            className="validationMessage"
                            id={"nummer-" + index}
                          >
                            {validationBorder("nummer-" + index)}
                          </p>
                        </div>
                      </div>
                      <div className="customer-input-section">
                        <label className="label-customer" htmlFor="">
                          Adres
                        </label>
                        <div
                          className={
                            validationBorder("locatie-adres-" + index)
                              ? "validationError"
                              : "input-color"
                          }
                        >
                          <input
                            type="text"
                            id="adres-straat"
                            value={objectenArr[index].locatie.adres}
                            onChange={(e) =>
                              addNewObjectInput(
                                "adres",
                                e.target.value,
                                index,
                                "locatie"
                              )
                            }
                            autoComplete="on"
                          />
                        </div>
                        <p
                          className="validationMessage"
                          id={"locatie-adres-" + index}
                        >
                          {validationBorder("locatie-adres-" + index)}
                        </p>
                      </div>
                      <div className="input-section-row">
                        <div className="input-wrapper">
                          <label className="label-customer" htmlFor="">
                            Huisnummer
                          </label>
                          <div
                            className={
                              validationBorder("locatie-huisnummer-" + index)
                                ? "validationError"
                                : "input-color"
                            }
                          >
                            <input
                              type="text"
                              value={objectenArr[index].locatie.huisnummer}
                              onChange={(e) =>
                                addNewObjectInput(
                                  "huisnummer",
                                  e.target.value,
                                  index,
                                  "locatie"
                                )
                              }
                              autoComplete="on"
                            />
                          </div>
                          <p
                            className="validationMessage"
                            id={"locatie-huisnummer-" + index}
                          >
                            {validationBorder("locatie-huisnummer-" + index)}
                          </p>
                        </div>
                        <div className="input-wrapper">
                          <label className="label-customer" htmlFor="">
                            Postcode
                          </label>
                          <div
                            className={
                              validationBorder("locatie-postcode-" + index)
                                ? "validationError"
                                : "input-color"
                            }
                          >
                            <input
                              type="text"
                              id="post-code"
                              value={objectenArr[index].locatie.postcode}
                              onChange={(e) =>
                                addNewObjectInput(
                                  "postcode",
                                  e.target.value,
                                  index,
                                  "locatie"
                                )
                              }
                              autoComplete="on"
                            />
                          </div>
                          <p
                            className="validationMessage"
                            id={"locatie-postcode-" + index}
                          >
                            {validationBorder("locatie-postcode-" + index)}
                          </p>
                        </div>
                        <div className="input-wrapper">
                          <label className="label-customer" htmlFor="">
                            Vestigingsplaats
                          </label>
                          <div
                            className={
                              validationBorder("locatie-plaats-" + index)
                                ? "validationError"
                                : "input-color"
                            }
                          >
                            <input
                              type="text"
                              value={objectenArr[index].locatie.plaats}
                              onChange={(e) =>
                                addNewObjectInput(
                                  "plaats",
                                  e.target.value,
                                  index,
                                  "locatie"
                                )
                              }
                              autoComplete="on"
                            />
                          </div>
                          <p
                            className="validationMessage"
                            id={"locatie-plaats-" + index}
                          >
                            {validationBorder("locatie-plaats-" + index)}
                          </p>
                        </div>
                      </div>
                      <div className="input-section-row">
                        <div className="input-wrapper">
                          <label className="label-customer" htmlFor="">
                            Telefoon
                          </label>
                          <div
                            className={
                              validationBorder("telefoonnummer-" + index)
                                ? "validationError"
                                : "input-color"
                            }
                          >
                            <input
                              type="text"
                              value={objectenArr[index].telefoonnummer}
                              onChange={(e) =>
                                addNewObjectInput(
                                  "telefoonnummer",
                                  e.target.value,
                                  index
                                )
                              }
                              autoComplete="on"
                            />
                          </div>
                          <p
                            className="validationMessage"
                            id={"telefoonnummer-" + index}
                          >
                            {validationBorder("telefoonnummer-" + index)}
                          </p>
                        </div>
                        <div className="input-wrapper">
                          <label className="label-customer" htmlFor="">
                            Bouwjaar
                          </label>
                          <div
                            className={
                              validationBorder("bouwjaar-" + index)
                                ? "validationError"
                                : "input-color"
                            }
                          >
                            <input
                              type="text"
                              value={objectenArr[index].bouwjaar}
                              onChange={(e) =>
                                addNewObjectInput(
                                  "bouwjaar",
                                  e.target.value,
                                  index
                                )
                              }
                              autoComplete="on"
                            />
                          </div>
                          <p
                            className="validationMessage"
                            id={"bouwjaar-" + index}
                          >
                            {validationBorder("bouwjaar-" + index)}
                          </p>
                        </div>
                      </div>
                      <h1 className="customer-header">Contactgegevens</h1>
                      <div className="input-section-row">
                        <div className="input-wrapper">
                          <label className="label-customer" htmlFor="">
                            Naam
                          </label>
                          <div
                            className={
                              validationBorder("contactpersoon-naam-" + index)
                                ? "validationError"
                                : "input-color"
                            }
                          >
                            <input
                              type="text"
                              id="contactpersoon-naam"
                              value={objectenArr[index].contactpersoon.naam}
                              onChange={(e) =>
                                addNewObjectInput(
                                  "naam",
                                  e.target.value,
                                  index,
                                  "contactpersoon"
                                )
                              }
                              autoComplete="on"
                            />
                          </div>
                          <p
                            className="validationMessage"
                            id={"contactpersoon-naam-" + index}
                          >
                            {validationBorder("contactpersoon-naam-" + index)}
                          </p>
                        </div>
                        <div className="input-wrapper">
                          <label className="label-customer" htmlFor="">
                            Telefoon
                          </label>
                          <div
                            className={
                              validationBorder(
                                "contactpersoon-telefoon-" + index
                              )
                                ? "validationError"
                                : "input-color"
                            }
                          >
                            <input
                              type="text"
                              id="contactpersoon-telefoon"
                              value={objectenArr[index].contactpersoon.telefoon}
                              onChange={(e) =>
                                addNewObjectInput(
                                  "telefoon",
                                  e.target.value,
                                  index,
                                  "contactpersoon"
                                )
                              }
                              autoComplete="on"
                            />
                          </div>
                          <p
                            className="validationMessage"
                            id={"contactpersoon-telefoon-" + index}
                          >
                            {validationBorder(
                              "contactpersoon-telefoon-" + index
                            )}
                          </p>
                        </div>
                        <div className="input-wrapper">
                          <label className="label-customer" htmlFor="">
                            Email
                          </label>
                          <div
                            className={
                              validationBorder("contactpersoon-email-" + index)
                                ? "validationError"
                                : "input-color"
                            }
                          >
                            <input
                              type="text"
                              id="contactpersoon-email"
                              value={objectenArr[index].contactpersoon.email}
                              onChange={(e) =>
                                addNewObjectInput(
                                  "email",
                                  e.target.value,
                                  index,
                                  "contactpersoon"
                                )
                              }
                              autoComplete="on"
                            />
                          </div>
                          <p
                            className="validationMessage"
                            id={"contactpersoon-email-" + index}
                          >
                            {validationBorder("contactpersoon-email-" + index)}
                          </p>
                        </div>
                      </div>
                      <hr className="hr2" />
                    </>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
            <div style={{ height: "100px" }}>
              <br />
              <button
                style={{ marginBottom: 10 }}
                className="submit-customer"
                onClick={submitData}
              >
                Bewerken
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCustomer;
