import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Axios from "axios";

//Css
import "../../scss/registration/registration_forum.scss";

//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

//Modal
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

//Api functions
import { upload_image } from "../../functions/api.js";
import { register } from "../../functions/api.js";

const Registration_forum = () => {
  let [scopeActive, setScopeActive] = useState([]);
  let [checked, setChecked] = useState(true);
  const [passwordShown, setPasswordShown] = useState("");
  let [form_step, set_form_step] = useState(1);
  let [validationError, setValdationError] = useState([]);
  let [registerResponse, setRegisterResponse] = useState("");
  const [showPaymentRequirements, setShowPaymentRequirements] = useState(false);

  const navigate = useNavigate();

  //Modal state management password requirements modal
  const handleClosePasswordRequirements = () =>
    setShowPaymentRequirements(false);

  //Submit on enter key
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter") {
        event.preventDefault();
        nextFormStep();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  });

  let [validationMessage] = useState({
    form_step_1_message: {
      company_name: new Set(),
      first_name: new Set(),
      infix: new Set(),
      last_name: new Set(),
      email: new Set(),
      phone_number: new Set(),
      password1: new Set(),
      password2: new Set(),
    },
    form_step_2_message: {
      adress: new Set(),
      postal_code: new Set(),
      city: new Set(),
      country: new Set(),
      vat_number: new Set(),
      logo: new Set(),
      terms: new Set(),
    },
  });

  let [userInput, setUserInput] = useState([
    {
      form_step_1: {
        company_name: "",
        first_name: "",
        infix: "",
        last_name: "",
        email: "",
        phone_number: "",
        password1: "",
        password2: "",
      },
      form_step_2: {
        adress: "",
        postal_code: "",
        city: "",
        country: "",
        vat_number: "",
        logo: "",
        terms: "",
      },
      form_step_3: {
        found_message: "",
        scopes: {},
      },
    },
  ]);

  const addUserInput = (inputName, inputValue) => {
    switch (inputName) {
      case "company_name":
        userInput[0].form_step_1.company_name = inputValue;
        setUserInput(userInput);
        break;
      case "first_name":
        userInput[0].form_step_1.first_name = inputValue;
        setUserInput(userInput);
        break;
      case "infix":
        userInput[0].form_step_1.infix = inputValue;
        setUserInput(userInput);
        break;
      case "last_name":
        userInput[0].form_step_1.last_name = inputValue;
        setUserInput(userInput);
        break;
      case "email":
        userInput[0].form_step_1.email = inputValue;
        setUserInput(userInput);
        break;
      case "phone_number":
        userInput[0].form_step_1.phone_number = inputValue;
        setUserInput(userInput);
        break;
      case "password1":
        userInput[0].form_step_1.password1 = inputValue;
        setUserInput(userInput);
        break;
      case "password2":
        userInput[0].form_step_1.password2 = inputValue;
        setUserInput(userInput);
        break;
      case "adress":
        userInput[0].form_step_2.adress = inputValue;
        setUserInput(userInput);
        break;
      case "postal_code":
        userInput[0].form_step_2.postal_code = inputValue;
        setUserInput(userInput);
        break;
      case "city":
        userInput[0].form_step_2.city = inputValue;
        setUserInput(userInput);
        break;
      case "country":
        userInput[0].form_step_2.country = inputValue;
        setUserInput(userInput);
        break;
      case "vat_number":
        userInput[0].form_step_2.vat_number = inputValue;
        setUserInput(userInput);
        break;
      case "logo":
        userInput[0].form_step_2.logo = inputValue;
        setUserInput(userInput);
        break;
      case "terms":
        userInput[0].form_step_2.terms = inputValue;
        setUserInput(userInput);
        break;
      case "found_message":
        userInput[0].form_step_3.found_message = inputValue;
        setUserInput(userInput);
        break;
      case "scopes":
        userInput[0].form_step_3.scopes = inputValue;
        setUserInput(userInput);
        break;
    }
  };

  const togglePassword = (passwordField) => {
    setPasswordShown(passwordField);
  };

  const addScope = (scopeName) => {
    if (scopeActive.length === 0) {
      setScopeActive([scopeName]);
    }

    for (let i = 0; i <= scopeActive.length; i++) {
      if (scopeActive[i] === scopeName) {
        scopeActive = scopeActive
          .slice(0, i)
          .concat(scopeActive.slice(i + 1, scopeActive.length));
        setScopeActive(scopeActive);
        break;
      }
      setScopeActive([...scopeActive, scopeName]);
    }
  };

  const nextFormStep = () => {
    if (form_step === 1) {
      inputValidation(1);
      if (
        userInput[0].form_step_1.company_name === "" ||
        userInput[0].form_step_1.first_name === "" ||
        userInput[0].form_step_1.last_name === "" ||
        userInput[0].form_step_1.email === "" ||
        userInput[0].form_step_1.phone_number === "" ||
        userInput[0].form_step_1.password1 === "" ||
        userInput[0].form_step_1.password2 === ""
      )
        return inputValidation(1);
      if (
        userInput[0].form_step_1.password1 !==
        userInput[0].form_step_1.password2
      )
        return inputValidation(1);
    }
    if (form_step === 2) {
      inputValidation(2);
      if (
        userInput[0].form_step_2.adress === "" ||
        userInput[0].form_step_2.postal_code === "" ||
        userInput[0].form_step_2.city === "" ||
        userInput[0].form_step_2.country === "" ||
        userInput[0].form_step_2.vat_number === "" ||
        userInput[0].form_step_2.terms === false ||
        userInput[0].form_step_2.terms === "" ||
        userInput[0].form_step_2.logo === ""
      )
        return inputValidation(2);
    }
    if (form_step === 3) {
      addUserInput("scopes", scopeActive);
      registerUser();
      setButtonLoading();
    }
    if (form_step > 2) return;
    imgValidation();
    set_form_step(form_step + 1);
  };

  const setFormStep = (step) => {
    set_form_step(step);
  };

  const setButtonLoading = () => {
    const footerWrapper = document.getElementById("footer-wrapper");
    footerWrapper.classList.remove("notLoading");
    footerWrapper.classList.add("loading");
  };

  const addValidationError = (inputName) => {
    setValdationError((validationError) => [...validationError, inputName]);
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const inputValidation = (step) => {
    const form_data_1 = userInput[0].form_step_1;
    const form_data_2 = userInput[0].form_step_2;
    const form_data_3 = userInput[0].form_step_3;
    const form_data_1_message = validationMessage.form_step_1_message;
    const form_data_2_message = validationMessage.form_step_2_message;
    let counter = 0;

    setValdationError([]);

    //Form step 1
    if (step === 1) {
      for (let key in form_data_1_message) {
        form_data_1_message[key] = new Set();
      }

      //Email regex
      let emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      //Email validation
      if (!emailRegex.test(form_data_1.email)) {
        addValidationError("email");
        setValidationErrorMessage(1, { email: "Geen geldige email" });
        form_data_1.email = "";
      }

      //Phonenumber regex
      let phoneNumberRegex =
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

      //Phonenumber validation
      if (!phoneNumberRegex.test(form_data_1.phone_number)) {
        addValidationError("phone_number");
        setValidationErrorMessage(1, {
          phone_number: "Geen geldig telefoonnummer",
        });
        form_data_1.phone_number = "";
      }

      //Password validation
      if (form_data_1.password1 !== form_data_1.password2) {
        for (let i = 0; i < 2; i++) {
          i === 0
            ? addValidationError(
                getKeyByValue(form_data_1, form_data_1.password1)
              )
            : addValidationError(
                getKeyByValue(form_data_1, form_data_1.password2)
              );
        }
        setValidationErrorMessage(1, {
          password1: "Wachtwoord komt niet overheen",
        });
        setValidationErrorMessage(1, {
          password2: "Wachtwoord komt niet overheen",
        });
      } else {
        //Password regex validation
        //Minimum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
        const passwordRegex =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;

        if (!passwordRegex.test(form_data_1.password1)) {
          setValidationErrorMessage(1, {
            password1: "Wachtwoord niet sterk genoeg",
          });
          addValidationError("password1");
          form_data_1.password1 = "";
        }

        if (!passwordRegex.test(form_data_1.password2)) {
          setValidationErrorMessage(1, {
            password2: "Wachtwoord niet sterk genoeg",
          });
          addValidationError("password2");
          form_data_1.password2 = "";
        }
      }

      for (let key in form_data_1) {
        if (form_data_1[key] === "") {
          addValidationError(key);
          form_data_1_message[key].add("Verplichte invoer");
        }
        counter++;
      }
    }

    //Form step 2
    if (step === 2) {
      for (let key in form_data_2_message) {
        form_data_2_message[key] = new Set();
      }

      for (let key in form_data_2) {
        if (form_data_2[key] === "") {
          addValidationError(key);
          form_data_2_message[key].add("Verplichte invoer");
        }
        if (form_data_2.terms === false || form_data_2.terms === "")
          addValidationError("terms");
        counter++;
      }
    }
  };

  const toggleCheckbox = () => {
    setChecked(!checked);
    addUserInput("terms", checked);
  };

  const uploadPhoto = (event) => {
    const img = event.target.files[0];
    const response = upload_image(img);

    response.then((data) => {
      addUserInput("logo", data.response.mediaid);
    });

    imgValidation();
  };

  const imgValidation = () => {
    const imgWrapper = document.querySelector("#logo-wrapper");
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (imgWrapper === null) return;
    setTimeout(() => {
      let imagePath = document.getElementById("logo-media-id").value;
      if (!allowedExtensions.exec(imagePath)) {
        imgWrapper.classList.remove("input-color");
        imgWrapper.classList.remove("validationSuccess");
        imgWrapper.classList.add("validationError");
        return;
      }
      if (
        userInput[0].form_step_2.logo &&
        userInput[0].form_step_2.logo !== ""
      ) {
        imgWrapper.classList.remove("input-color");
        imgWrapper.classList.add("validationSuccess");
      } else {
        imgWrapper.classList.add("input-color");
        imgWrapper.classList.remove("validationSuccess");
        imgWrapper.classList.remove("validationError");
      }

      if (validationError.includes("logo")) {
        imgWrapper.classList.remove("input-color");
        imgWrapper.classList.add("validationError");
      }
    }, 100);
  };

  const setValidationErrorMessage = (step, errorKeyAndValue) => {
    const form_data_1_message = validationMessage.form_step_1_message;
    const form_data_2_message = validationMessage.form_step_2_message;

    if (step === 1) {
      if (errorKeyAndValue && errorKeyAndValue !== undefined) {
        form_data_1_message[Object.keys(errorKeyAndValue)].add(
          errorKeyAndValue[Object.keys(errorKeyAndValue)]
        );
      }
    }

    if (step === 2) {
      if (errorKeyAndValue && errorKeyAndValue !== undefined) {
        form_data_2_message[Object.keys(errorKeyAndValue)].add(
          errorKeyAndValue[Object.keys(errorKeyAndValue)]
        );
      }
    }
  };

  const displayValidationErrorMessage = (step, textId) => {
    const form_data_1_message = validationMessage.form_step_1_message;
    const form_data_2_message = validationMessage.form_step_2_message;

    let validationErrorForm1 = form_data_1_message[textId];
    let validationErrorForm2 = form_data_2_message[textId];

    if (
      document.getElementById(textId) === undefined ||
      document.getElementById(textId === null)
    ) {
      return;
    }
    setTimeout(() => {
      if (form_data_1_message[textId] !== undefined) {
        if (step === 1) {
          document.getElementById(textId).innerHTML = "";
          if (validationErrorForm1 === undefined) {
            return;
          }

          const validationObject = validationErrorForm1.values();
          const validationValues = validationObject.next();
          if (validationValues.value === undefined) {
            return;
          }
          document.getElementById(textId).innerHTML = validationValues.value;
        }
      } else if (form_data_2_message[textId] !== undefined) {
        if (step === 2) {
          document.getElementById(textId).innerHTML = "";
          if (validationErrorForm2 === undefined) {
            return;
          }

          const validationObject = validationErrorForm2.values();
          const validationValues = validationObject.next();
          if (validationValues.value === undefined) {
            return;
          }
          document.getElementById(textId).innerHTML = validationValues.value;
        }
      }
    }, 100);
  };

  const registerUser = async () => {
    var response = await register(userInput);
    setRegisterResponse(response);

    if (response.message === "account-created") {
      return registrationSuccess();
    } else if (
      response.message === "account-already-exist" ||
      "invalid-email" ||
      "invalid-phone_number" ||
      "password-not-equal" ||
      "password-not-strong-enough"
    ) {
      const footerWrapper = document.getElementById("footer-wrapper");
      footerWrapper.classList.remove("loading");
      footerWrapper.classList.add("notLoading");
      return;
    }
  };

  const registrationSuccess = () => {
    sessionStorage.setItem("registrationStatus", "success");
    return navigate("/finish");
  };

  return (
    <div>
      <div className="registration-title">
        <div
          onClick={
            form_step >= 1 ? () => setFormStep(1) : () => setFormStep(form_step)
          }
          className={form_step >= 1 ? "forum-step active" : ""}
        >
          <p>Stap-1</p>
        </div>
        <div
          onClick={
            form_step >= 2 ? () => setFormStep(2) : () => setFormStep(form_step)
          }
          className={form_step >= 2 ? "forum-step active" : "forum-step"}
        >
          <p>Stap-2</p>
        </div>
        <div
          onClick={
            form_step >= 3 ? () => setFormStep(3) : () => setFormStep(form_step)
          }
          className={form_step >= 3 ? "forum-step active" : "forum-step"}
        >
          <p>Stap-3</p>
        </div>
      </div>

      <div className="registration-body">
        <div
          className="form-step-1"
          style={form_step === 1 ? { display: "block" } : { display: "none" }}
        >
          <form method="POST">
            <div className="input-section">
              <label className="login-lbl" htmlFor="">
                Bedrijfsnaam
              </label>
              <div
                className={
                  validationError.includes("company_name")
                    ? "validationError"
                    : "input-color"
                }
              >
                <input
                  type="text"
                  id="company-name"
                  onChange={(e) => addUserInput("company_name", e.target.value)}
                  autoComplete="on"
                />
              </div>
              <p className="validationMessage" id="company_name">
                {displayValidationErrorMessage(1, "company_name")}
              </p>
            </div>
            <div className="input-section-row">
              <div className="input-wrapper">
                <label className="login-lbl" htmlFor="">
                  Voornaam
                </label>
                <div
                  className={
                    validationError.includes("first_name")
                      ? "validationError"
                      : "input-color"
                  }
                >
                  <input
                    type="text"
                    id="first-name"
                    onChange={(e) => addUserInput("first_name", e.target.value)}
                    autoComplete="on"
                  />
                </div>
                <p className="validationMessage" id="first_name">
                  {displayValidationErrorMessage(1, "first_name")}
                </p>
              </div>
              <div className="input-wrapper">
                <label className="login-lbl" htmlFor="">
                  Tussenvoegsel
                </label>
                <div className="input-color">
                  <input
                    type="text"
                    id="infix"
                    onChange={(e) => addUserInput("infix", e.target.value)}
                    autoComplete="on"
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <label className="login-lbl" htmlFor="">
                  Achternaam
                </label>
                <div
                  className={
                    validationError.includes("last_name")
                      ? "validationError"
                      : "input-color"
                  }
                >
                  <input
                    type="text"
                    id="last-name"
                    onChange={(e) => addUserInput("last_name", e.target.value)}
                    autoComplete="on"
                  />
                </div>
                <p className="validationMessage" id="last_name">
                  {displayValidationErrorMessage(1, "last_name")}
                </p>
              </div>
            </div>
            <div className="input-section">
              <label className="login-lbl" htmlFor="">
                Email
              </label>
              <div
                className={
                  validationError.includes("email")
                    ? "validationError"
                    : "input-color"
                }
              >
                <input
                  type="text"
                  name="email"
                  onChange={(e) => addUserInput("email", e.target.value)}
                  autoComplete="on"
                />
              </div>
              <p className="validationMessage" id="email">
                {displayValidationErrorMessage(1, "email")}
              </p>
            </div>
            <div className="input-section">
              <label className="login-lbl" htmlFor="">
                Telefoonnummer
              </label>
              <div
                className={
                  validationError.includes("phone_number")
                    ? "validationError"
                    : "input-color"
                }
              >
                <input
                  type="text"
                  name="phone_number"
                  onChange={(e) => addUserInput("phone_number", e.target.value)}
                  autoComplete="on"
                />
              </div>
              <p className="validationMessage" id="phonenumber">
                {displayValidationErrorMessage(1, "phone_number")}
              </p>
            </div>
            <div className="input-section-row">
              <div className="input-wrapper">
                <label className="login-lbl" htmlFor="">
                  Wachtwoord{" "}
                  <i
                    onClick={() => setShowPaymentRequirements(true)}
                    className="fa-solid fa-circle-question"
                  ></i>
                </label>
                <div
                  className={
                    validationError.includes("password1")
                      ? "validationError"
                      : "input-color"
                  }
                >
                  <input
                    type={passwordShown === "1" ? "text" : "password"}
                    id="password"
                    onChange={(e) => addUserInput("password1", e.target.value)}
                  />
                  <div
                    onClick={
                      passwordShown !== "1"
                        ? () => togglePassword("1")
                        : () => togglePassword("")
                    }
                    className="password-toggle"
                  >
                    <i
                      className={
                        passwordShown === "1"
                          ? "fa-solid fa-eye"
                          : "fa-solid fa-eye-slash"
                      }
                    ></i>
                  </div>
                </div>
                <p className="validationMessage" id="password1">
                  {displayValidationErrorMessage(1, "password1")}
                </p>
              </div>
              <div className="input-wrapper">
                <label className="login-lbl" htmlFor="">
                  Herhaal wachtwoord
                </label>
                <div
                  className={
                    validationError.includes("password2")
                      ? "validationError"
                      : "input-color"
                  }
                >
                  <input
                    type={passwordShown === "2" ? "text" : "password"}
                    id="repeat-password"
                    onChange={(e) => addUserInput("password2", e.target.value)}
                  />
                  <div
                    onClick={
                      passwordShown !== "2"
                        ? () => togglePassword("2")
                        : () => togglePassword("")
                    }
                    className="password-toggle"
                  >
                    <i
                      className={
                        passwordShown === "2"
                          ? "fa-solid fa-eye"
                          : "fa-solid fa-eye-slash"
                      }
                    ></i>
                  </div>
                </div>
                <p className="validationMessage" id="password2">
                  {displayValidationErrorMessage(1, "password2")}
                </p>
              </div>
            </div>
          </form>
        </div>

        <div
          className="form-step-2"
          style={form_step === 2 ? { display: "block" } : { display: "none" }}
        >
          <form method="POST">
            <div className="input-section">
              <label className="login-lbl" htmlFor="">
                Adres
              </label>
              <div
                className={
                  validationError.includes("adress")
                    ? "validationError"
                    : "input-color"
                }
              >
                <input
                  type="text"
                  name="adress"
                  onChange={(e) => addUserInput("adress", e.target.value)}
                  autoComplete="on"
                />
              </div>
              <p className="validationMessage" id="adress">
                {displayValidationErrorMessage(2, "adress")}
              </p>
            </div>
            <div className="input-section-row">
              <div className="input-wrapper">
                <label className="login-lbl" htmlFor="">
                  Postcode
                </label>
                <div
                  className={
                    validationError.includes("postal_code")
                      ? "validationError"
                      : "input-color"
                  }
                >
                  <input
                    type="text"
                    id="postalcode"
                    onChange={(e) =>
                      addUserInput("postal_code", e.target.value)
                    }
                    autoComplete="on"
                  />
                </div>
                <p className="validationMessage" id="postal_code">
                  {displayValidationErrorMessage(2, "postal_code")}
                </p>
              </div>
              <div className="input-wrapper">
                <label className="login-lbl" htmlFor="">
                  Stad
                </label>
                <div
                  className={
                    validationError.includes("city")
                      ? "validationError"
                      : "input-color"
                  }
                >
                  <input
                    type="text"
                    name="city"
                    onChange={(e) => addUserInput("city", e.target.value)}
                    autoComplete="on"
                  />
                </div>
                <p className="validationMessage" id="city">
                  {displayValidationErrorMessage(2, "city")}
                </p>
              </div>
            </div>
            <div className="input-section-row">
              <div className="input-wrapper">
                <label className="login-lbl" htmlFor="">
                  Land
                </label>
                <div
                  className={
                    validationError.includes("country")
                      ? "validationError"
                      : "input-color"
                  }
                >
                  <input
                    type="text"
                    name="country"
                    onChange={(e) => addUserInput("country", e.target.value)}
                    autoComplete="on"
                  />
                </div>
                <p className="validationMessage" id="country">
                  {displayValidationErrorMessage(2, "country")}
                </p>
              </div>
              <div className="input-wrapper">
                <label className="login-lbl" htmlFor="">
                  BTW nummer
                </label>
                <div
                  className={
                    validationError.includes("vat_number")
                      ? "validationError"
                      : "input-color"
                  }
                >
                  <input
                    type="text"
                    id="vatnumber"
                    onChange={(e) => addUserInput("vat_number", e.target.value)}
                    autoComplete="on"
                  />
                </div>
                <p className="validationMessage" id="vat_number">
                  {displayValidationErrorMessage(2, "vat_number")}
                </p>
              </div>
            </div>
            <div className="input-section-row">
              <div className="input-wrapper">
                <label className="file">Logo (JPEG,JPG,PNG)</label>
                <div id="logo-wrapper" className="" onLoad={imgValidation()}>
                  <input
                    type="file"
                    accept=".jpeg, .png, .jpg"
                    onChange={(event) => uploadPhoto(event)}
                    id="logo-media-id"
                    aria-label="File browser example"
                  />
                  <span className="file-custom" />
                </div>
                <p className="validationMessage" id="logo">
                  {displayValidationErrorMessage(2, "logo")}
                </p>
              </div>
            </div>
            <div className="terms-wrapper">
              <input
                type="checkbox"
                id="terms-btn"
                onClick={(e) => toggleCheckbox()}
              />
              <label
                className={
                  validationError.includes("terms") ? "validationErrorText" : ""
                }
                htmlFor=""
              >
                Klik om de{" "}
                <a
                  target="_blank"
                  className={
                    validationError.includes("terms")
                      ? "validationErrorText"
                      : ""
                  }
                  href="https://www.ezinspect.nl/wp-content/uploads/2022/04/algemene_voorwaarden_ezinspect.pdf"
                >
                  voorwaarden
                </a>{" "}
                te accepteren
              </label>
            </div>
          </form>
        </div>

        <div
          className="form-step-3"
          style={form_step === 3 ? { display: "block" } : { display: "none" }}
        >
          <form method="POST">
            <div className="input-section">
              <label className="login-lbl" htmlFor="">
                Hoe heeft u ons gevonden?
              </label>
              <div className="input-color">
                <textarea
                  id="find-us-text"
                  cols="30"
                  rows="10"
                  onChange={(e) =>
                    addUserInput("found_message", e.target.value)
                  }
                ></textarea>
              </div>
            </div>
            <div className="input-section">
              <label className="login-lbl" htmlFor="">
                Met welke scopes wilt u werken?
              </label>
              <div className="scope-btn-wrapper">
                <div
                  onClick={() => addScope("scope-8")}
                  className={
                    scopeActive.indexOf("scope-8") > -1
                      ? "scope active"
                      : "scope"
                  }
                >
                  <p>Scope-8</p>
                </div>
                <div
                  onClick={() => addScope("scope-10")}
                  className={
                    scopeActive.indexOf("scope-10") > -1
                      ? "scope active"
                      : "scope"
                  }
                >
                  <p>Scope-10</p>
                </div>
                <div
                  onClick={() => addScope("scope-NEN1010")}
                  className={
                    scopeActive.indexOf("scope-NEN1010") > -1
                      ? "scope active"
                      : "scope"
                  }
                >
                  <p>Scope-NEN1010</p>
                </div>
                <div
                  onClick={() => addScope("scope-12")}
                  className={
                    scopeActive.indexOf("scope-12") > -1
                      ? "scope active"
                      : "scope"
                  }
                >
                  <p>Scope-12</p>
                </div>
              </div>
            </div>
            <div
              className={
                registerResponse.message === "account-already-exist"
                  ? "registration-error-message"
                  : "hide-error-message"
              }
            >
              <div className="text-wrapper">
                <p>Dit account is al geregistreerd</p>
              </div>
            </div>
            <div
              className={
                registerResponse.message === "invalid-email"
                  ? "registration-error-message"
                  : "hide-error-message"
              }
            >
              <div className="text-wrapper">
                <p>U heeft een ongeldig email ingevoerd</p>
              </div>
            </div>
            <div
              className={
                registerResponse.message === "invalid-phone_number"
                  ? "registration-error-message"
                  : "hide-error-message"
              }
            >
              <div className="text-wrapper">
                <p>U heeft een ongeldig telefoonnummer ingevoerd</p>
              </div>
            </div>
            <div
              className={
                registerResponse.message === "password-not-equal"
                  ? "registration-error-message"
                  : "hide-error-message"
              }
            >
              <div className="text-wrapper">
                <p>De ingevoerde wachtwoorden komen niet overheen</p>
              </div>
            </div>
            <div
              className={
                registerResponse.message === "password-not-strong-enough"
                  ? "registration-error-message"
                  : "hide-error-message"
              }
            >
              <div className="text-wrapper">
                <p>De ingevoerde wachtwoorden zijn niet sterk genoeg</p>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="registration-footer">
        <div id="footer-wrapper" className="footer-wrapper notLoading">
          <button onClick={nextFormStep} className="next-step-btn">
            Verder
            <svg
              width="23"
              height="16"
              viewBox="0 0 23 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.7071 8.77595C23.0976 8.38543 23.0976 7.75227 22.7071 7.36174L16.3431 0.99778C15.9526 0.607255 15.3195 0.607255 14.9289 0.99778C14.5384 1.3883 14.5384 2.02147 14.9289 2.41199L20.5858 8.06885L14.9289 13.7257C14.5384 14.1162 14.5384 14.7494 14.9289 15.1399C15.3195 15.5304 15.9526 15.5304 16.3431 15.1399L22.7071 8.77595ZM0 9.06885H22V7.06885H0V9.06885Z"
                fill="white"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              style={{
                margin: "auto",
                background: "none",
                display: "block",
                shapeRendering: "auto",
              }}
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <g transform="rotate(0 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.9166666666666666s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(30 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.8333333333333334s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(60 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.75s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(90 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.6666666666666666s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(120 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.5833333333333334s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(150 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.5s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(180 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.4166666666666667s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(210 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.3333333333333333s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(240 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.25s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(270 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.16666666666666666s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(300 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.08333333333333333s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(330 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="0s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
            </svg>
          </button>
        </div>
      </div>

      <Modal
        show={showPaymentRequirements}
        onHide={handleClosePasswordRequirements}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Uw wachtwoord moet voldoen aan de volgende eisen:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Minimaal 10 karakters</p>
          <p>Minimaal 1 hoofdletter</p>
          <p>Minimaal 1 kleineletter</p>
          <p>Minimaal 1 speciaal teken (@$!%*?&)</p>
          <p>Minimaal 1 cijfer</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePasswordRequirements}>
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Registration_forum;
