/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
// Importing third party libraries
import { useLocation, useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import DateTime from "react-datetime";
// Get SCSS from styles and components
import "../../scss/reports/select-inspectie.scss";
import "react-datetime/css/react-datetime.css";

// Importing the correct functions to call the api
import { get_one_rapport, update_rapportage } from "../../functions/api";

// Importing functional child components
import Tekst from "./Tekstenbieb";
import Instruments from "./Instruments";
import Normen from "./Normen";
import NormenGetoetst from "./NormenGetoetst";
import Aansluitgegevens from "./Aansluitgegevens";
import InhoudsOpgave from "./InhoudsOpgave";
import { AiOutlineLeft } from "react-icons/ai";

// Importing functional navigation components
import Menu from "../navigation/Menu";
import Nav from "../navigation/Nav";

function EditReport() {
  // permissions
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const navigate = useNavigate();
  // Creating all the necessary states
  let [klantnaam, setKlantnaam] = useState("");
  let [objectnaam, setObjectnaam] = useState("");
  let [datumstart, setDatumstart] = useState("");
  let [soortinspectieid, setSoortinspectieid] = useState("");

  let [rapportid, setRapportid] = useState("");

  let [inspectieid, setInspectieid] = useState("");
  let [objectfoto, setObjectfoto] = useState("");

  let [rapportNummer, setRapportNummer] = useState("");
  let [inhoudsopgave, setInhoudsOpgave] = useState([]);
  let [subtitel, setSubtitel] = useState("");
  let [instellingen, setInstellingen] = useState([]);
  let [inleidingAlgemeen, setInleidingAlgemeen] = useState("");
  let [inleidingKwaliteitswaarborg, setInleidingKwaliteitswaarborg] =
    useState("");
  let [inleidingHerstelgebreken, setInleidingHerstelgebreken] = useState("");

  let [installatieverantwoordelijke, setInstallatieverantwoordelijke] =
    useState([]);
  let [meetinstrumenten, setMeetinstrumenten] = useState([]);

  let [kenmerkAlgemeen, setKenmerkalgemeen] = useState("");
  let [frequentiedraairichting, setFrequentiedraairichting] = useState("");
  let [objectfuncties, setObjectfuncties] = useState([]);
  let [aansluitgegevens, setAansluitgegevens] = useState("");
  let [aansluitgegevensArr, setAansluitgegevensArr] = useState([]);
  let [aansluitgegevenid, setAansluitgegevenid] = useState();

  let [omvanginspectie, setOmvanginspectie] = useState("");
  let [nietgeinspecteerd, setNietgeinspecteerd] = useState("");

  let [redeninspectie, setRedeninspectie] = useState("");
  let [inspectieuitgevoerdvolgens, setInspectieuitgevoerdvolgens] =
    useState("");
  let [deelektronischeinstallatie, setDeelelektronischeinstallatie] =
    useState("");
  let [beoordelingscriteria, setBeoordelingscriteria] = useState("");
  let [uitgangspuntenArr, setUitgangspuntenArr] = useState([]);
  let [uitgangspuntenArr2, setUitgangspuntenArr2] = useState([]);
  let [normid, setNormid] = useState("");
  let [normid2, setNormid2] = useState("");

  let [bedrijfsomstandigheden, setBedrijfsomstandigheden] = useState("");
  let [wederzijdsebeinvloeding, setWederzijdsebeinvloeding] = useState("");
  let [uitwendigebeinvloeding, setUitwendigebeinvloeding] = useState("");
  let [automatischeuitschakeling, setAutomatischeuitschakeling] = useState("");

  let [volgendeinspectie, setVolgendeInspectie] = useState("");
  let [leeftijd, setLeeftijd] = useState("");
  let [kwaliteit, setKwaliteit] = useState("");
  let [omgevingsomstandigheden, setOmgevingsomstandigheden] = useState("");
  let [personen, setPersonen] = useState("");
  let [matevantoezicht, setMatevantoezicht] = useState("");
  let [datum, setDatum] = useState("");

  let [beoordeling, setBeoordeling] = useState("");
  let [toelichting, setToelichting] = useState("");

  let [totaalPunten, setTotaalPunten] = useState("");

  const [loading, setLoading] = useState(true);

  // With useLocation I can get the rapport ID from the selected report
  const location = useLocation();
  const { rapportId } = location.state;
  // Hardcoded options for the corresponding select boxes

  const installatieLeeftijd = [
    { value: 1, label: "Jonger dan 10 jaar" },
    { value: 2, label: "Ouder dan 10 jaar" },
    { value: 3, label: "Ouder dan 20 jaar" },
    { value: 4, label: "Ouder dan 30 jaar" },
  ];

  const installatieKwaliteit = [
    {
      value: 1,
      label:
        "Is aanzienelijk beter dan de minimale kwaliteit zoals is vastgesteld in de jongste elektrotechnische normen",
    },
    { value: 2, label: "Voldoet aan de jongste elektrotechnische vormen" },
    {
      value: 3,
      label:
        "Voldoet aan de normen bij de aanleg van toepassing waren + extra veiligheidsvoorzieningen",
    },
    {
      value: 4,
      label: "Voldoet aan de normen die bij de aanleg van toepassing waren",
    },
    {
      value: 5,
      label:
        "Levert het vermoeden of heeft feitelijk aan dat de elektrische installatie niet aan de normen voldoet, er zijn echter geen gevaarlijke situaties aanwezig",
    },
  ];
  const omgevingsOmstandigheden = [
    {
      value: 1,
      label:
        "Schoon en droog; geen brandgevaar t.g.v. stof; geen corrosie- en explosiegevaar; is vrij van transportmiddelen of zware materialen",
    },
    {
      value: 2,
      label:
        "Is niet schoon en droog; bevat explosieve of corrosieve gassen; levert explosiegevaar ten gevolge van stof op; houdt het gebruik van transportmiddelen of zware materialen in",
    },
    {
      value: 3,
      label:
        "Zwaar industrieel met voortdurend gevaar voor veiligheid: vocht; brandbaar materiaal; stof of corrosieve of explosieve gassen of dampen of stof; of houdt gebruik van zware transportmiddelen in",
    },
  ];
  const installatiePersonen = [
    {
      value: 1,
      label:
        "Uitsluitend ervaren elektrotechnische opgeleiden personen of personen zelfstandig kunnen oordelen over veilig werken",
    },
    {
      value: 2,
      label:
        "Niet specifiek elektrotechnische opgeleid personeel, maar wel opgeleid zijn aandacht te geven aan gevaren door werken met elektriciteit",
    },
    { value: 3, label: "Leken" },
    { value: 4, label: "Leerlingen, cursisten, studenten, practisanten" },
  ];
  const regelmaat = [
    { value: 1, label: "Regelmatig" },
    { value: 2, label: "Sporadisch" },
  ];
  const zichtbaarMaken = [
    { value: 1, label: "Grafiek" },
    { value: 2, label: "Sios logo" },
  ];
  const zichtbaarMaken2 = [{ value: 1, label: "Installatieverantwoordelijke" }];

  // When this function is used the stated will be stored in the object and it will send the object through express.
  const onSubmit = async () => {
    let bijlagenLijst = [];
    if (selectedExtraBijlagen.length > 0) {
      selectedExtraBijlagen.map((objSelectedBijlage) => {
        bijlagenLijst.push({
          titel: objSelectedBijlage.label,
          pdf: objSelectedBijlage.value,
        });
      });
    }

    const object = {
      rapport_info: {
        klantnaam: klantnaam,
        objectnaam: objectnaam,
        datumstart: datumstart,
        soortinspectieid: soortinspectieid,
      },
      rapportid: rapportid,
      inspectie_object: {
        inspectieid: inspectieid,
        objectfoto: objectfoto,
      },
      rapportnummer: rapportNummer,
      inhoudsopgave: inhoudsopgave,
      subtitel: subtitel,
      instellingen: instellingen,
      inleiding: {
        algemeen: inleidingAlgemeen,
        kwaliteitswaarborg: inleidingKwaliteitswaarborg,
        herstelgebreken: inleidingHerstelgebreken,
      },
      algemenegegevens: {
        installatieverantwoordelijke:
          installatieverantwoordelijke !== undefined
            ? Array.isArray(installatieverantwoordelijke)
              ? installatieverantwoordelijke[0] !== undefined
                ? "1"
                : "0"
              : "0"
            : "0",
        meetinstrumenten: meetinstrumenten,
      },
      kenmerken: {
        algemeen: kenmerkAlgemeen,
        frequentiedraairichting: frequentiedraairichting,
        objectfuncties: objectfuncties,
        aansluitgegevens: aansluitgegevens,
        aansluitgegevens_array: aansluitgegevensArr,
      },
      inspectie: {
        omvanginspectie: omvanginspectie,
        nietgeinspecteerd: nietgeinspecteerd,
      },
      uitgangspunten: {
        redeninspectie: redeninspectie,
        inspectieuitgevoerdvolgens: inspectieuitgevoerdvolgens,
        deelektronischeinstallatie: deelektronischeinstallatie,
        beoordelingscriteria: beoordelingscriteria,
        uitgangspunten_array: uitgangspuntenArr,
        uitgangspunten_array_2: uitgangspuntenArr2,
      },
      inspectiemethode: {
        bedrijfsomstandigheden: bedrijfsomstandigheden,
        wederzijdsebeinvloeding: wederzijdsebeinvloeding,
        uitwendigebeinvloeding: uitwendigebeinvloeding,
        automatischeuitschakeling: automatischeuitschakeling,
      },
      volgendeinspectie: {
        volgendeinspectie: volgendeinspectie,
        leeftijd: leeftijd,
        kwaliteit: kwaliteit,
        omgevingsomstandigheden: omgevingsomstandigheden,
        personen: personen,
        matevantoezicht: matevantoezicht,
        datum: datum.toString(),
      },
      beoordeling: {
        eindbeoordeling: beoordeling,
        toelichting: toelichting,
      },
      extraBijlagen: bijlagenLijst,
    };

    const response = await update_rapportage(JSON.stringify(object));
    window.open(response.link);

    return navigate("/reports");
  };

  let gekozenInstellingen = undefined;

  if (instellingen && instellingen !== "") {
    gekozenInstellingen = instellingen.map((object) => {
      let currentOption;
      if (Number.isInteger(object)) {
        currentOption = zichtbaarMaken.filter(
          (obj_option) => obj_option.value == object
        );
      } else {
        currentOption = zichtbaarMaken.filter(
          (obj_option) => obj_option.label == object
        );
      }
      return {
        value: currentOption[0].value,
        label: currentOption[0].label,
      };
    });
  }

  // This function will 'calculate' the points from the inspection and return the total value
  const calculatePunten = (object_values) => {
    let totaal = 0;

    if (parseInt(object_values.personen) === 1) {
      totaal = totaal + 0;
    }
    if (parseInt(object_values.personen) === 2) {
      totaal = totaal + 5;
    }
    if (parseInt(object_values.personen) === 3) {
      totaal = totaal + 8;
    }
    if (parseInt(object_values.personen) === 4) {
      totaal = totaal + 10;
    }

    if (parseInt(object_values.matevantoezicht) === 1) {
      totaal = totaal + 0;
    }
    if (parseInt(object_values.matevantoezicht) === 2) {
      totaal = totaal + 10;
    }

    if (parseInt(object_values.omgeving) === 1) {
      totaal = totaal + 0;
    }
    if (parseInt(object_values.omgeving) === 2) {
      totaal = totaal + 10;
    }
    if (parseInt(object_values.omgeving) === 3) {
      totaal = totaal + 20;
    }

    if (parseInt(object_values.kwaliteit) === 1) {
      totaal = totaal + 0;
    }
    if (parseInt(object_values.kwaliteit) === 2) {
      totaal = totaal + 2;
    }
    if (parseInt(object_values.kwaliteit) === 3) {
      totaal = totaal + 4;
    }
    if (parseInt(object_values.kwaliteit) === 4) {
      totaal = totaal + 7;
    }
    if (parseInt(object_values.kwaliteit) === 5) {
      totaal = totaal + 15;
    }

    if (parseInt(object_values.leeftijd) === 1) {
      totaal = totaal + 0;
    }
    if (parseInt(object_values.leeftijd) === 2) {
      totaal = totaal + 5;
    }
    if (parseInt(object_values.leeftijd) === 3) {
      totaal = totaal + 8;
    }
    if (parseInt(object_values.leeftijd) === 4) {
      totaal = totaal + 10;
    }

    return setTotaalPunten(totaal);
  };

  let gekozenKwaliteit = () => {
    switch (kwaliteit) {
      case "1":
        return installatieKwaliteit.filter(({ value }) => value === 1);
      case "2":
        return installatieKwaliteit.filter(({ value }) => value === 2);
      case "3":
        return installatieKwaliteit.filter(({ value }) => value === 3);
      case "4":
        return installatieKwaliteit.filter(({ value }) => value === 4);
      case "5":
        return installatieKwaliteit.filter(({ value }) => value === 5);
      default:
        break;
    }
  };
  let gekozenLeeftijd = () => {
    switch (leeftijd) {
      case "1":
        return installatieLeeftijd.filter(({ value }) => value === 1);
      case "2":
        return installatieLeeftijd.filter(({ value }) => value === 2);
      case "3":
        return installatieLeeftijd.filter(({ value }) => value === 3);
      case "4":
        return installatieLeeftijd.filter(({ value }) => value === 4);
      default:
        break;
    }
  };
  let gekozenPersonen = () => {
    switch (personen) {
      case "1":
        return installatiePersonen.filter(({ value }) => value === 1);
      case "2":
        return installatiePersonen.filter(({ value }) => value === 2);
      case "3":
        return installatiePersonen.filter(({ value }) => value === 3);
      case "4":
        return installatiePersonen.filter(({ value }) => value === 4);
      default:
        break;
    }
  };
  let gekozenOmgeving = () => {
    switch (omgevingsomstandigheden) {
      case "1":
        return omgevingsOmstandigheden.filter(({ value }) => value === 1);
      case "2":
        return omgevingsOmstandigheden.filter(({ value }) => value === 2);
      case "3":
        return omgevingsOmstandigheden.filter(({ value }) => value === 3);
      default:
        break;
    }
  };
  let gekozenRegelmaat = () => {
    switch (matevantoezicht) {
      case "1":
        return regelmaat.filter(({ value }) => value === 1);
      case "2":
        return regelmaat.filter(({ value }) => value === 2);
      default:
        break;
    }
  };
  // useEffect will run once when the page loads in and when the rapportId changes
  useEffect(() => {
    // Get data from express with the corresponding rapport id
    get_one_rapport(rapportId)
      .then((report) => {
        if (report.ok) {
          return report.json();
        }
        throw report;
      })
      .then((data) => {
        // Setting all the data in the correct states
        setKlantnaam(data.rapportage_object.rapport_info.klantnaam);
        setObjectnaam(data.rapportage_object.rapport_info.objectnaam);
        setDatumstart(data.rapportage_object.rapport_info.datumstart);
        setSoortinspectieid(
          data.rapportage_object.rapport_info.soortinspectieid
        );
        setRapportid(data.rapportage_object.rapportid);
        setInspectieid(data.rapportage_object.inspectie_object.inspectieid);
        setObjectfoto(data.rapportage_object.inspectie_object.objectfoto);
        setRapportNummer(data.rapportage_object.rapportnummer);
        setInhoudsOpgave(data.rapportage_object.inhoudsopgave);
        setSubtitel(data.rapportage_object.subtitel);
        setInstellingen(data.rapportage_object.instellingen);
        setInleidingAlgemeen(data.rapportage_object.inleiding.algemeen);
        setInleidingKwaliteitswaarborg(
          data.rapportage_object.inleiding.kwaliteitswaarborg
        );
        setInleidingHerstelgebreken(
          data.rapportage_object.inleiding.herstelgebreken
        );
        setInstallatieverantwoordelijke(
          data.rapportage_object.algemenegegevens
            .installatieverantwoordelijke === "1"
            ? [{ value: 1, label: "Installatieverantwoordelijke" }]
            : []
        );
        setMeetinstrumenten(
          data.rapportage_object.algemenegegevens.meetinstrumenten
        );
        setKenmerkalgemeen(data.rapportage_object.kenmerken.algemeen);
        setFrequentiedraairichting(
          data.rapportage_object.kenmerken.frequentiedraairichting
        );
        setObjectfuncties(data.rapportage_object.kenmerken.objectfuncties);
        setAansluitgegevens(data.rapportage_object.kenmerken.aansluitgegevens);

        setAansluitgegevensArr(
          data.rapportage_object.kenmerken.aansluitgegevens_array
        );

        setAansluitgegevenid(
          data.rapportage_object.kenmerken.aansluitgegevens_array
            .aansluitgegevenid
        );
        setOmvanginspectie(data.rapportage_object.inspectie.omvanginspectie);
        setNietgeinspecteerd(
          data.rapportage_object.inspectie.nietgeinspecteerd
        );
        setRedeninspectie(data.rapportage_object.uitgangspunten.redeninspectie);
        setInspectieuitgevoerdvolgens(
          data.rapportage_object.uitgangspunten.inspectieuitgevoerdvolgens
        );
        setDeelelektronischeinstallatie(
          data.rapportage_object.uitgangspunten.deelektronischeinstallatie
        );
        setBeoordelingscriteria(
          data.rapportage_object.uitgangspunten.beoordelingscriteria
        );
        setUitgangspuntenArr(
          data.rapportage_object.uitgangspunten.uitgangspunten_array
        );
        setUitgangspuntenArr2(
          data.rapportage_object.uitgangspunten.uitgangspunten_array_2
        );
        setBedrijfsomstandigheden(
          data.rapportage_object.inspectiemethode.bedrijfsomstandigheden
        );
        setWederzijdsebeinvloeding(
          data.rapportage_object.inspectiemethode.wederzijdsebeinvloeding
        );
        setUitwendigebeinvloeding(
          data.rapportage_object.inspectiemethode.uitwendigebeinvloeding
        );
        setAutomatischeuitschakeling(
          data.rapportage_object.inspectiemethode.automatischeuitschakeling
        );
        setVolgendeInspectie(
          data.rapportage_object.volgendeinspectie.volgendeinspectie
        );
        setLeeftijd(data.rapportage_object.volgendeinspectie.leeftijd);
        setKwaliteit(data.rapportage_object.volgendeinspectie.kwaliteit);
        setOmgevingsomstandigheden(
          data.rapportage_object.volgendeinspectie.omgevingsomstandigheden
        );
        setPersonen(data.rapportage_object.volgendeinspectie.personen);
        setMatevantoezicht(
          data.rapportage_object.volgendeinspectie.matevantoezicht
        );
        setDatum(new Date(data.rapportage_object.volgendeinspectie.datum));
        setBeoordeling(data.rapportage_object.beoordeling.eindbeoordeling);
        setToelichting(data.rapportage_object.beoordeling.toelichting);
        let object_values = {
          leeftijd: data.rapportage_object.volgendeinspectie.leeftijd,
          kwaliteit: data.rapportage_object.volgendeinspectie.kwaliteit,
          omgeving:
            data.rapportage_object.volgendeinspectie.omgevingsomstandigheden,
          personen: data.rapportage_object.volgendeinspectie.personen,
          matevantoezicht:
            data.rapportage_object.volgendeinspectie.matevantoezicht,
        };
        calculatePunten(object_values);

        if (data.rapportage_object.extra_bijlagen) {
          setExtraBijlagenPdf(data.rapportage_object.extra_bijlagen);
          let options = [];
          let selectedOptions = [];
          let i = -1;

          data.rapportage_object.extra_bijlagen.map((bijlage) => {
            i++;

            var objOption = {
              label: bijlage.titel,
              value: bijlage.pdf,
            };

            options.push(objOption);
            selectedOptions.push(objOption);
            console.log();
          });

          setExtraBijlagen(options);
          setSelectedExtraBijlagen(selectedOptions);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      })
      .finally(() => {
        if (
          !permissions &&
          permissions.editPermissions.editRapportage === "0"
        ) {
          Swal.fire({ title: "Geen toegang!" }).then(() => {
            navigate("/reports");
          });
        }
        // Set loading on false if everything is set in the states.
        setLoading(false);
      });
  }, [rapportId]);

  const [extraBijlagen, setExtraBijlagen] = useState([]);
  const [extraBijlagenPdf, setExtraBijlagenPdf] = useState([]);
  const [selectedExtraBijlagen, setSelectedExtraBijlagen] = useState([]);
  async function handlePdfUpload(titel, file) {
    if (titel && file) {
      // Set new state for extraBijlagen

      const formData = new FormData();
      formData.append("pdf", file);
      const response = await fetch(
        "https://api.ezinspect.nl/api/v2/UploadPDF",
        {
          method: "POST",
          body: formData,
        }
      );

      const jsonResponse = await response.json();

      try {
        var newObjExtraBijlagen = {
          label: titel,
          value: jsonResponse.pdf,
        };

        setExtraBijlagen([...extraBijlagen, newObjExtraBijlagen]);

        setSelectedExtraBijlagen([
          ...selectedExtraBijlagen,
          newObjExtraBijlagen,
        ]);
      } catch (error) {
        alert(error);
      }

      // Set new state for extraBijlagenPdf
      setExtraBijlagenPdf([
        ...extraBijlagenPdf,
        {
          titel: titel,
          pdf: jsonResponse.pdf,
        },
      ]);
    }
  }
  const BijlageModal = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success mx-2",
        cancelButton: "btn btn-danger mx-2",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Voeg hier een extra bijlage toe.",
        showCancelButton: true,
        html: `<div style="display: grid; width: 80%; margin: auto;">
        <label>Titel</label>
        <input type="text" style="margin-top: 8px;" id="swal-input1" class="swal2-input" />
        <label>PDF</label>
        <input style="margin-left: 80px; margin-top: 8px;" type="file" accept="application/pdf" id="swal-input2" class="swal2-input" />
        </div>`,
        confirmButtonText: "Ja, voeg toe!",
        cancelButtonText: "Nee, Annuleer!",
        reverseButtons: true,
        preConfirm: () => {
          const titleInput = document.getElementById("swal-input1").value;
          const pdfInput = document.getElementById("swal-input2").files[0];
          if (titleInput && pdfInput) {
            handlePdfUpload(titleInput, pdfInput);
          } else {
            swalWithBootstrapButtons.fire({
              title: "Oeps!",
              text: "U heeft niet alle velden ingevuld.",
              icon: "error",
              confirmButtonText: "Ok!",
            });
          }
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire(
            "Toegevoegd!",
            "Extra bijlage is toegevoegd."
          );
        }
      });
  };
  // If loading = true the states aren't set yet.
  if (loading === true) {
    return (
      <div className="select-report-container-main">
        <Nav page="Rapportages" />
        <div className="page-wrap-row">
          <Menu className="menu-container" />
        </div>
      </div>
    );
  }
  // If loading = false all the states will be set and returned in the form.
  if (loading === false) {
    return (
      <div className="select-report-container-main">
        <Nav page="Rapportages" />
        <div className="page-wrap-row">
          <Menu className="menu-container" />
          <div className="select-inspectie pt-0">
            <div className="rapportage-input-col">
              <Link
                className="fixed bottom-0 left-0 ml-4 mb-4"
                to="/reports"
                style={{
                  textDecoration: "none",
                  color: "#757575",
                  width: "85px",
                }}
              >
                <p className="text-base flex flex-row">
                  <AiOutlineLeft className="mt-1" />
                  terug
                </p>
              </Link>

              <div className="rapportage-input-left">
                <label className="label-inspectie">Bijlage(n)</label>
                <InhoudsOpgave
                  edit={true}
                  defaults={inhoudsopgave}
                  toParent={setInhoudsOpgave}
                />
                <label
                  style={{ paddingTop: "2px" }}
                  className="label-inspectie"
                >
                  Extra Bijlage(n)
                </label>
                <br />
                <div style={{ width: "100%", display: "flex" }}>
                  <button
                    className="btn btn-primary"
                    style={{
                      width: "240px",
                      height: "45.5px",
                      marginTop: "1px",
                    }}
                    onClick={BijlageModal}
                  >
                    Voeg extra bijlage toe
                  </button>
                  <div
                    style={{
                      width: "98%",
                      marginLeft: "8px",
                    }}
                  >
                    <Select
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          height: state.hasValue ? "47.5px" : "47.5px",
                        }),
                      }}
                      options={extraBijlagen}
                      value={selectedExtraBijlagen}
                      isMulti
                      isClearable={true}
                      closeMenuOnSelect={false}
                      onChange={(s) => {
                        console.log("Selected Value: " + JSON.stringify(s));
                        setSelectedExtraBijlagen(s);
                      }}
                    />
                  </div>
                </div>
                <br />
                <label className="label-inspectie">Zichtbaar maken</label>
                <Select
                  closeMenuOnSelect={false}
                  defaultValue={gekozenInstellingen}
                  isMulti
                  isClearable={false}
                  options={zichtbaarMaken}
                  onChange={(e) => {
                    setInstellingen(e.map((object) => object.value));
                  }}
                />
              </div>
              <div className="rapportage-input-col">
                <label className="label-inspectie">Subtitel </label>
                <textarea
                  placeholder="Type here"
                  spellCheck={false}
                  style={{ width: "70vw", height: "30vh" }}
                  className="rapportage-textarea"
                  value={subtitel}
                  onChange={(e) => setSubtitel(e.target.value)}
                />
              </div>
            </div>
            <hr className="hr1" />
            <div className="selected-inspectie-row-title">
              <h1 className="inspectie-header">1. Inleiding</h1>
            </div>
            <div className="rapportage-input-col">
              <div className="rapportage-input-row">
                <label className="label-inspectie">Inleiding algemeen </label>
                <Tekst toParent={setInleidingAlgemeen} />
              </div>
              <textarea
                placeholder="Type here"
                spellCheck={false}
                style={{ width: "70vw", height: "30vh" }}
                className="rapportage-textarea"
                value={inleidingAlgemeen}
                onChange={(e) => setInleidingAlgemeen(e.target.value)}
              />
              <div className="rapportage-input-row">
                <label className="label-inspectie">Kwaliteitswaarborg </label>
                <Tekst toParent={setInleidingKwaliteitswaarborg} />
              </div>
              <textarea
                placeholder="Type here"
                spellCheck={false}
                style={{ width: "70vw", height: "30vh" }}
                className="rapportage-textarea"
                value={inleidingKwaliteitswaarborg}
                onChange={(e) => setInleidingKwaliteitswaarborg(e.target.value)}
              />
              <div className="rapportage-input-row">
                <label className="label-inspectie">Herstelgebreken </label>
                <Tekst toParent={setInleidingHerstelgebreken} />
              </div>
              <textarea
                placeholder="Type here"
                spellCheck={false}
                style={{ width: "70vw", height: "30vh" }}
                className="rapportage-textarea"
                value={inleidingHerstelgebreken}
                onChange={(e) => setInleidingHerstelgebreken(e.target.value)}
              />
            </div>
            <hr className="hr1" />
            <div className="selected-inspectie-row-title">
              <h1 className="inspectie-header">2. Algemene gegevens</h1>
            </div>
            <div className="rapportage-input-col">
              <div className="rapportage-input-left">
                <label className="label-inspectie">Zichtbaar maken</label>
                <br />
                <Select
                  isMulti
                  isClearable
                  value={installatieverantwoordelijke}
                  options={zichtbaarMaken2}
                  onChange={(e) => {
                    setInstallatieverantwoordelijke(e.map((object) => object));
                  }}
                />
                <br />
                <label className="label-inspectie">Meetinstrumenten</label>
                <Instruments
                  defaults={meetinstrumenten}
                  toParent={setMeetinstrumenten}
                />
              </div>
            </div>
            <hr className="hr1" />
            <div className="selected-inspectie-row-title">
              <h1 className="inspectie-header">3. Kenmerken</h1>
            </div>
            <div className="rapportage-input-col">
              <Aansluitgegevens
                defaults={aansluitgegevensArr}
                toParent={setAansluitgegevenid}
                aansluitgegevens={aansluitgegevensArr}
                defaults2={objectfuncties}
                toParent2={setObjectfuncties}
                setArray={setAansluitgegevensArr}
              />
              <div className="rapportage-input-row">
                <label className="label-inspectie">
                  Kenmerkenen van technische installatie{" "}
                </label>
                <Tekst toParent={setKenmerkalgemeen} />
              </div>
              <textarea
                placeholder="Type here"
                spellCheck={false}
                style={{ width: "70vw", height: "30vh" }}
                className="rapportage-textarea"
                value={kenmerkAlgemeen}
                onChange={(e) => setKenmerkalgemeen(e.target.value)}
              />
            </div>
            <hr className="hr1" />
            <div className="selected-inspectie-row-title">
              <h1 className="inspectie-header">4. Inspectie</h1>
            </div>
            <div className="rapportage-input-col">
              <div className="rapportage-input-row">
                <label className="label-inspectie">Omvang inspectie </label>
                <Tekst toParent={setOmvanginspectie} />
              </div>
              <textarea
                placeholder="Type here"
                spellCheck={false}
                style={{ width: "70vw", height: "30vh" }}
                className="rapportage-textarea"
                value={omvanginspectie}
                onChange={(e) => setOmvanginspectie(e.target.value)}
              />
              <label className="label-inspectie">Niet geïnspecteerd:</label>
              <textarea
                placeholder="Type here"
                spellCheck={false}
                style={{ width: "70vw", height: "30vh" }}
                className="rapportage-textarea"
                value={nietgeinspecteerd}
                onChange={(e) => setNietgeinspecteerd(e.target.value)}
              />
            </div>
            <hr className="hr1" />
            <div className="selected-inspectie-row-title">
              <h1 className="inspectie-header">4.2 Uitgangspunten inspectie</h1>
            </div>
            <div className="rapportage-input-col">
              <div className="rapportage-input-row">
                <label className="label-inspectie">Reden van inspectie: </label>
                <Tekst toParent={setRedeninspectie} />
              </div>
              <textarea
                placeholder="Type here"
                spellCheck={false}
                style={{ width: "70vw", height: "30vh" }}
                className="rapportage-textarea"
                value={redeninspectie}
                onChange={(e) => setRedeninspectie(e.target.value)}
              />
              <label className="label-inspectie">Inspectie normen</label>
              <Normen
                defaults={uitgangspuntenArr}
                toParent={setNormid}
                setArray={setUitgangspuntenArr}
              />
              <label className="label-inspectie">Installatie normen</label>
              <NormenGetoetst
                defaults={uitgangspuntenArr2}
                toParent={setNormid2}
                setArray={setUitgangspuntenArr2}
              />
              <div className="rapportage-input-row">
                <label className="label-inspectie">
                  Beoordelingscriteria:{" "}
                </label>
                <Tekst toParent={setBeoordelingscriteria} />
              </div>
              <textarea
                placeholder="Type here"
                spellCheck={false}
                style={{ width: "70vw", height: "30vh" }}
                className="rapportage-textarea"
                value={beoordelingscriteria}
                onChange={(e) => setBeoordelingscriteria(e.target.value)}
              />
            </div>
            <hr className="hr1" />
            <div className="selected-inspectie-row-title">
              <h1 className="inspectie-header">4.3 Inspectie methode</h1>
            </div>
            <div className="rapportage-input-col">
              <div className="rapportage-input-row">
                <label className="label-inspectie">Controle punten: </label>
                <Tekst toParent={setBedrijfsomstandigheden} />
              </div>
              <textarea
                placeholder="Type here"
                spellCheck={false}
                style={{ width: "70vw", height: "30vh" }}
                className="rapportage-textarea"
                value={bedrijfsomstandigheden}
                onChange={(e) => setBedrijfsomstandigheden(e.target.value)}
              />
            </div>
            <hr className="hr1" />
            <div className="selected-inspectie-row-title">
              <h1 className="inspectie-header">5. Volgende inspectie</h1>
            </div>
            <div className="rapportage-input-col">
              <div className="rapportage-input-row">
                <label className="label-inspectie">Volgende inspectie </label>
                <Tekst toParent={setVolgendeInspectie} />
              </div>
              <textarea
                placeholder="Type here"
                spellCheck={false}
                style={{ width: "70vw", height: "30vh" }}
                className="rapportage-textarea"
                value={volgendeinspectie}
                onChange={(e) => setVolgendeInspectie(e.target.value)}
              />
              <label className="label-inspectie">Installatie leeftijd</label>
              <Select
                defaultValue={gekozenLeeftijd}
                options={installatieLeeftijd}
                onChange={async (e) => {
                  setLeeftijd(e.value);

                  let object_values = {
                    leeftijd: e.value.toString(),
                    kwaliteit: kwaliteit,
                    omgeving: omgevingsomstandigheden,
                    personen: personen,
                    matevantoezicht: matevantoezicht,
                  };

                  calculatePunten(object_values);
                }}
              />
              <br />
              <label className="label-inspectie">Installatie kwaliteit</label>
              <Select
                defaultValue={gekozenKwaliteit}
                options={installatieKwaliteit}
                onChange={(e) => {
                  setKwaliteit(e.value);
                  let object_values = {
                    leeftijd: leeftijd,
                    kwaliteit: e.value.toString(),
                    omgeving: omgevingsomstandigheden,
                    personen: personen,
                    matevantoezicht: matevantoezicht,
                  };

                  calculatePunten(object_values);
                }}
              />
              <br />
              <label className="label-inspectie">Omgevingsomstandigheden</label>
              <Select
                defaultValue={gekozenOmgeving}
                options={omgevingsOmstandigheden}
                onChange={(e) => {
                  setOmgevingsomstandigheden(e.value);
                  let object_values = {
                    leeftijd: leeftijd,
                    kwaliteit: kwaliteit,
                    omgeving: e.value.toString(),
                    personen: personen,
                    matevantoezicht: matevantoezicht,
                  };

                  calculatePunten(object_values);
                }}
              />
              <br />
              <label className="label-inspectie">
                Personen die installatie gebruiken
              </label>
              <Select
                defaultValue={gekozenPersonen}
                options={installatiePersonen}
                onChange={(e) => {
                  setPersonen(e.value);
                  let object_values = {
                    leeftijd: leeftijd,
                    kwaliteit: kwaliteit,
                    omgeving: omgevingsomstandigheden,
                    personen: e.value.toString(),
                    matevantoezicht: matevantoezicht,
                  };

                  calculatePunten(object_values);
                }}
              />
              <br />
              <label className="label-inspectie">Mate van toezicht</label>
              <Select
                defaultValue={gekozenRegelmaat}
                options={regelmaat}
                onChange={(e) => {
                  setMatevantoezicht(e.value);
                  let object_values = {
                    leeftijd: leeftijd,
                    kwaliteit: kwaliteit,
                    omgeving: omgevingsomstandigheden,
                    personen: personen,
                    matevantoezicht: e.value.toString(),
                  };

                  calculatePunten(object_values);
                }}
              />
              <br />
              <label style={{ marginTop: "2vw" }}>
                Punten totaal: {totaalPunten}
              </label>
              <br />
              <label style={{ marginTop: "2vw" }}>Volgende afspraak:</label>
              <DateTime
                locale="nl"
                timeFormat={false}
                dateFormat="DD MMMM Y"
                initialValue={datum ? new Date(datum.toString()) : new Date()}
                onChange={(e) => {
                  const date = new Date(e).toLocaleDateString("nl", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  });
                  setDatum(date);
                }}
              />
            </div>
            <hr className="hr1" />
            <div className="selected-inspectie-row-title">
              <h1 className="inspectie-header">6. Eind beoordeling</h1>
            </div>
            <div className="rapportage-input-col">
              <div className="rapportage-input-row">
                <label className="label-inspectie">Eindbeoordeling </label>
                <Tekst toParent={setBeoordeling} />
              </div>
              <textarea
                placeholder="Type here"
                spellCheck={false}
                style={{ width: "70vw", height: "30vh" }}
                className="rapportage-textarea "
                value={beoordeling}
                onChange={(e) => setBeoordeling(e.target.value)}
              />
              <div className="rapportage-input-row">
                <label className="label-inspectie">
                  Toelichting algemeen / aanbevelingen{" "}
                </label>
                <Tekst toParent={setToelichting} />
              </div>
              <textarea
                placeholder="Type here"
                spellCheck={false}
                style={{ width: "70vw", height: "30vh" }}
                className="rapportage-textarea"
                value={toelichting}
                onChange={(e) => setToelichting(e.target.value)}
              />
            </div>
            <hr className="hr1" />
            <div style={{ height: "100px" }}>
              <br />
              <button className="submit-rapportage-edit" onClick={onSubmit}>
                Aanpassen
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditReport;
