import React from 'react'
import { NavLink } from "react-router-dom";

//css
import '../../scss/registration/registration_layout.scss';

//Components
import ForgotPasswordForum from './Forgot_password_forum'

const Forgot_password_layout = () => {
    return (
        <div className='page-wrapper'>
            <div className="register-content-wrapper">
                <div className="left-section">
                    <div className="left-title">
                        <h1><strong>E<span>Z</span></strong> Inspect</h1>
                    </div>
                    <div className="left-text">
                        <h2>Wachtwoord vergeten</h2>
                        <p>Vraag een nieuw wachtwoord aan door het forumulier in te vullen.</p>
                        <div className="already-account-alert">
                            <p>Wil je inloggen? Klik dan op <NavLink to="/login">deze link</NavLink></p>
                        </div>
                    </div>
                </div>
                <div className="right-section-forget-password">
                    <div className="forget-password-form-wrapper">
                        <ForgotPasswordForum />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Forgot_password_layout