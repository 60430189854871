import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import { BsSearch, BsPlusLg } from "react-icons/bs";
import Swal from "sweetalert2";

//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

//Css
import "../../scss/teams/teams.scss";
import "../../scss/dashboard/dashboard_menu.scss";

import MemberMenu from "./MemberMenu.js";
import Nav from "../navigation/Nav";
import Menu from "../navigation/Menu";
import { invite_member, get_user } from "../../functions/api";

const Teams = () => {
  // permissions
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const navigate = useNavigate();

  const [show, setShowInvite] = useState(false);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const handleCloseInvite = () => setShowInvite(false);
  const handleShowInvite = () => setShowInvite(true);
  let filtered = "";
  const email = useRef("");
  if (data.all_staff !== undefined) {
    filtered = !search
      ? data.all_staff
      : data.all_staff.filter((obj) => {
          return (
            obj.first_name.toLowerCase().includes(search.toLowerCase()) ||
            obj.last_name.toLowerCase().includes(search.toLowerCase()) ||
            obj.job_title.toLowerCase().includes(search.toLowerCase()) ||
            obj.infix.toLowerCase().includes(search.toLowerCase())
          );
        });
  } else {
    filtered = data.user_info;
  }

  useEffect(() => {
    get_user()
      .then((result) => {
        if (result.ok) return result.json();
        throw result;
      })
      .then((data) => {
        setData(data.message.current_user);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (permissions.viewPermissions.showTeams === "0" || 0) {
          Swal.fire({
            title: "Geen toegang!",
            text: "U wordt terug gestuurd naar de vorige pagina.",
            timer: 1000,
            showConfirmButton: false,
          }).then(() => {
            navigate(-1);
          });
        }
        setLoading(false);
      });
  }, []);

  const InviteMember = () => {
    let object = {
      userid: data.user_info.user_id,
      email: email.current.value,
    };
    invite_member(object);
    handleCloseInvite();
    return Swal.fire({
      title: "Email verstuurd!",
      showConfirmButton: true,
    });
  };
  if (loading === true) {
    return (
      <div>
        <Nav page="Teams" />
        <div className="page-wrap-row" style={{ display: "block" }}>
          <Menu className="menu-container" />
          <div className="page-wrapper-teams">
            <div className="top-wrapper">
              <h2>Nodig uw medewerkers uit</h2>
              <div className="invite-button-wrapper">
                <button
                  className="select-report-create"
                  onClick={handleShowInvite}
                >
                  <p>Teamlid uitnodigen</p>
                </button>
              </div>
            </div>
            <div className="searchBar-wrapper">
              <BsSearch size={20} />
              <input
                className="teams-searchBar"
                type="text"
                placeholder="Zoek op naam"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="container-fluid team-wrapper">
              <div className={"row row-cols"}>
                <div className="col">
                  <div
                    className="invite-member"
                    style={{ cursor: "pointer" }}
                    onClick={handleShowInvite}
                  >
                    <div className="invite-circle-wrapper">
                      <div className="invite-circle">
                        <BsPlusLg size={40} />
                      </div>
                    </div>
                    <div className="invite-member-text">
                      <h2>Teamlid uitnodigen</h2>
                      <h3>
                        Werk samen met je collega's en ervaar samen het gemak
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          size="lg"
          show={show}
          onHide={handleCloseInvite}
          dialogClassName="border-radius-modal"
          centered
        >
          <button
            type="button"
            className="close-invite"
            onClick={handleCloseInvite}
          >
            <BsPlusLg size={18} />
          </button>
          <div className="modal-wrapper">
            <h2>Vul hier onder het mailadres in</h2>
            <h4>
              Naar het onderstaande mailadres word een uitnodiging gestuurd.
            </h4>
            <input ref={email} type="email" className="form-control" />
            <div className="button-wrapper">
              <button
                onClick={InviteMember}
                type="button"
                className="send-invite"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="#fff"
                    d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376V479.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z"
                  />
                </svg>
                <span>Uitnodiging versturen</span>
              </button>
              <button
                type="button"
                className="cancel-invite"
                onClick={handleCloseInvite}
              >
                <span>Annuleren</span>
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  if (loading === false) {
    return (
      <div>
        <Nav page="Teams" />
        <div className="page-wrap-row" style={{ display: "block" }}>
          <Menu className="menu-container" />
          <div className="page-wrapper-teams">
            <div
              className="top-wrapper"
              style={{ display: filtered === data.user_info ? "none" : "" }}
            >
              <h2>Nodig uw medewerkers uit</h2>
              <div className="invite-button-wrapper">
                <button
                  type="button"
                  className="select-report-create"
                  onClick={handleShowInvite}
                >
                  <p>Teamlid uitnodigen</p>
                </button>
              </div>
            </div>
            <div
              className="searchBar-wrapper"
              style={{ display: filtered === data.user_info ? "none" : "" }}
            >
              <BsSearch size={20} />
              <input
                className="teams-searchBar"
                type="text"
                placeholder="Zoek op naam"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="container-fluid team-wrapper">
              <div
                className={
                  filtered === data.user_info
                    ? "row row-cols"
                    : "row row-cols-1 row-cols-sm-2 row-cols-lg-3"
                }
              >
                <div
                  className="col"
                  style={{ display: filtered === data.user_info ? "none" : "" }}
                >
                  <div
                    className="invite-member"
                    style={{ cursor: "pointer" }}
                    onClick={handleShowInvite}
                  >
                    <div className="invite-circle-wrapper">
                      <div className="invite-circle">
                        <BsPlusLg size={40} />
                      </div>
                    </div>
                    <div className="invite-member-text">
                      <h2>Teamlid uitnodigen</h2>
                      <h3>
                        Werk samen met je collega's en ervaar samen het gemak
                      </h3>
                    </div>
                  </div>
                </div>
                {filtered === data.user_info ? (
                  <div
                    key={data.user_info.user_id}
                    className="col"
                    style={{
                      maxWidth: "450px",
                      margin: "auto",
                      marginTop: "125px",
                    }}
                  >
                    <div className="member">
                      <div className="member-top">
                        <MemberMenu
                          ownerid={
                            data.account_info === undefined
                              ? data.user_info.user_id
                              : data.account_info.account_id
                          }
                          id={data.user_info.user_id}
                          first_name={data.user_info.first_name}
                          infix={data.user_info.infix}
                          last_name={data.user_info.last_name}
                          job_title={data.user_info.job_title}
                        />
                      </div>
                      <hr />
                      <div className="member-avatar-wrapper">
                        <div className="member-avatar">
                          <h2>
                            {data.user_info.first_name.charAt(0) +
                              data.user_info.last_name.charAt(0)}
                          </h2>
                        </div>
                      </div>
                      <div className="member-bottom">
                        <h2>
                          {data.user_info.first_name +
                            " " +
                            data.user_info.infix +
                            " " +
                            data.user_info.last_name}
                        </h2>
                        <h3 style={{ fontWeight: "Bold" }}>
                          {data.user_info.job_title}
                        </h3>
                      </div>
                    </div>
                  </div>
                ) : (
                  filtered.map((obj) => {
                    return (
                      <div
                        key={obj.userid}
                        id={obj.userid}
                        style={{ display: obj.isActive === "0" ? "none" : "" }}
                        className="col"
                      >
                        <div className="member">
                          <div className="member-top">
                            <MemberMenu
                              ownerId={data.user_info.user_id}
                              id={obj.userid}
                              first_name={obj.first_name}
                              last_name={obj.last_name}
                              job_title={obj.job_title}
                              infix={obj.infix}
                            />
                          </div>
                          <hr />
                          <div className="member-avatar-wrapper">
                            <div className="member-avatar">
                              <h2>
                                {obj.first_name.charAt(0) +
                                  obj.last_name.charAt(0)}
                              </h2>
                            </div>
                          </div>
                          <div className="member-bottom">
                            <h2>
                              {obj.first_name +
                                " " +
                                obj.infix +
                                " " +
                                obj.last_name}
                            </h2>
                            <h3
                              style={{
                                display:
                                  obj.userid === data.user_info.user_id
                                    ? ""
                                    : "none",
                                fontWeight: "Bold",
                              }}
                            >
                              Eindverantwoordelijke
                            </h3>
                            <h3
                              style={{
                                fontWeight:
                                  obj.userid === data.user_info.user_id
                                    ? "Bold"
                                    : "400",
                              }}
                            >
                              {obj.job_title}
                            </h3>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal
          size="lg"
          show={show}
          onHide={handleCloseInvite}
          dialogClassName="border-radius-modal"
          centered
        >
          <button
            type="button"
            className="close-invite"
            onClick={handleCloseInvite}
          >
            <BsPlusLg className="mx-[13.15px] mt-[1px]" size={18} />
          </button>
          <div className="modal-wrapper">
            <h2>Vul hier onder het mailadres in</h2>
            <h4>
              Naar het onderstaande mailadres word een uitnodiging gestuurd.
            </h4>
            <input ref={email} type="email" className="form-control" />
            <div className="mt-2 flex flex-row justify-start">
              <button
                onClick={InviteMember}
                type="button"
                className="px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded-lg flex flex-row"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  viewBox="0 0 512 512"
                  className="mr-2 mt-1"
                >
                  <path
                    fill="#fff"
                    d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376V479.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z"
                  />
                </svg>
                <span>Uitnodiging versturen</span>
              </button>
              <button
                type="button"
                className="px-4 py-2 mx-2 text-red-400 hover:text-red-700 hover:bg-red-200 rounded-lg bg-red-100"
                onClick={handleCloseInvite}
              >
                <span>Annuleren</span>
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
};

export default Teams;
