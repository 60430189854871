import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRouteLogin = ({ children, is_auth }) => {
  return is_auth === "200" || is_auth === 200 ? (
    <Navigate to="/reports" replace={true} />
  ) : (
    children
  );
};

export default PrivateRouteLogin;
