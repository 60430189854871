import React from "react";
import { logoutExpress } from "../../functions/api";
export default function Nav() {
  const removeCookies = async () => {
    var allCookies = document.cookie.split(";");

    // The "expire" attribute of every cookie is
    // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
    for (let i = 0; i < allCookies.length; i++) {
      document.cookie =
        allCookies[i] + "=;expires=" + new Date(0).toUTCString();
    }

    return true;
  };

  const logout = async () => {
    // remove data layers express server
    logoutExpress()
      .then((response) => response.json())
      .then((response) => {
        if (response.msg === "valid-logout") {
          // first remove cookies
          removeCookies();
          localStorage.clear();
          window.location.reload();
        }
      });
  };
  // bg color #060a24
  return (
    <nav className="fixed top-0 w-full py-2 flex bg-[#060a24] z-50 h-[62px] items-center">
      <div className="w-full h-full flex flex-row items-center justify-center text-center mx-2">
        <svg
          width={92}
          className="fixed left-0 ml-8"
          viewBox="0 0 167 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.74538 4.54772V9.26141H13.4395V13.6432H5.74538V18.7552H14.4474V23.3029H0V0H14.4474V4.54772H5.74538Z"
            fill="white"
          />
          <path
            d="M24.2924 18.6556H34.4392V23.3029H17.7742V18.9876L27.8539 4.6473H17.7742V0H34.4392V4.31535L24.2924 18.6556Z"
            fill="#D63230"
          />
          <path
            d="M49.5793 0.232364V23.3029H45.7491V0.232364H49.5793Z"
            fill="white"
          />
          <path
            d="M63.9486 4.71369C65.4045 4.71369 66.7037 5.01245 67.846 5.60996C69.0108 6.20747 69.9179 7.09267 70.5675 8.26556C71.2171 9.43845 71.5419 10.8548 71.5419 12.5145V23.3029H67.7452V13.0788C67.7452 11.4412 67.3308 10.1909 66.5021 9.3278C65.6733 8.4426 64.5421 8 63.1086 8C61.6751 8 60.5327 8.4426 59.6815 9.3278C58.8528 10.1909 58.4384 11.4412 58.4384 13.0788V23.3029H54.6081V5.01245H58.4384V7.10373C59.0656 6.35131 59.8607 5.76487 60.8239 5.3444C61.8095 4.92393 62.851 4.71369 63.9486 4.71369Z"
            fill="white"
          />
          <path
            d="M83.1473 23.6017C81.6914 23.6017 80.3811 23.3472 79.2163 22.8382C78.0739 22.3071 77.1668 21.5989 76.4948 20.7137C75.8228 19.8064 75.4644 18.7994 75.4196 17.6929H79.3843C79.4515 18.4675 79.8211 19.1203 80.493 19.6515C81.1874 20.1604 82.0498 20.4149 83.0801 20.4149C84.1553 20.4149 84.9841 20.2158 85.5665 19.8174C86.1712 19.397 86.4736 18.8658 86.4736 18.2241C86.4736 17.538 86.1376 17.029 85.4657 16.6971C84.8161 16.3651 83.7745 16 82.341 15.6017C80.9522 15.2254 79.8211 14.8603 78.9475 14.5062C78.0739 14.1521 77.3124 13.61 76.6628 12.8797C76.0356 12.1494 75.722 11.1867 75.722 9.9917C75.722 9.01798 76.0132 8.13278 76.5956 7.3361C77.178 6.51729 78.0067 5.87552 79.0819 5.41079C80.1795 4.94606 81.4338 4.71369 82.845 4.71369C84.9505 4.71369 86.6416 5.24481 87.9184 6.30705C89.2175 7.34716 89.9119 8.77455 90.0015 10.5892H86.1712C86.104 9.7704 85.768 9.11756 85.1633 8.6307C84.5585 8.14384 83.7409 7.90041 82.7106 7.90041C81.7026 7.90041 80.9298 8.08852 80.3923 8.46473C79.8547 8.84094 79.5859 9.33887 79.5859 9.95851C79.5859 10.4454 79.7651 10.8548 80.1235 11.1867C80.4818 11.5187 80.9186 11.7842 81.4338 11.9834C81.949 12.1604 82.7106 12.3928 83.7185 12.6805C85.0625 13.0346 86.16 13.3997 87.0112 13.7759C87.8848 14.13 88.6351 14.6611 89.2623 15.3693C89.8895 16.0775 90.2143 17.018 90.2367 18.1909C90.2367 19.231 89.9455 20.1604 89.3631 20.9793C88.7807 21.7981 87.952 22.4398 86.8768 22.9046C85.824 23.3693 84.5809 23.6017 83.1473 23.6017Z"
            fill="white"
          />
          <path
            d="M98.2056 7.70124C98.8552 6.8603 99.7399 6.15214 100.86 5.57676C101.98 5.00138 103.245 4.71369 104.657 4.71369C106.269 4.71369 107.736 5.11203 109.058 5.90871C110.402 6.68326 111.455 7.7787 112.216 9.19502C112.978 10.6113 113.359 12.2379 113.359 14.0747C113.359 15.9115 112.978 17.5602 112.216 19.0207C111.455 20.4592 110.402 21.5878 109.058 22.4066C107.736 23.2033 106.269 23.6017 104.657 23.6017C103.245 23.6017 101.991 23.325 100.893 22.7718C99.7959 22.1964 98.9 21.4882 98.2056 20.6473V32H94.3753V5.01245H98.2056V7.70124ZM109.461 14.0747C109.461 12.8133 109.192 11.7289 108.655 10.8216C108.14 9.89212 107.445 9.19502 106.572 8.73029C105.721 8.24343 104.802 8 103.817 8C102.853 8 101.935 8.24343 101.061 8.73029C100.21 9.21715 99.516 9.92531 98.9784 10.8548C98.4632 11.7842 98.2056 12.8797 98.2056 14.1411C98.2056 15.4025 98.4632 16.509 98.9784 17.4606C99.516 18.39 100.21 19.0982 101.061 19.5851C101.935 20.0719 102.853 20.3154 103.817 20.3154C104.802 20.3154 105.721 20.0719 106.572 19.5851C107.445 19.0761 108.14 18.3458 108.655 17.3942C109.192 16.4426 109.461 15.3361 109.461 14.0747Z"
            fill="white"
          />
          <path
            d="M134.114 13.7095C134.114 14.3956 134.069 15.0152 133.979 15.5685H119.834C119.946 17.029 120.495 18.2019 121.481 19.0871C122.466 19.9723 123.676 20.4149 125.109 20.4149C127.17 20.4149 128.626 19.5629 129.477 17.8589H133.61C133.05 19.5408 132.031 20.9239 130.552 22.0083C129.096 23.0705 127.282 23.6017 125.109 23.6017C123.34 23.6017 121.749 23.2144 120.338 22.4398C118.949 21.6432 117.852 20.5367 117.046 19.1203C116.262 17.6819 115.87 16.0221 115.87 14.1411C115.87 12.26 116.25 10.6113 117.012 9.19502C117.796 7.75657 118.882 6.65007 120.271 5.87552C121.682 5.10097 123.295 4.71369 125.109 4.71369C126.856 4.71369 128.413 5.0899 129.779 5.84232C131.146 6.59474 132.21 7.65698 132.971 9.02905C133.733 10.379 134.114 11.9391 134.114 13.7095ZM130.115 12.5145C130.093 11.1203 129.589 10.0028 128.604 9.16182C127.618 8.32088 126.397 7.90041 124.941 7.90041C123.62 7.90041 122.489 8.32088 121.548 9.16182C120.607 9.98063 120.047 11.0982 119.868 12.5145H130.115Z"
            fill="white"
          />
          <path
            d="M136.606 14.1411C136.606 12.26 136.987 10.6113 137.749 9.19502C138.533 7.75657 139.608 6.65007 140.974 5.87552C142.34 5.10097 143.908 4.71369 145.678 4.71369C147.918 4.71369 149.766 5.24481 151.222 6.30705C152.7 7.34716 153.697 8.84094 154.212 10.7884H150.079C149.743 9.88105 149.206 9.17289 148.467 8.6639C147.727 8.15491 146.798 7.90041 145.678 7.90041C144.11 7.90041 142.856 8.45366 141.915 9.56016C140.997 10.6445 140.537 12.1715 140.537 14.1411C140.537 16.1106 140.997 17.6487 141.915 18.7552C142.856 19.8617 144.11 20.4149 145.678 20.4149C147.895 20.4149 149.363 19.4523 150.079 17.527H154.212C153.674 19.3859 152.666 20.8686 151.188 21.9751C149.71 23.0595 147.873 23.6017 145.678 23.6017C143.908 23.6017 142.34 23.2144 140.974 22.4398C139.608 21.6432 138.533 20.5367 137.749 19.1203C136.987 17.6819 136.606 16.0221 136.606 14.1411Z"
            fill="white"
          />
          <path
            d="M162.498 8.09958V18.2241C162.498 18.9101 162.655 19.408 162.968 19.7178C163.304 20.0055 163.864 20.1494 164.648 20.1494H167V23.3029H163.976C162.251 23.3029 160.93 22.9046 160.011 22.1079C159.093 21.3112 158.634 20.0166 158.634 18.2241V8.09958H156.45V5.01245H158.634V0.46473H162.498V5.01245H167V8.09958H162.498Z"
            fill="white"
          />
        </svg>
        <strong className="fixed mx-auto text-sm" id="dashboard-title">
          Dashboard
        </strong>

        <div className="fixed right-0 mr-10 z-50 hover:cursor-pointer text-sm">
          <p onClick={() => logout()}>Uitloggen</p>
        </div>
      </div>
    </nav>
  );
}
