import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Css
import "../../scss/registration/registration_forum.scss";

//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

//Api functions
import { forget_password } from "../../functions/api";

const Forgot_password_forum = () => {
  let [userInput, setUserInput] = useState([
    {
      form_step_1: {
        email: "",
      },
    },
  ]);
  let [accountStatus, setAccountStatus] = useState("");
  const navigate = useNavigate();

  const addUserInput = (inputName, inputValue) => {
    switch (inputName) {
      case "email":
        userInput[0].form_step_1.email = inputValue;
        setUserInput(userInput);
        break;
    }
  };

  const setButtonLoading = () => {
    const footerWrapper = document.getElementById("footer-wrapper");
    footerWrapper.classList.remove("notLoading");
    footerWrapper.classList.add("loading");
  };

  const setButtonNotLoading = () => {
    const footerWrapper = document.getElementById("footer-wrapper");
    footerWrapper.classList.remove("loading");
    footerWrapper.classList.add("notLoading");
  };

  const sendForgetPassword = () => {
    const email = userInput[0].form_step_1.email;
    setButtonLoading();
    forget_password(email).then((response) => {
      if (response.msg === "invalid-account") {
        setAccountStatus(response.msg);
        setButtonNotLoading();
      }
      if (response.msg === "valid-response") {
        navigate("/forgot_password_finish");
      }
    });
  };

  //Submit on enter key
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter") {
        event.preventDefault();
        sendForgetPassword();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <div>
      <div className="registration-body">
        <div className="input-section">
          <label htmlFor="">Email</label>
          <div className="input-color">
            <input
              type="text"
              id="company-name"
              onChange={(e) => addUserInput("email", e.target.value)}
            />
          </div>
        </div>
        <div
          className={
            accountStatus === "invalid-account"
              ? "login-error-message"
              : "hide-error-message"
          }
        >
          <div className="text-wrapper">
            <p>Dit email adres is onbekend bij ons</p>
          </div>
        </div>
      </div>

      <div className="registration-footer">
        <div id="footer-wrapper" className="footer-wrapper notLoading">
          <button
            onClick={() => sendForgetPassword()}
            className="next-step-btn"
          >
            Submit
            <svg
              width="23"
              height="16"
              viewBox="0 0 23 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.7071 8.77595C23.0976 8.38543 23.0976 7.75227 22.7071 7.36174L16.3431 0.99778C15.9526 0.607255 15.3195 0.607255 14.9289 0.99778C14.5384 1.3883 14.5384 2.02147 14.9289 2.41199L20.5858 8.06885L14.9289 13.7257C14.5384 14.1162 14.5384 14.7494 14.9289 15.1399C15.3195 15.5304 15.9526 15.5304 16.3431 15.1399L22.7071 8.77595ZM0 9.06885H22V7.06885H0V9.06885Z"
                fill="white"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              style={{
                margin: "auto",
                background: "none",
                display: "block",
                shapeRendering: "auto",
              }}
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <g transform="rotate(0 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.9166666666666666s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(30 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.8333333333333334s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(60 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.75s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(90 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.6666666666666666s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(120 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.5833333333333334s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(150 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.5s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(180 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.4166666666666667s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(210 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.3333333333333333s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(240 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.25s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(270 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.16666666666666666s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(300 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="-0.08333333333333333s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
              <g transform="rotate(330 50 50)">
                <rect
                  x={47}
                  y={24}
                  rx={3}
                  ry={6}
                  width={6}
                  height={12}
                  fill="#ffffff"
                >
                  <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="1s"
                    begin="0s"
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Forgot_password_forum;
