import React, { useState, useEffect } from "react";

import Popup from "reactjs-popup";

// Get Teksten
import { get_all_teksten } from "../../functions/api";

import "../../scss/reports/report.scss";
import "../../scss/reports/reports.scss";

export default function Tekst(props) {
  const [data, setData] = useState([]);
  let [loading, setLoading] = useState(true);
  let [search, setSearch] = useState("");

  // Filter through the object based on the user input, this will only filter on the labels from the library
  const filtered = !search
    ? data.result
    : data.result.filter((tekst) => {
        return tekst.labelnaam.toLowerCase().includes(search.toLowerCase());
      });
  useEffect(() => {
    get_all_teksten()
      .then((tekst) => {
        if (tekst.ok) {
          return tekst.json();
        }
        throw tekst;
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, []);
  if (loading === true) {
    return <div></div>;
  }
  if (loading === false) {
    return (
      <Popup
        trigger={
          <button style={{ background: "none", border: "none", width: "24px" }}>
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{ isolation: "isolate" }}
              viewBox="0 0 24 24"
              width="18pt"
              height="18pt"
            >
              <path
                d=" M 13.643 11.382 C 13.714 11.52 13.855 11.6 14 11.6 C 14.061 11.6 14.124 11.586 14.182 11.556 C 14.205 11.544 16.56 10.348 17.721 9.982 C 17.931 9.915 18.048 9.69 17.981 9.48 C 17.915 9.269 17.692 9.152 17.479 9.218 C 16.256 9.605 13.918 10.793 13.818 10.844 C 13.622 10.944 13.543 11.185 13.643 11.382 Z "
                id="Path"
                fill="rgb(6,10,36)"
              />
              <path
                d=" M 14 6.8 C 14.061 6.8 14.124 6.786 14.182 6.757 C 14.205 6.745 16.56 5.549 17.721 5.182 C 17.931 5.115 18.048 4.89 17.981 4.68 C 17.915 4.469 17.692 4.352 17.479 4.419 C 16.256 4.805 13.918 5.993 13.818 6.044 C 13.622 6.144 13.543 6.385 13.643 6.582 C 13.714 6.72 13.855 6.8 14 6.8 Z "
                id="Path"
                fill="rgb(6,10,36)"
              />
              <path
                d=" M 23.6 6 C 23.379 6 23.2 6.179 23.2 6.4 L 23.2 22 C 23.2 22.662 22.662 23.2 22 23.2 L 12.4 23.2 L 12.4 22.28 C 12.983 22.076 14.502 21.6 16 21.6 C 19.484 21.6 21.85 22.372 21.874 22.38 C 21.994 22.42 22.129 22.4 22.234 22.324 C 22.338 22.249 22.4 22.128 22.4 22 L 22.4 4.4 C 22.4 4.213 22.27 4.05 22.087 4.01 C 22.087 4.01 21.773 3.94 21.237 3.841 C 21.021 3.801 20.812 3.945 20.772 4.162 C 20.732 4.38 20.875 4.588 21.092 4.628 C 21.297 4.666 21.468 4.699 21.6 4.725 L 21.6 21.468 C 20.694 21.231 18.681 20.8 16 20.8 C 14.257 20.8 12.545 21.372 12.016 21.566 C 11.545 21.358 10.122 20.8 8.4 20.8 C 5.645 20.8 3.375 21.254 2.4 21.485 L 2.4 4.71 C 3.199 4.513 5.558 4 8.4 4 C 9.858 4 11.109 4.454 11.6 4.659 L 11.6 20.4 C 11.6 20.544 11.678 20.677 11.803 20.748 C 11.928 20.819 12.082 20.817 12.206 20.743 C 12.246 20.719 16.209 18.352 19.726 17.18 C 19.89 17.125 20 16.972 20 16.8 L 20 0.4 C 20 0.269 19.936 0.146 19.828 0.071 C 19.72 -0.003 19.583 -0.021 19.459 0.025 C 16.259 1.226 13.02 3.241 12.988 3.261 C 12.801 3.378 12.744 3.625 12.861 3.812 C 12.978 3.999 13.224 4.057 13.412 3.939 C 13.442 3.921 16.259 2.168 19.2 0.985 L 19.2 16.514 C 16.47 17.462 13.619 19.012 12.4 19.705 L 12.4 4.4 C 12.4 4.249 12.314 4.11 12.179 4.042 C 12.11 4.008 10.471 3.2 8.4 3.2 C 4.786 3.2 2.007 3.982 1.89 4.016 C 1.718 4.065 1.6 4.222 1.6 4.4 L 1.6 22 C 1.6 22.125 1.659 22.244 1.759 22.319 C 1.829 22.372 1.914 22.4 2 22.4 C 2.037 22.4 2.074 22.395 2.11 22.384 C 2.137 22.377 4.903 21.6 8.4 21.6 C 9.862 21.6 11.112 22.055 11.6 22.26 L 11.6 23.2 L 2 23.2 C 1.338 23.2 0.8 22.662 0.8 22 L 0.8 6.4 C 0.8 6.179 0.621 6 0.4 6 C 0.179 6 0 6.179 0 6.4 L 0 22 C 0 23.103 0.897 24 2 24 L 22 24 C 23.103 24 24 23.103 24 22 L 24 6.4 C 24 6.179 23.821 6 23.6 6 Z "
                id="Path"
                fill="rgb(6,10,36)"
              />
              <path
                d=" M 13.643 8.982 C 13.714 9.12 13.855 9.2 14 9.2 C 14.061 9.2 14.124 9.186 14.182 9.156 C 14.205 9.144 16.56 7.948 17.721 7.582 C 17.931 7.515 18.048 7.29 17.981 7.08 C 17.915 6.869 17.692 6.752 17.479 6.819 C 16.256 7.205 13.918 8.393 13.818 8.444 C 13.622 8.544 13.543 8.785 13.643 8.982 Z "
                id="Path"
                fill="rgb(6,10,36)"
              />
              <path
                d=" M 13.643 13.782 C 13.714 13.92 13.855 14 14 14 C 14.061 14 14.124 13.986 14.182 13.956 C 14.205 13.944 16.56 12.748 17.721 12.382 C 17.931 12.315 18.048 12.09 17.981 11.88 C 17.915 11.669 17.692 11.552 17.479 11.618 C 16.256 12.005 13.918 13.193 13.818 13.244 C 13.622 13.344 13.543 13.584 13.643 13.782 Z "
                id="Path"
                fill="rgb(6,10,36)"
              />
              <path
                d=" M 9.691 7.423 C 7.221 6.844 4.414 7.582 4.296 7.614 C 4.083 7.671 3.956 7.89 4.014 8.104 C 4.062 8.282 4.223 8.4 4.4 8.4 C 4.434 8.4 4.469 8.396 4.504 8.387 C 4.53 8.379 7.233 7.668 9.509 8.202 C 9.723 8.252 9.939 8.119 9.99 7.904 C 10.04 7.689 9.906 7.474 9.691 7.423 Z "
                id="Path"
                fill="rgb(6,10,36)"
              />
              <path
                d=" M 9.691 9.823 C 7.221 9.244 4.414 9.982 4.296 10.014 C 4.083 10.071 3.956 10.29 4.014 10.504 C 4.062 10.682 4.223 10.8 4.4 10.8 C 4.434 10.8 4.469 10.796 4.504 10.786 C 4.53 10.779 7.233 10.068 9.509 10.602 C 9.723 10.652 9.939 10.519 9.99 10.304 C 10.04 10.089 9.906 9.874 9.691 9.823 Z "
                id="Path"
                fill="rgb(6,10,36)"
              />
              <path
                d=" M 13.643 16.182 C 13.714 16.32 13.855 16.4 14 16.4 C 14.061 16.4 14.124 16.386 14.182 16.356 C 14.205 16.344 16.56 15.148 17.721 14.782 C 17.931 14.715 18.048 14.49 17.981 14.28 C 17.915 14.069 17.692 13.952 17.479 14.018 C 16.256 14.405 13.918 15.593 13.818 15.644 C 13.622 15.744 13.543 15.984 13.643 16.182 Z "
                id="Path"
                fill="rgb(6,10,36)"
              />
              <path
                d=" M 9.691 12.223 C 7.221 11.644 4.414 12.382 4.296 12.414 C 4.083 12.471 3.956 12.69 4.014 12.904 C 4.062 13.082 4.223 13.2 4.4 13.2 C 4.434 13.2 4.469 13.196 4.504 13.186 C 4.53 13.179 7.233 12.468 9.509 13.002 C 9.723 13.052 9.939 12.919 9.99 12.704 C 10.04 12.489 9.906 12.274 9.691 12.223 Z "
                id="Path"
                fill="rgb(6,10,36)"
              />
              <path
                d=" M 9.691 17.023 C 7.221 16.444 4.414 17.182 4.296 17.214 C 4.083 17.271 3.956 17.49 4.014 17.704 C 4.062 17.882 4.223 18 4.4 18 C 4.434 18 4.469 17.996 4.504 17.986 C 4.53 17.979 7.233 17.268 9.509 17.802 C 9.723 17.852 9.939 17.719 9.99 17.504 C 10.04 17.289 9.906 17.074 9.691 17.023 Z "
                id="Path"
                fill="rgb(6,10,36)"
              />
              <path
                d=" M 9.691 14.623 C 7.221 14.044 4.414 14.782 4.296 14.814 C 4.083 14.871 3.956 15.09 4.014 15.304 C 4.062 15.482 4.223 15.6 4.4 15.6 C 4.434 15.6 4.469 15.596 4.504 15.586 C 4.53 15.579 7.233 14.868 9.509 15.402 C 9.723 15.452 9.939 15.319 9.99 15.104 C 10.04 14.889 9.906 14.674 9.691 14.623 Z "
                id="Path"
                fill="rgb(6,10,36)"
              />
            </svg>
          </button>
        }
        modal
        lockScroll
        position="down right"
      >
        {(close) => (
          <div className="p-3 shadow-2xl shadow-black">
            <button className="close border-2" onClick={close}>
              close
            </button>
            <input
              className="modal-search text-[#060a24]"
              placeholder="Zoeken op label"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <hr
              className="w-full"
              style={{ height: "2px", backgroundColor: "black" }}
            />
            <div className="teksten">
              <div className="h-[520px] w-full overflow-auto">
                {filtered.map((object) => {
                  return (
                    <div
                      className="flex flex-col py-1 w-full"
                      key={object.biebtekstid}
                    >
                      <button
                        onClick={() => {
                          props.toParent(object.omschrijving);
                          return close();
                        }}
                      >
                        <label className="text-[#060a24] text-lg font-medium">
                          {object.labelnaam}
                        </label>
                        <p className="text-[#060a24] text-base">
                          {object.omschrijving}
                        </p>
                        <hr
                          className="w-full"
                          style={{ height: "2px", backgroundColor: "grey" }}
                        />
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}
