import React, { useRef, useState, useEffect } from "react";
import Nav from "../navigation/Nav.js";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Menu from "../navigation/Menu";
import { AiOutlineLeft } from "react-icons/ai";
import Select from "react-select";
// API

//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

//Css
import "../../scss/teams/member.scss";
import {
  update_member,
  show_member_permissions,
  update_permissions,
} from "../../functions/api.js";

const Member = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let [disabled, setDisabled] = useState(false);
  let [loading, setLoading] = useState(true);
  let [permissions, setPermissions] = useState();
  const [selectedOption, setSelectedOption] = useState([]);
  const firstName = useRef();
  const lastName = useRef();
  const infix = useRef();

  let [updateOptions, setUpdateOptions] = useState([]);
  let id = location.state.id;

  let sessionPermissions = JSON.parse(localStorage.getItem("permissions"));
  let options = [
    {
      value: 0,
      label: "Inspecties aanmaken",
    },
    {
      value: 1,
      label: "Rapportages aanmaken",
    },
    {
      value: 2,
      label: "Teamleden aanpassen",
    },
    {
      value: 3,
      label: "Administratie",
    },
    { value: 4, label: "Klanten" },
    {
      value: 5,
      label: "Rapportages",
    },
    { value: 6, label: "Scios" },
    {
      value: 7,
      label: "Teamleden",
    },
    { value: 8, label: "Teams" },
    {
      value: 9,
      label: "Subscripties",
    },
    { value: 10, label: "App login" },
    {
      value: 11,
      label: "Webapp login",
    },
    {
      value: 12,
      label: "Inspecties",
    },
    {
      value: 13,
      label: "Rapportages aanpassen",
    },
  ];

  useEffect(() => {
    show_member_permissions(id)
      .then((response) => {
        if (response.success === true) {
          return response;
        }
        throw response;
      })
      .then((data) => {
        setPermissions(data.message);
        let selected = [];
        const loginPerms = data.message.permissions.loginPermissions;
        const viewPerms = data.message.permissions.viewPermissions;
        const createPerms = data.message.permissions.createPermissions;
        const editPerms = data.message.permissions.editPermissions;

        if (createPerms.createInspection === "1") {
          selected.push({ value: 0, label: "Inspecties aanmaken" });
        }
        if (createPerms.createRapportage === "1") {
          selected.push({ value: 1, label: "Rapportages aanmaken" });
        }
        if (editPerms.editTeamMembers === "1") {
          selected.push({ value: 2, label: "Teamleden aanpassen" });
        }
        if (viewPerms.showAdministration === "1") {
          selected.push({ value: 3, label: "Administratie" });
        }
        if (viewPerms.showCustomers === "1") {
          selected.push({ value: 4, label: "Klanten" });
        }
        if (viewPerms.showRapportages === "1") {
          selected.push({ value: 5, label: "Rapportages" });
        }
        if (viewPerms.showScios === "1") {
          selected.push({ value: 6, label: "Scios" });
        }
        if (viewPerms.showTeamMembers === "1") {
          selected.push({ value: 7, label: "Teamleden" });
        }
        if (viewPerms.showTeams === "1") {
          selected.push({ value: 8, label: "Teams" });
        }
        if (viewPerms.showSubscriptions === "1") {
          selected.push({ value: 9, label: "Subscripties" });
        }
        if (loginPerms.appLogin === "1") {
          selected.push({ value: 10, label: "App login" });
        }
        if (loginPerms.webappLogin === "1") {
          selected.push({ value: 11, label: "Webapp login" });
        }
        if (viewPerms.showInspections === "1") {
          selected.push({ value: 12, label: "Inspecties" });
        }
        if (editPerms.editRapportage === "1") {
          selected.push({ value: 13, label: "Rapportages aanpassen" });
        }
        setSelectedOption(selected);
        setUpdateOptions(selected.map((object) => object.label));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        if (location.state.id === location.state.ownerId) {
          setDisabled((disabled = true));
        }
        setLoading(false);
      });
  }, [id]);

  const checkIfInputIsDisabled = () => {
    if (disabled === true) {
      document.addEventListener("keydown", (e) => {
        e.preventDefault();
      });
      document.addEventListener("pointerdown", (e) => {
        e.preventDefault();
      });
    }
  };

  const UpdateUser = async () => {
    if (location.state.ownerId === location.state.userId) {
      Swal.fire({
        title: "Geen toegang!",
        text: "U kunt de eigenaar niet aanpassen.",
        timer: 1000,
        showConfirmButton: false,
      }).then(() => {
        return navigate("/teams");
      });
    }
    setDisabled((disabled = true));
    let obj = {};
    let object = {
      userid: location.state.id,
      first_name:
        firstName.current.value !== undefined
          ? firstName.current.value
          : location.state.first_name,
      last_name:
        lastName.current !== undefined
          ? lastName.current.value
          : location.state.last_name,
      infix: infix.current.value,
    };
    if (updateOptions !== null || undefined) {
      obj = {
        permissionInfo: {
          permissionId: await permissions.permissions.permissionInfo
            .permissionId,
          accountUserId: await permissions.permissions.permissionInfo
            .accountUserId,
          userId: await permissions.permissions.permissionInfo.userId,
        },
        loginPermissions: {
          webappLogin:
            updateOptions.includes("Webapp login") === true ? "1" : "0",
          appLogin: updateOptions.includes("App login") === true ? "1" : "0",
        },
        viewPermissions: {
          showRapportages:
            updateOptions.includes("Rapportages") === true ? "1" : "0",
          showInspections:
            updateOptions.includes("Inspecties") === true ? "1" : "0",
          showCustomers: updateOptions.includes("Klanten") === true ? "1" : "0",
          showTeams: updateOptions.includes("Teams") === true ? "1" : "0",
          showTeamMembers:
            updateOptions.includes("Teamleden") === true ? "1" : "0",
          showSubscriptions:
            updateOptions.includes("Subscripties") === true ? "1" : "0",
          showScios: updateOptions.includes("Scios") === true ? "1" : "0",
          showAdministration:
            updateOptions.includes("Administratie") === true ? "1" : "0",
        },
        createPermissions: {
          createInspection:
            updateOptions.includes("Inspecties aanmaken") === true ? "1" : "0",
          createRapportage:
            updateOptions.includes("Rapportages aanmaken") === true ? "1" : "0",
        },
        editPermissions: {
          editTeamMembers:
            updateOptions.includes("Teamleden aanpassen") === true ? "1" : "0",
          editRapportage:
            updateOptions.includes("Rapportages aanpassen") === true
              ? "1"
              : "0",
        },
      };
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success mx-2",
        cancelButton: "btn btn-danger mx-2",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Weet je zeker dat je de gegevens wilt updaten?",
        text: "Het is niet mogelijk om terug te gaan.",
        showCancelButton: true,
        confirmButtonText: "Ja, Update!",
        cancelButtonText: "Nee, Annuleer!",
        reverseButtons: true,
        preConfirm: (id) => {
          if (id === false) {
            return Swal.DismissReason.cancel + navigate(-1);
          }
          update_member(object);
          if (obj !== null || undefined) {
            console.log();
            update_permissions(obj);
          }
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons
            .fire("Geupdate!", "Het teamlid is geupdate.")
            .then(() => {
              return navigate("/teams");
            });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          setDisabled((disabled = false));
          swalWithBootstrapButtons.fire(
            "Geannuleerd",
            "Teamlid is niet geupdate."
          );
        }
      });
    if (sessionPermissions.editPermissions.editTeamMembers === "0") {
      Swal.fire({
        title: "Geen toegang!",
        text: "U wordt terug gestuurd naar de vorige pagina.",
        timer: 1000,
        showConfirmButton: false,
      }).then(() => {
        navigate("/teams");
      });
    }
  };

  if (loading === true) {
    return (
      <div>
        <Nav page="Teams" />
        <div className="page-wrap-row" style={{ display: "block" }}>
          <Menu className="menu-container" />
          <div className="page-wrapper-member">
            <Link
              className="return-to-teams"
              to="/teams"
              style={{
                textDecoration: "none",
                color: "#757575",
              }}
            >
              <p className="return-to-teams">
                <AiOutlineLeft style={{ fontSize: "1vw" }} />
                terug
              </p>
            </Link>
            <div className="container-fluid member-wrapper pt-5">
              <div className="row gx-5">
                <div className="col-12 col-md-4">
                  <div className="member">
                    <div className="member-top"></div>
                    <hr />
                    <div className="member-avatar-wrapper">
                      <div className="member-avatar">
                        <h2>
                          {location.state.first_name.charAt(0) +
                            location.state.last_name.charAt(0)}
                        </h2>
                      </div>
                    </div>
                    <div className="member-bottom">
                      <h2>
                        {location.state.first_name +
                          " " +
                          location.state.infix +
                          " " +
                          location.state.last_name}
                      </h2>
                      <h3
                        style={{
                          display:
                            location.state.id === location.state.ownerId
                              ? ""
                              : "none",
                          fontWeight: "Bold",
                        }}
                      >
                        Eindverantwoordelijke
                      </h3>
                      <h3>{location.state.job_title}</h3>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="member-form">
                    <div className="form-group">
                      <label for="voornaam">Voornaam</label>
                      <input
                        defaultValue={location.state.first_name}
                        ref={firstName}
                        type="text"
                        className="form-control"
                        id={
                          disabled === true
                            ? "disableInput voornaam input"
                            : "voornaam input"
                        }
                        disabled={disabled}
                      />
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4 form-group">
                        <label for="tussenvoegsel">Tussenvoegsel</label>
                        <input
                          defaultValue={location.state.infix}
                          ref={infix}
                          type="text"
                          className="form-control"
                          id={
                            disabled === true
                              ? "disableInput tussenvoegsel input"
                              : "tussenvoegsel input"
                          }
                          disabled={disabled}
                        />
                      </div>
                      <div className="col-12 col-md-8 form-group">
                        <label for="achternaam">Achternaam</label>
                        <input
                          className={"form-control"}
                          id={
                            disabled === true
                              ? "disableInput achternaam input"
                              : "achternaam input"
                          }
                          defaultValue={location.state.last_name}
                          ref={lastName}
                          type="text"
                          disabled={disabled}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-8 form-group">
                      <Select
                        isMulti
                        isClearable={false}
                        closeMenuOnSelect={false}
                        options={options}
                        defaultValue={selectedOption}
                        isDisabled={disabled}
                      />
                    </div>
                    <div className="save-button-wrapper">
                      <button
                        disabled={disabled}
                        className="select-report-create"
                        onClick={() => UpdateUser()}
                      >
                        <p>Opslaan</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (loading === false) {
    return (
      <div>
        <Nav page="Teams" />
        <div className="page-wrap-row" style={{ display: "block" }}>
          <Menu className="menu-container" />
          <div className="page-wrapper-member w-full h-full">
            <Link
              className="fixed bottom-0 left-0 ml-4 mb-4"
              to="/teams"
              style={{
                textDecoration: "none",
                color: "#757575",
                width: "85px",
              }}
            >
              <p className="text-base flex flex-row">
                <AiOutlineLeft className="mt-1.5" />
                terug
              </p>
            </Link>
            <div className="container-fluid member-wrapper pt-8 lg:pt-24">
              <div className="row gx-5">
                <div className="col-12 col-md-4">
                  <div className="member">
                    <div className="member-top"></div>
                    <hr />
                    <div className="member-avatar-wrapper">
                      <div className="member-avatar">
                        <h2>
                          {location.state.first_name.charAt(0) +
                            location.state.last_name.charAt(0)}
                        </h2>
                      </div>
                    </div>
                    <div className="member-bottom">
                      <h2>
                        {location.state.first_name +
                          " " +
                          location.state.infix +
                          " " +
                          location.state.last_name}
                      </h2>
                      <h3
                        style={{
                          display:
                            location.state.id === location.state.ownerId
                              ? ""
                              : "none",
                          fontWeight: "Bold",
                        }}
                      >
                        Eindverantwoordelijke
                      </h3>
                      <h3>{location.state.job_title}</h3>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="member-form">
                    <div className="form-group">
                      <label for="voornaam">Voornaam</label>
                      <input
                        defaultValue={location.state.first_name}
                        ref={firstName}
                        type="text"
                        className="form-control"
                        id={
                          disabled === true
                            ? "disableInput voornaam input"
                            : "voornaam input"
                        }
                        disabled={disabled}
                        onKeyDown={checkIfInputIsDisabled}
                      />
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4 form-group">
                        <label for="tussenvoegsel">Tussenvoegsel</label>
                        <input
                          defaultValue={location.state.infix}
                          ref={infix}
                          type="text"
                          className="form-control"
                          id={
                            disabled === true
                              ? "disableInput tussenvoegsel input"
                              : "tussenvoegsel input"
                          }
                          disabled={disabled}
                          onKeyDown={checkIfInputIsDisabled}
                        />
                      </div>
                      <div className="col-12 col-md-8 form-group">
                        <label for="achternaam">Achternaam</label>
                        <input
                          defaultValue={location.state.last_name}
                          ref={lastName}
                          type="text"
                          className="form-control"
                          id={
                            disabled === true
                              ? "disableInput achternaam input"
                              : "achternaam input"
                          }
                          disabled={disabled}
                          onKeyDown={checkIfInputIsDisabled}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-8 form-group">
                      {console.log()}
                      <Select
                        isMulti
                        isClearable={false}
                        closeMenuOnSelect={false}
                        onChange={(e) =>
                          setUpdateOptions(e.map((object) => object.label))
                        }
                        options={options}
                        defaultValue={selectedOption}
                        isDisabled={disabled}
                        onKeyDown={checkIfInputIsDisabled}
                      />
                    </div>
                    <div className="save-button-wrapper">
                      {location.state.id === location.state.ownerId ? (
                        <button
                          className="select-report-create"
                          onKeyDown={checkIfInputIsDisabled}
                          disabled={disabled}
                        >
                          <p>Opslaan</p>
                        </button>
                      ) : (
                        <button
                          className="select-report-create"
                          onClick={() => UpdateUser()}
                          onKeyDown={checkIfInputIsDisabled}
                        >
                          <p>Opslaan</p>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Member;
