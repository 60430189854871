import { useLocation } from "react-router-dom";
import Nav from "../../navigation/Nav";
import Menu from "../../navigation/Menu";
import "../../../scss/reports/result.scss";
import { Link } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
function ReportResult() {
  const location = useLocation();
  return (
    <div className="select-report-container-main">
      <Nav page="Rapportages" />

      <div className="page-wrap-row">
        <Menu className="menu-container" />
        <Link
          className="fixed bottom-0 left-0 ml-4 mb-4"
          to="/reports"
          style={{
            textDecoration: "none",
            color: "#757575",
            width: "85px",
          }}
        >
          <p className="text-base flex flex-row">
            <AiOutlineLeft className="mt-1" />
            terug
          </p>
        </Link>
        <iframe
          src={location.state.url}
          title={location.state.name}
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
}
export default ReportResult;
