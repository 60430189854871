import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRouteForgotPassword = ({ children, id_status }) => {
  return id_status === 200 || id_status === "200" ? (
    children
  ) : (
    <Navigate to="/" replace={true} />
  );
};

export default PrivateRouteForgotPassword;
