import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";

// Get Teksten
import { get_all_instrumenten } from "../../functions/api";

function Instruments(props) {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState();
  const [selectedOption, setSelectedOption] = useState([]);

  useEffect(() => {
    get_all_instrumenten()
      .then((instruments) => {
        if (instruments.ok) {
          return instruments.json();
        }
        throw instruments;
      })
      .then((data) => {
        const optionsMeetinstrumenten = data.map((object) => {
          return {
            value: object.meetapparatuurid,
            label: object.naam,
          };
        });

        let array = props.defaults;
        setOptions(optionsMeetinstrumenten);
        let selectedMeetinstrumenten = undefined;
        // Check if there's anything in the defaults prop.
        if (props.defaults && props.defaults !== undefined) {
          // Map through the array and filter to check if there's an option selected already.
          selectedMeetinstrumenten = array.map((object) => {
            let currentOption = optionsMeetinstrumenten.filter(
              (obj_option) => obj_option.value === object
            );

            return {
              value: currentOption[0].value,
              label: currentOption[0].label,
            };
          });
        }

        setSelectedOption(selectedMeetinstrumenten);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading === true) {
    return <div></div>;
  }
  if (loading === false) {
    return (
      <ReactSelect
        isMulti
        defaultValue={selectedOption}
        options={options}
        onChange={(e) => {
          props.toParent(e.map((object) => object.value));
        }}
        isClearable={false}
        closeMenuOnSelect={false}
      />
    );
  }
}

export default Instruments;
