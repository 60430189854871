import React, { useState, useEffect } from "react";
import Select from "react-select";

// Get object functies
import { get_all_objecten } from "../../functions/api";

function ObjectFunctions(props) {
  const [loading, setLoading] = useState(true);

  let [options, setOptions] = useState();
  let [selectedObjects, setSelectedObjects] = useState();

  useEffect(() => {
    get_all_objecten()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        const options = data.map((object) => {
          return {
            value: object.objectfunctieid,
            label: object.naam,
          };
        });
        setOptions(options);

        let selectedobj = undefined;
        // Check if the defaults prop is empty or not
        if (props.defaults && props.defaults !== undefined) {
          selectedobj = props.defaults.map((object) => {
            // Map through the array and filter to check if there's an option selected already.
            let currentOption = options.filter(
              (obj_options) => obj_options.value === object
            );

            return {
              value: currentOption[0].value,
              label: currentOption[0].label,
            };
          });
        }
        setSelectedObjects(selectedobj);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading === true) {
    return <div></div>;
  }
  if (loading === false) {
    return (
      <Select
        isMulti
        isClearable={false}
        defaultValue={selectedObjects}
        options={options}
        onChange={(e) => props.toParent(e.map((object) => object.value))}
        closeMenuOnSelect={false}
      />
    );
  }
}

export default ObjectFunctions;
