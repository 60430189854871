import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

//Css
import "../../scss/dashboard/dashboard.scss";

//Modal
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

//Api functions
import { get_user } from "../../functions/api";
import { subscribe_package } from "../../functions/api";
import { update_subscribe_package } from "../../functions/api";
import { checkIfPaymentIdentificationIsValid } from "../../functions/api";

//Navigation
import Menu from "../navigation/Menu";
import Nav from "../navigation/Nav";

const Dashboard = () => {
  const [data, setData] = useState(null);
  let [pakketId, setPakketId] = useState();
  const [showPayment, setShowPayment] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  //Modal state management payment modal
  const handleClosePayment = () => setShowPayment(false);
  const handleShowPayment = () => setShowPayment(true);

  //Modal state management cofirmation modal
  const handleCloseConfirmation = () => setShowConfirmation(false);

  //Navigation
  const navigate = useNavigate();

  useEffect(() => {
    get_user()
      .then((user) => {
        if (user.ok) {
          return user.json();
        }
        throw user;
      })
      .then((data) => {
        setData(data.message);

        //Set dashboard title
        if (
          data.message.current_user.subscription_info.current_subscription
            .length === 0
        ) {
          document.getElementById("dashboard-title").innerHTML =
            "Activeer uw account";
        } else {
          document.getElementById("dashboard-title").innerHTML = "Dashboard";
        }

        //Set dashboard user name
        document.getElementById("name").innerText =
          data.message.current_user.user_info.first_name +
          " " +
          data.message.current_user.user_info.last_name;

        //Display correct trial data
        if (data.message.current_user.subscription_info.trial_left !== null) {
          if (
            data.message.current_user.subscription_info.trial_left.d < 1 &&
            data.message.current_user.subscription_info.trial_left.h < 1 &&
            data.message.current_user.subscription_info.trial_left.i !== 0
          ) {
            document.getElementById("trial_length").innerHTML =
              "Uw proef account loopt af in " +
              data.message.current_user.subscription_info.trial_left.i +
              " minuten " +
              "<a href=''>Upgrade nu</a>";
          } else if (
            data.message.current_user.subscription_info.trial_left.i <= 0
          ) {
            document.getElementById("trial_length").innerHTML =
              "Uw proef account is afgelopen " + "<a href=''>Upgrade nu</a>";
          } else {
            document.getElementById("trial_length").innerHTML =
              "Uw proef account loopt af in " +
              data.message.current_user.subscription_info.trial_left.d +
              " dagen " +
              data.message.current_user.subscription_info.trial_left.h +
              " uur " +
              "<a href=''>Upgrade nu</a>";
          }
        } else {
          const periodId =
            data.message.current_user.subscription_info.current_subscription[0]
              .periodid;
          const period =
            (periodId && periodId === 2) || periodId === "2"
              ? "(maandelijks)"
              : "(jaarlijks)";
          document.getElementById("trial_length").innerHTML =
            "Uw huidige abbonoment is: " +
            data.message.current_user.subscription_info.current_subscription[0]
              .name +
            " " +
            period;
        }

        //Change the subscription buttons
        if (
          data.message.current_user.subscription_info.current_subscription
            .length !== 0
        ) {
          const planId =
            data.message.current_user.subscription_info.current_subscription[0]
              .planid;
          const basicButton = document.querySelectorAll(".button-basic");
          const advancedButton = document.querySelectorAll(".button-advanced");

          if (
            planId === 2 ||
            planId === "2" ||
            planId === 3 ||
            planId === "3"
          ) {
            for (let i = 0; i < basicButton.length; i++) {
              basicButton[i].style.display = "none";
              if (planId === 2 || planId === "2") {
                break;
              }
            }
          }

          if (
            planId === 4 ||
            planId === "4" ||
            planId === 5 ||
            planId === "5"
          ) {
            for (let i = 0; i < advancedButton.length; i++) {
              advancedButton[i].style.display = "none";
              if (planId === 4 || planId === "4") {
                break;
              }
            }

            //Prevents user from downgrading.
            for (let i = 0; i < basicButton.length; i++) {
              basicButton[i].style.display = "none";
            }
          }

          //Change the subscription toggle based on the current subscription
          const packagePeriodToggle = document.getElementById(
            "package-options-period-switch"
          );
          if (
            planId === 2 ||
            planId === "2" ||
            planId === 4 ||
            planId === "4"
          ) {
            packagePeriodToggle.checked = true;
            switchPackageMonth();
          } else if (
            planId === 3 ||
            planId === "3" ||
            planId === 5 ||
            planId === "5"
          ) {
            packagePeriodToggle.checked = false;
            switchPackageMonth();
          }

          //Gray out the downgrade package
          if (
            planId === 4 ||
            planId === "4" ||
            planId === 5 ||
            planId === "5"
          ) {
            basicButton.forEach((btn) => {
              btn.classList.add("downgrade");
            });
          }
        }
      });

    //Check if there is an payment identification id in the url if so we will check if it is payed and already showed or not
    const urlParams = new URLSearchParams(window.location.search);
    const paymentIdentification = urlParams.get("payment_identification");

    //If there is an paymentIdentificationId check if it is valid
    if (paymentIdentification !== null) {
      //
      checkIfPaymentIdentificationIsValid(paymentIdentification).then(
        (data) => {
          //Display the payment verification modal
          if (data === 0 || data === "0") {
            const url = new URL(window.location);
            url.searchParams.set("payment_identification", "");
            window.history.pushState({}, "", url);
            navigate("/dashboard");
            setShowConfirmation(true);
          }
        }
      );
    }

    // permissions
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    if (permissions.viewPermissions.showSubscriptions === "0") {
      Swal.fire({
        title: "Geen toegang!",
        text: "U wordt terug gestuurd naar de vorige pagina.",
        timer: 1000,
        showConfirmButton: false,
      }).then(() => {
        navigate(-1);
      });
    }
  }, []);

  const switchPackageMonth = () => {
    const packagePeriodToggle = document.getElementById(
      "package-options-period-switch"
    );
    if (packagePeriodToggle.checked === true) {
      document.getElementsByClassName("packages-monthly")[0].style.display =
        "flex";
      document.getElementsByClassName("packages-yearly")[0].style.display =
        "none";
    } else {
      document.getElementsByClassName("packages-monthly")[0].style.display =
        "none";
      document.getElementsByClassName("packages-yearly")[0].style.display =
        "flex";
    }
  };

  const subscribe = async (package_id) => {
    if (data.current_user.subscription_info.current_subscription.length === 0) {
      //If the user hasnt made an verification payment. It will create an payment
      const subscribe_response = await subscribe_package(package_id);

      //Send user to mollie payement page
      window.location.replace(subscribe_response.checkout_link);
      return;
    }

    //If the user has already made an verification payment and is verified
    if (
      data.current_user.subscription_info.current_subscription[0].mandateid !==
      ""
    ) {
      setPakketId(package_id);
      handleShowPayment();
    } else {
      //If the user hasnt made an verification payment. It will create an payment
      const subscribe_response = await subscribe_package(package_id);
      //Send user to mollie payement page
      window.location.replace(subscribe_response.checkout_link);
    }
  };

  const updateSubscription = () => {
    const response = update_subscribe_package(pakketId);

    response.then((responseMessage) => {
      if (responseMessage.msg === "package-update-success") {
        window.history.replaceState(
          null,
          null,
          `?payment_identification=${responseMessage.payment_identification}`
        );
        handleClosePayment();
      }
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <div>
      <Nav />
      <div className="page-wrap-row">
        <Menu className="menu-container" />
        <div className="page-wrapper-dashboard">
          <div className="welcome-wrapper">
            <h2>
              Hallo <span id="name"></span>,
            </h2>
            <p>Dé inspectie app voor scope 8, scope 10 en scope 12!</p>
          </div>

          <div className="package-wrapper">
            <div className="package-title">
              <strong className="packages-title">De abonnementen</strong>
            </div>

            <div className="check-wrapper">
              <div className="check-inner-wrapper">
                <div className="check-content-wrapper">
                  <i className="fa-solid fa-check"></i>
                  <p>Gratis 14 dagen proefperiode</p>
                </div>

                <div className="check-content-wrapper">
                  <i className="fa-solid fa-check"></i>
                  <p>Alle functionaliteiten uitproberen</p>
                </div>

                <div className="check-content-wrapper">
                  <i className="fa-solid fa-check"></i>
                  <p>Elk moment opzegbaar</p>
                </div>
              </div>
            </div>

            <div className="package-switch-wrapper">
              <div className="package-options">
                <p>Jaarlijks betalen</p>
              </div>
              <div className="switch-wrapper">
                <label className="switch">
                  <input
                    id="package-options-period-switch"
                    type="checkbox"
                    onChange={(event) => switchPackageMonth(event)}
                  />
                  <span className="slider round" />
                </label>
              </div>
              <div className="package-options">
                <p>Maandelijks betalen</p>
              </div>
            </div>

            <div className="packages-wrapper packages-monthly">
              <div className="packages-inner-wrapper">
                <div className="package-card">
                  <strong>Basic</strong>
                  <div className="price-wrapper">
                    <span>€14,99</span>
                    <p>prijs per gebruiker per maand</p>
                    <hr />
                  </div>
                  <div className="package-option-info-wrapper">
                    <div className="package-option-info-inner-wrapper">
                      <p>Inspecteren Met Kopieerfunctie</p>
                      <p>Agenderen</p>
                      <p>Rapporten In PDF Genereren</p>
                      <p>Auto Save</p>
                      <p>Back-Up</p>
                      <p>Teksten Bibliotheek</p>
                    </div>
                  </div>
                  <div className="package-buy-btn">
                    <button
                      type="button"
                      className="button-basic"
                      onClick={() => subscribe(2)}
                    >
                      Koop nu
                    </button>
                  </div>
                </div>

                <div className="package-card-red">
                  <strong>Advanced</strong>
                  <div className="price-wrapper">
                    <span>€25,99</span>
                    <p>prijs per gebruiker per maand</p>
                    <hr />
                  </div>
                  <div className="package-option-info-wrapper">
                    <div className="package-option-info-inner-wrapper">
                      <p>
                        Gevulde Teksten Bibliotheek (Inleidingen En Normen
                        T.B.V. NEN1010, NEN3140/Scope 8,10 En 12)
                      </p>
                      <p>3 Maanden Rapporten Backup</p>
                      <p>Alles Van Basic</p>
                      <p>Onbeperkt Inspectie Rapporten Aanmaken</p>
                      <p>Offline inspecteren</p>
                    </div>
                  </div>
                  <div className="package-buy-btn">
                    <button
                      type="button"
                      className="button-advanced"
                      onClick={() => subscribe(4)}
                    >
                      Koop nu
                    </button>
                  </div>
                </div>

                <div className="package-card package-card-hidden">
                  <strong>Premium</strong>
                  <div className="price-wrapper">
                    <span>-,--</span>
                    <p>prijs per gebruiker per maand</p>
                    <hr />
                  </div>
                  <div className="package-option-info-wrapper">
                    <div className="package-option-info-inner-wrapper">
                      <p>Exporteren Naar Excel</p>
                      <p>Offline Inspecteren</p>
                      <p>Met Meerdere Mensen Werken Aan Een Inspectie</p>
                      <p>Notificaties</p>
                      <p>Volledige Personalisering Van Rapportages</p>
                      <p>Plus Alles Van Advanced</p>
                      <p>Koppelingen Met Externe Systemen</p>
                      <p>Binnenkort Beschikbaar!</p>
                    </div>
                  </div>
                  <div className="package-buy-btn">
                    <button type="button" className="button-premium">
                      Binnenkort
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="packages-wrapper packages-yearly">
              <div className="packages-inner-wrapper">
                <div className="package-card">
                  <strong>Basic</strong>
                  <div className="price-wrapper">
                    <span>€12,99</span>
                    <p>
                      prijs per gebruiker per maand / jaarlijks vooruitbetaald
                    </p>
                    <hr />
                  </div>
                  <div className="package-option-info-wrapper">
                    <div className="package-option-info-inner-wrapper">
                      <p>Inspecteren Met Kopieerfunctie</p>
                      <p>Agenderen</p>
                      <p>Rapporten In PDF Genereren</p>
                      <p>Auto Save</p>
                      <p>Back-Up</p>
                      <p>Teksten Bibliotheek</p>
                    </div>
                  </div>
                  <div className="package-buy-btn">
                    <button
                      type="button"
                      className="button-basic"
                      onClick={() => subscribe(3)}
                    >
                      Koop nu
                    </button>
                  </div>
                </div>

                <div className="package-card-red">
                  <strong>Advanced</strong>
                  <div className="price-wrapper">
                    <span>€23,99</span>
                    <p>
                      prijs per gebruiker per maand / jaarlijks vooruitbetaald
                    </p>
                    <hr />
                  </div>
                  <div className="package-option-info-wrapper">
                    <div className="package-option-info-inner-wrapper">
                      <p>
                        Gevulde Teksten Bibliotheek (Inleidingen En Normen
                        T.B.V. NEN1010, NEN3140/Scope 8,10 En 12)
                      </p>
                      <p>3 Maanden Rapporten Backup</p>
                      <p>Alles Van Basic</p>
                      <p>Onbeperkt Inspectie Rapporten Aanmaken</p>
                      <p>Offline inspecteren</p>
                    </div>
                  </div>
                  <div className="package-buy-btn">
                    <button
                      type="button"
                      className="button-advanced"
                      onClick={() => subscribe(5)}
                    >
                      Koop nu
                    </button>
                  </div>
                </div>

                <div className="package-card package-card-hidden">
                  <strong>Premium</strong>
                  <div className="price-wrapper">
                    <span>-,--</span>
                    <p>
                      prijs per gebruiker per maand / jaarlijks vooruitbetaald
                    </p>
                    <hr />
                  </div>
                  <div className="package-option-info-wrapper">
                    <div className="package-option-info-inner-wrapper">
                      <p>Exporteren Naar Excel</p>
                      <p>Met Meerdere Mensen Werken Aan Een Inspectie</p>
                      <p>Notificaties</p>
                      <p>Volledige Personalisering Van Rapportages</p>
                      <p>Plus Alles Van Advanced</p>
                      <p>Koppelingen Met Externe Systemen</p>
                      <p>Binnenkort Beschikbaar!</p>
                    </div>
                  </div>
                  <div className="package-buy-btn">
                    <button type="button" className="button-premium">
                      Binnenkort
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="dashboard-footer-wrapper">
          <p id="trial_length"></p>
        </div>
      </footer>

      {/* Update subscription modal */}
      <Modal show={showPayment} onHide={handleClosePayment}>
        <Modal.Header closeButton>
          <Modal.Title>Abonnement veranderen?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Weet je het zeker dat je het pakket wilt veranderen
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePayment}>
            Annuleren
          </Button>
          <Button variant="primary" onClick={() => updateSubscription()}>
            Bevestigen
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Payment conformation modal */}
      <Modal show={showConfirmation} onHide={handleCloseConfirmation}>
        <Modal.Header closeButton>
          <Modal.Title>Abonnement is geactiveerd</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Vanaf nu kun je volledig gebruik maken van EZinspect
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmation}>
            Oke
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Dashboard;
