import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";

//Css
import "../../scss/registration/registration_forum.scss";
import "../../scss/login/login_form.scss";

//Api functions
import { login, show_permissions } from "../../functions/api";

const Registration_forum = ({ is_auth }) => {
  const navigate = useNavigate();

  if (is_auth === 200 || is_auth === "200") {
    navigate("/dashboard");
  }

  const [passwordShown, setPasswordShown] = useState("");
  let [form_step] = useState(1);
  let [userInput, setUserInput] = useState([
    {
      form_step_1: {
        email: "",
        password: "",
      },
    },
  ]);
  let [loginStatus, setLoginStatus] = useState("");

  const addUserInput = (inputName, inputValue) => {
    switch (inputName) {
      case "email":
        userInput[0].form_step_1.email = inputValue;
        setUserInput(userInput);
        break;
      case "password":
        userInput[0].form_step_1.password = inputValue;
        setUserInput(userInput);
        break;
    }
  };

  const togglePassword = (passwordField) => {
    setPasswordShown(passwordField);
  };

  const loginUser = async () => {
    const form_data_1 = userInput[0].form_step_1;
    const username = form_data_1.email;
    const password = form_data_1.password;
    const loginResponse = await login(username, password);
    const loginMessage = await loginResponse.json();

    setLoginStatus(loginMessage.msg);
    const permissionResponse = await show_permissions();
    const jsonResponse = await permissionResponse.json();

    if (loginMessage.msg === "valid-login") {
      localStorage.setItem(
        "permissions",
        JSON.stringify(jsonResponse.message.permissions)
      );
      window.location.reload();
    }
  };

  //Submit on enter key
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter") {
        event.preventDefault();
        loginUser();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <div>
      <div className="registration-body">
        <div
          className="form-step-1"
          style={form_step === 1 ? { display: "block" } : { display: "none" }}
        >
          <form className="login-form" method="POST">
            <div className="input-section">
              <label className="login-lbl" htmlFor="">
                Email
              </label>
              <div className={"input-color"}>
                <input
                  type="text"
                  id="company-name"
                  onChange={(e) => addUserInput("email", e.target.value)}
                />
              </div>
            </div>
            <div className="input-section">
              <label className="login-lbl" htmlFor="">
                Wachtwoord
              </label>
              <div className={"input-color"}>
                <input
                  type={passwordShown === "1" ? "text" : "password"}
                  id="password"
                  onChange={(e) => addUserInput("password", e.target.value)}
                />
                <div
                  onClick={
                    passwordShown !== "1"
                      ? () => togglePassword("1")
                      : () => togglePassword("")
                  }
                  className="password-toggle"
                >
                  <i
                    className={
                      passwordShown === "1"
                        ? "fa-solid fa-eye"
                        : "fa-solid fa-eye-slash"
                    }
                  ></i>
                </div>
              </div>
            </div>
            <div className="login-register-info-wrapper">
              <p>
                Heb je nog geen account? Registreer je dan{" "}
                <NavLink className="login-nav-link" to="/">
                  hier
                </NavLink>
              </p>
              <p>
                <NavLink to="/forgot_password">Wachtwoord vergeten</NavLink>
              </p>
            </div>
            <div
              className={
                loginStatus === "invalid-login"
                  ? "login-error-message"
                  : "hide-error-message"
              }
            >
              <div className="text-wrapper">
                <p>Ongeldige login</p>
              </div>
            </div>
            {/* {loginStatus === 'valid-login' ? () =>  : ""} */}
          </form>
        </div>
      </div>

      <div className="registration-footer">
        <div className="footer-wrapper">
          <button className="next-step-btn" onClick={loginUser}>
            Login
            <svg
              width="23"
              height="16"
              viewBox="0 0 23 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.7071 8.77595C23.0976 8.38543 23.0976 7.75227 22.7071 7.36174L16.3431 0.99778C15.9526 0.607255 15.3195 0.607255 14.9289 0.99778C14.5384 1.3883 14.5384 2.02147 14.9289 2.41199L20.5858 8.06885L14.9289 13.7257C14.5384 14.1162 14.5384 14.7494 14.9289 15.1399C15.3195 15.5304 15.9526 15.5304 16.3431 15.1399L22.7071 8.77595ZM0 9.06885H22V7.06885H0V9.06885Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Registration_forum;
