import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

//Css
import "../../../scss/registration/registration_forum.scss";

//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

//Modal
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

//Api functions
import {
  register_team_member,
  check_invite_link,
} from "../../../functions/api";

const RegisterMember = () => {
  const navigate = useNavigate();
  let { id, token } = useParams();

  let [check, setCheck] = useState();
  let [checked, setChecked] = useState(true);
  const [passwordShown, setPasswordShown] = useState("");
  let [validationError, setValdationError] = useState([]);
  let [registerResponse, setRegisterResponse] = useState("");
  const [showPaymentRequirements, setShowPaymentRequirements] = useState(false);

  //Modal state management password requirements modal
  const handleClosePasswordRequirements = () =>
    setShowPaymentRequirements(false);

  //Submit on enter key
  useEffect(() => {
    check_invite_link(id, token)
      .then((result) => {
        return result;
      })
      .then((data) => {
        setCheck(data);
      })
      .catch(() => {});

    const listener = (event) => {
      if (event.code === "Enter") {
        event.preventDefault();
        registerUser();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  let [validationMessage] = useState({
    form_message: {
      first_name: new Set(),
      infix: new Set(),
      last_name: new Set(),
      email: new Set(),
      password1: new Set(),
      password2: new Set(),
      terms: new Set(),
    },
  });

  let [userInput, setUserInput] = useState([
    {
      form: {
        first_name: "",
        infix: "",
        last_name: "",
        email: "",
        password1: "",
        password2: "",
        terms: "",
      },
    },
  ]);

  const addUserInput = (inputName, inputValue) => {
    // eslint-disable-next-line default-case
    switch (inputName) {
      case "first_name":
        userInput[0]["form"].first_name = inputValue;
        setUserInput(userInput);
        break;
      case "infix":
        userInput[0].form.infix = inputValue;
        setUserInput(userInput);
        break;
      case "last_name":
        userInput[0].form.last_name = inputValue;
        setUserInput(userInput);
        break;
      case "email":
        userInput[0].form.email = inputValue;
        setUserInput(userInput);
        break;
      case "password1":
        userInput[0].form.password1 = inputValue;
        setUserInput(userInput);
        break;
      case "password2":
        userInput[0].form.password2 = inputValue;
        setUserInput(userInput);
        break;
      case "terms":
        userInput[0].form.terms = inputValue;
        setUserInput(userInput);
        break;
    }
  };

  const togglePassword = (passwordField) => {
    setPasswordShown(passwordField);
  };

  const addValidationError = (inputName) => {
    setValdationError((validationError) => [...validationError, inputName]);
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const inputValidation = () => {
    const form_data = userInput[0].form;
    const form_message = validationMessage.form_message;
    let counter = 0;

    setValdationError([]);

    for (let key in form_message) {
      form_message[key] = new Set();
    }

    //Email regex
    let emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    //Email validation
    if (!emailRegex.test(form_data.email)) {
      addValidationError("email");
      setValidationErrorMessage({ email: "Geen geldige email" });
      form_data.email = "";
    }

    //Password validation
    if (form_data.password1 !== form_data.password2) {
      for (let i = 0; i < 2; i++) {
        i === 0
          ? addValidationError(getKeyByValue(form_data, form_data.password1))
          : addValidationError(getKeyByValue(form_data, form_data.password2));
      }
      setValidationErrorMessage({ password1: "Wachtwoord komt niet overheen" });
      setValidationErrorMessage({ password2: "Wachtwoord komt niet overheen" });
    } else {
      //Password regex validation
      //Minimum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;

      if (!passwordRegex.test(form_data.password1)) {
        setValidationErrorMessage({
          password1: "Wachtwoord niet sterk genoeg",
        });
        addValidationError("password1");
        form_data.password1 = "";
      }

      if (!passwordRegex.test(form_data.password2)) {
        setValidationErrorMessage({
          password2: "Wachtwoord niet sterk genoeg",
        });
        addValidationError("password2");
        form_data.password2 = "";
      }
    }

    for (let key in form_data) {
      if (form_data[key] === "") {
        addValidationError(key);
        form_message[key].add("Verplichte invoer");
      }
      counter++;
    }
  };

  const toggleCheckbox = () => {
    setChecked(!checked);
    addUserInput("terms", checked);
  };

  const setValidationErrorMessage = (errorKeyAndValue) => {
    const form_message = validationMessage.form_message;
    if (errorKeyAndValue && Object.keys(errorKeyAndValue).length !== 0) {
      const errorKey = Object.keys(errorKeyAndValue)[0];
      const errorValue = errorKeyAndValue[errorKey];
      form_message[errorKey].add(errorValue);
    }
  };

  const displayValidationErrorMessage = (textId) => {
    const form_message = validationMessage.form_message;

    let validationErrorForm1 = form_message[textId];

    if (
      document.getElementById(textId) === undefined ||
      document.getElementById(textId === null)
    ) {
      return;
    }
    setTimeout(() => {
      if (form_message[textId] !== undefined) {
        document.getElementById(textId).innerHTML = "";
        if (validationErrorForm1 === undefined) {
          return;
        }

        const validationObject = validationErrorForm1.values();
        const validationValues = validationObject.next();
        if (validationValues.value === undefined) {
          return;
        }
        document.getElementById(textId).innerHTML = validationValues.value;
      }
    }, 100);
  };

  const registerUser = async () => {
    inputValidation();
    if (
      userInput[0].form.first_name === "" ||
      userInput[0].form.last_name === "" ||
      userInput[0].form.email === "" ||
      userInput[0].form.password1 === "" ||
      userInput[0].form.password2 === ""
    )
      return inputValidation();
    if (userInput[0].form.password1 !== userInput[0].form.password2)
      return inputValidation();

    let object = {
      form: {
        first_name: userInput[0].form.first_name,
        last_name: userInput[0].form.last_name,
        infix: userInput[0].form.infix,
        email: userInput[0].form.email,
        password1: userInput[0].form.password1,
        password2: userInput[0].form.password2,
        terms: userInput[0].form.terms,
      },
    };

    let response = await register_team_member(id, token, object);
    setRegisterResponse(response);

    if (response.message === "account-created") return registrationSuccess();
    else if (
      response.message === "account-already-exists" ||
      "invalid-email" ||
      "invalid-phone_number" ||
      "password-not-equal" ||
      "password-not-strong-enough"
    ) {
      setTimeout(() => {
        return;
      }, 500);
    }
  };
  const registrationSuccess = () => {
    sessionStorage.setItem("registrationStatus", "success");
    return navigate("/finish");
  };

  if (check === false) {
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
    return (
      <h1 style={{ color: "black", fontSize: "72px", textAlign: "center" }}>
        Invalid invite
      </h1>
    );
  }
  return (
    <div className="page-wrapper">
      <div className="register-content-wrapper">
        <div className="left-section">
          <div className="left-title">
            <h1>
              <strong>
                E<span>Z</span>
              </strong>{" "}
              Inspect
            </h1>
          </div>
          <div className="left-text">
            <h2>Registratie Teamlid</h2>
            <p>
              Meld je hier aan voor de eenvoudige inspectie app als teamlid bij
              je werkgever
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="registration-form-wrapper">
            <div className="registration-title">
              <div className="forum-step active">
                <p>Registreer</p>
              </div>
            </div>
            <br />
            <div className="registration-body">
              <div className="form-step-1" style={{ display: "block" }}>
                <form method="POST">
                  <div className="input-section-row">
                    <div className="input-wrapper">
                      <label className="login-lbl" htmlFor="">
                        Voornaam
                      </label>
                      <div
                        className={
                          validationError.includes("first_name")
                            ? "validationError"
                            : "input-color"
                        }
                      >
                        <input
                          type="text"
                          id="first-name"
                          onChange={(e) =>
                            addUserInput("first_name", e.target.value)
                          }
                          autoComplete="on"
                        />
                      </div>
                      <p className="validationMessage" id="first_name">
                        {displayValidationErrorMessage("first_name")}
                      </p>
                    </div>
                    <div className="input-wrapper">
                      <label className="login-lbl" htmlFor="">
                        Tussenvoegsel
                      </label>
                      <div className="input-color">
                        <input
                          type="text"
                          id="infix"
                          onChange={(e) =>
                            addUserInput("infix", e.target.value)
                          }
                          autoComplete="on"
                        />
                      </div>
                    </div>
                    <div className="input-wrapper">
                      <label className="login-lbl" htmlFor="">
                        Achternaam
                      </label>
                      <div
                        className={
                          validationError.includes("last_name")
                            ? "validationError"
                            : "input-color"
                        }
                      >
                        <input
                          type="text"
                          id="last-name"
                          onChange={(e) =>
                            addUserInput("last_name", e.target.value)
                          }
                          autoComplete="on"
                        />
                      </div>
                      <p className="validationMessage" id="last_name">
                        {displayValidationErrorMessage("last_name")}
                      </p>
                    </div>
                  </div>
                  <div className="input-section">
                    <label className="login-lbl" htmlFor="">
                      Email
                    </label>
                    <div
                      className={
                        validationError.includes("email")
                          ? "validationError"
                          : "input-color"
                      }
                    >
                      <input
                        type="text"
                        name="email"
                        onChange={(e) => addUserInput("email", e.target.value)}
                        autoComplete="on"
                      />
                    </div>
                    <p className="validationMessage" id="email">
                      {displayValidationErrorMessage("email")}
                    </p>
                  </div>
                  <div className="input-section-row">
                    <div className="input-wrapper">
                      <label className="login-lbl" htmlFor="">
                        Wachtwoord{" "}
                        <i
                          onClick={() => setShowPaymentRequirements(true)}
                          className="fa-solid fa-circle-question"
                        ></i>
                      </label>
                      <div
                        className={
                          validationError.includes("password1")
                            ? "validationError"
                            : "input-color"
                        }
                      >
                        <input
                          type={passwordShown === "1" ? "text" : "password"}
                          id="password"
                          onChange={(e) =>
                            addUserInput("password1", e.target.value)
                          }
                        />
                        <div
                          onClick={
                            passwordShown !== "1"
                              ? () => togglePassword("1")
                              : () => togglePassword("")
                          }
                          className="password-toggle"
                        >
                          <i
                            className={
                              passwordShown === "1"
                                ? "fa-solid fa-eye"
                                : "fa-solid fa-eye-slash"
                            }
                          ></i>
                        </div>
                      </div>
                      <p className="validationMessage" id="password1">
                        {displayValidationErrorMessage("password1")}
                      </p>
                    </div>
                    <div className="input-wrapper">
                      <label className="login-lbl" htmlFor="">
                        Herhaal wachtwoord
                      </label>
                      <div
                        className={
                          validationError.includes("password2")
                            ? "validationError"
                            : "input-color"
                        }
                      >
                        <input
                          type={passwordShown === "2" ? "text" : "password"}
                          id="repeat-password"
                          onChange={(e) =>
                            addUserInput("password2", e.target.value)
                          }
                        />
                        <div
                          onClick={
                            passwordShown !== "2"
                              ? () => togglePassword("2")
                              : () => togglePassword("")
                          }
                          className="password-toggle"
                        >
                          <i
                            className={
                              passwordShown === "2"
                                ? "fa-solid fa-eye"
                                : "fa-solid fa-eye-slash"
                            }
                          ></i>
                        </div>
                      </div>
                      <p className="validationMessage" id="password2">
                        {displayValidationErrorMessage("password2")}
                      </p>
                    </div>
                  </div>
                  <div style={{ marginTop: "-20px" }} className="terms-wrapper">
                    <input
                      type="checkbox"
                      id="terms-btn"
                      onClick={(e) => toggleCheckbox()}
                    />
                    <label
                      className={
                        validationError.includes("terms")
                          ? "validationErrorText"
                          : ""
                      }
                      htmlFor=""
                    >
                      Klik om de{" "}
                      <a
                        target="_blank"
                        className={
                          validationError.includes("terms")
                            ? "validationErrorText"
                            : ""
                        }
                        href="https://www.ezinspect.nl/wp-content/uploads/2022/04/algemene_voorwaarden_ezinspect.pdf"
                      >
                        voorwaarden
                      </a>{" "}
                      te accepteren
                    </label>
                  </div>
                  <div
                    id="account-exists"
                    className={
                      registerResponse.message === "account-already-exists"
                        ? "registration-error-message"
                        : "hide-error-message"
                    }
                  >
                    <div className="text-wrapper">
                      <p>Dit account is al geregistreerd</p>
                    </div>
                  </div>
                  <div
                    className={
                      registerResponse.message === "invalid-email"
                        ? "registration-error-message"
                        : "hide-error-message"
                    }
                  >
                    <div className="text-wrapper">
                      <p>U heeft een ongeldig email ingevoerd</p>
                    </div>
                  </div>
                  <div
                    className={
                      registerResponse.message === "password-not-equal"
                        ? "registration-error-message"
                        : "hide-error-message"
                    }
                  >
                    <div className="text-wrapper">
                      <p>De ingevoerde wachtwoorden komen niet overheen</p>
                    </div>
                  </div>
                  <div
                    className={
                      registerResponse.message === "password-not-strong-enough"
                        ? "registration-error-message"
                        : "hide-error-message"
                    }
                  >
                    <div className="text-wrapper">
                      <p>De ingevoerde wachtwoorden zijn niet sterk genoeg</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="registration-footer">
              <div id="footer-wrapper" className="footer-wrapper notLoading">
                <button
                  onClick={(e) => registerUser()}
                  className="next-step-btn"
                >
                  Registreer
                  <svg
                    width="23"
                    height="16"
                    viewBox="0 0 23 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.7071 8.77595C23.0976 8.38543 23.0976 7.75227 22.7071 7.36174L16.3431 0.99778C15.9526 0.607255 15.3195 0.607255 14.9289 0.99778C14.5384 1.3883 14.5384 2.02147 14.9289 2.41199L20.5858 8.06885L14.9289 13.7257C14.5384 14.1162 14.5384 14.7494 14.9289 15.1399C15.3195 15.5304 15.9526 15.5304 16.3431 15.1399L22.7071 8.77595ZM0 9.06885H22V7.06885H0V9.06885Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    style={{
                      margin: "auto",
                      background: "none",
                      display: "block",
                      shapeRendering: "auto",
                    }}
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                  >
                    <g transform="rotate(0 50 50)">
                      <rect
                        x={47}
                        y={24}
                        rx={3}
                        ry={6}
                        width={6}
                        height={12}
                        fill="#ffffff"
                      >
                        <animate
                          attributeName="opacity"
                          values="1;0"
                          keyTimes="0;1"
                          dur="1s"
                          begin="-0.9166666666666666s"
                          repeatCount="indefinite"
                        />
                      </rect>
                    </g>
                    <g transform="rotate(30 50 50)">
                      <rect
                        x={47}
                        y={24}
                        rx={3}
                        ry={6}
                        width={6}
                        height={12}
                        fill="#ffffff"
                      >
                        <animate
                          attributeName="opacity"
                          values="1;0"
                          keyTimes="0;1"
                          dur="1s"
                          begin="-0.8333333333333334s"
                          repeatCount="indefinite"
                        />
                      </rect>
                    </g>
                    <g transform="rotate(60 50 50)">
                      <rect
                        x={47}
                        y={24}
                        rx={3}
                        ry={6}
                        width={6}
                        height={12}
                        fill="#ffffff"
                      >
                        <animate
                          attributeName="opacity"
                          values="1;0"
                          keyTimes="0;1"
                          dur="1s"
                          begin="-0.75s"
                          repeatCount="indefinite"
                        />
                      </rect>
                    </g>
                    <g transform="rotate(90 50 50)">
                      <rect
                        x={47}
                        y={24}
                        rx={3}
                        ry={6}
                        width={6}
                        height={12}
                        fill="#ffffff"
                      >
                        <animate
                          attributeName="opacity"
                          values="1;0"
                          keyTimes="0;1"
                          dur="1s"
                          begin="-0.6666666666666666s"
                          repeatCount="indefinite"
                        />
                      </rect>
                    </g>
                    <g transform="rotate(120 50 50)">
                      <rect
                        x={47}
                        y={24}
                        rx={3}
                        ry={6}
                        width={6}
                        height={12}
                        fill="#ffffff"
                      >
                        <animate
                          attributeName="opacity"
                          values="1;0"
                          keyTimes="0;1"
                          dur="1s"
                          begin="-0.5833333333333334s"
                          repeatCount="indefinite"
                        />
                      </rect>
                    </g>
                    <g transform="rotate(150 50 50)">
                      <rect
                        x={47}
                        y={24}
                        rx={3}
                        ry={6}
                        width={6}
                        height={12}
                        fill="#ffffff"
                      >
                        <animate
                          attributeName="opacity"
                          values="1;0"
                          keyTimes="0;1"
                          dur="1s"
                          begin="-0.5s"
                          repeatCount="indefinite"
                        />
                      </rect>
                    </g>
                    <g transform="rotate(180 50 50)">
                      <rect
                        x={47}
                        y={24}
                        rx={3}
                        ry={6}
                        width={6}
                        height={12}
                        fill="#ffffff"
                      >
                        <animate
                          attributeName="opacity"
                          values="1;0"
                          keyTimes="0;1"
                          dur="1s"
                          begin="-0.4166666666666667s"
                          repeatCount="indefinite"
                        />
                      </rect>
                    </g>
                    <g transform="rotate(210 50 50)">
                      <rect
                        x={47}
                        y={24}
                        rx={3}
                        ry={6}
                        width={6}
                        height={12}
                        fill="#ffffff"
                      >
                        <animate
                          attributeName="opacity"
                          values="1;0"
                          keyTimes="0;1"
                          dur="1s"
                          begin="-0.3333333333333333s"
                          repeatCount="indefinite"
                        />
                      </rect>
                    </g>
                    <g transform="rotate(240 50 50)">
                      <rect
                        x={47}
                        y={24}
                        rx={3}
                        ry={6}
                        width={6}
                        height={12}
                        fill="#ffffff"
                      >
                        <animate
                          attributeName="opacity"
                          values="1;0"
                          keyTimes="0;1"
                          dur="1s"
                          begin="-0.25s"
                          repeatCount="indefinite"
                        />
                      </rect>
                    </g>
                    <g transform="rotate(270 50 50)">
                      <rect
                        x={47}
                        y={24}
                        rx={3}
                        ry={6}
                        width={6}
                        height={12}
                        fill="#ffffff"
                      >
                        <animate
                          attributeName="opacity"
                          values="1;0"
                          keyTimes="0;1"
                          dur="1s"
                          begin="-0.16666666666666666s"
                          repeatCount="indefinite"
                        />
                      </rect>
                    </g>
                    <g transform="rotate(300 50 50)">
                      <rect
                        x={47}
                        y={24}
                        rx={3}
                        ry={6}
                        width={6}
                        height={12}
                        fill="#ffffff"
                      >
                        <animate
                          attributeName="opacity"
                          values="1;0"
                          keyTimes="0;1"
                          dur="1s"
                          begin="-0.08333333333333333s"
                          repeatCount="indefinite"
                        />
                      </rect>
                    </g>
                    <g transform="rotate(330 50 50)">
                      <rect
                        x={47}
                        y={24}
                        rx={3}
                        ry={6}
                        width={6}
                        height={12}
                        fill="#ffffff"
                      >
                        <animate
                          attributeName="opacity"
                          values="1;0"
                          keyTimes="0;1"
                          dur="1s"
                          begin="0s"
                          repeatCount="indefinite"
                        />
                      </rect>
                    </g>
                  </svg>
                </button>
              </div>
            </div>

            <Modal
              show={showPaymentRequirements}
              onHide={handleClosePasswordRequirements}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Uw wachtwoord moet voldoen aan de volgende eisen:
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Minimaal 10 karakters</p>
                <p>Minimaal 1 hoofdletter</p>
                <p>Minimaal 1 kleineletter</p>
                <p>Minimaal 1 speciaal teken (@$!%*?&)</p>
                <p>Minimaal 1 cijfer</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleClosePasswordRequirements}
                >
                  Sluiten
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterMember;
