import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiDotsHorizontal } from "react-icons/hi";
import { FaTrashAlt } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import Swal from "sweetalert2";

import { disable_member } from "../../functions/api";

//Css
import "../../scss/teams/dropdown.scss";

const MemberMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const navigate = useNavigate();
  const DeleteMember = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success mx-2",
        cancelButton: "btn btn-danger mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Weet je zeker dat je het teamlid wilt verwijderen?",
        text: "Het is niet mogelijk om terug te gaan.",
        showCancelButton: true,
        confirmButtonText: "Ja, Verwijder!",
        cancelButtonText: "Nee, Annuleer!",
        reverseButtons: true,
        preConfirm: (id) => {
          if (id === false) return Swal.DismissReason.cancel;
          disable_member({ userid: props.id });
          document.getElementById(props.id).style.display = "none";
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire(
            "Verwijderd!",
            "Het teamlid is verwijderd."
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Geannuleerd",
            "Teamlid is niet verwijderd."
          );
        }
      });
  };

  return (
    <div
      className={`member-menu ${isOpen ? "is-open" : ""}`}
      onMouseLeave={handleMouseLeave}
    >
      <HiDotsHorizontal
        size={25}
        className="dropdown-toggle"
        onClick={toggleMenu}
      />
      <ul className="dropdown-menu">
        <li>
          <a
            className="px-2 py-1"
            onClick={() =>
              navigate(`/teams/edit_member/${props.id}`, {
                state: {
                  ownerId: props.ownerId,
                  id: props.id,
                  first_name: props.first_name,
                  last_name: props.last_name,
                  job_title: props.job_title,
                  infix: props.infix,
                },
              })
            }
          >
            <span className="flex flex-row text-sm">
              <MdEdit className="mt-[5px] mr-1" /> Bewerken
            </span>
          </a>
        </li>
        <li style={{ display: props.ownerId === props.id ? "none" : "" }}>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => DeleteMember(props.id)}
          >
            <FaTrashAlt /> Verwijderen
          </a>
        </li>
      </ul>
    </div>
  );
};

export default MemberMenu;
