import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../scss/reports/select-report.scss";

// Navigation
import Menu from "../navigation/Menu";
import Nav from "../navigation/Nav";
import { get_all_customers } from "../../functions/api";

function Customers() {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState({
    field: "klantnaam",
    order: "asc",
  });

  const thStyle =
    "px-4 py-3 border-b-2 border-gray-200 bg-gray-50 text-left text-lg font-semibold text-gray-600 cursor-pointer";
  const tdStyle = "px-4 py-3 border-b border-gray-200 text-lg";

  useEffect(() => {
    get_all_customers()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setCustomers(data);
        setFilteredCustomers(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setLoading(false);
      });
  }, []);

  const handleSearch = (term) => {
    setSearchTerm(term);
    const lowercasedFilter = term.toLowerCase();
    const filteredData = customers.filter(
      (customer) =>
        customer.klantnaam.toLowerCase().includes(lowercasedFilter) ||
        customer.bedrijfsnaam.toLowerCase().includes(lowercasedFilter) ||
        customer.email.toLowerCase().includes(lowercasedFilter) ||
        customer.website.toLowerCase().includes(lowercasedFilter)
    );
    handleSort(sortOrder.field, sortOrder.order, filteredData);
  };

  const handleSort = (field, order, data = filteredCustomers) => {
    const sortedData = [...data].sort((a, b) => {
      const valueA = a[field].toLowerCase();
      const valueB = b[field].toLowerCase();
      if (valueA < valueB) return order === "asc" ? -1 : 1;
      if (valueA > valueB) return order === "asc" ? 1 : -1;
      return 0;
    });
    setFilteredCustomers(sortedData);
    setSortOrder({ field, order });
  };

  const resetFilters = () => {
    setSearchTerm("");
    setSortOrder({ field: "klantnaam", order: "asc" });
    setFilteredCustomers(customers);
  };

  const customerList = () => {
    if (filteredCustomers.length > 0) {
      return filteredCustomers.map((customer) => (
        <tr
          key={customer.id}
          className="hover:bg-gray-100 transition-colors duration-200 cursor-pointer"
          onClick={() => {
            navigate("/customers/edit_customer", {
              state: { customer },
            });
          }}
        >
          <td className={tdStyle}>{customer.klantnaam}</td>
          <td className={tdStyle}>{customer.bedrijfsnaam}</td>
          <td className={tdStyle}>{customer.telefoon}</td>
          <td className={tdStyle}>{customer.email}</td>
          <td className={tdStyle}>{customer.website}</td>
        </tr>
      ));
    }
    return (
      <tr>
        <td colSpan="5" className="px-4 py-2 text-center">
          Geen klanten gevonden
        </td>
      </tr>
    );
  };

  return (
    <div className="select-report-container-main">
      <Nav page="Klanten" />
      <div className="page-wrap-row">
        <Menu className="menu-container" />
        <div className="select-report-container p-4">
          <h1 className="text-2xl font-semibold mb-4 ml-10 mt-10">Klanten</h1>
          <div className="px-10 mb-4 flex justify-between items-center">
            <input
              type="text"
              placeholder="Zoek op naam, bedrijfsnaam, email of website..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="px-4 py-2 border rounded-md shadow-sm w-1/3"
            />
            <div className="flex space-x-4">
              <button
                onClick={() =>
                  handleSort(
                    "klantnaam",
                    sortOrder.order === "asc" ? "desc" : "asc"
                  )
                }
                className="px-4 py-2 border rounded-md shadow-sm"
              >
                Sorteer op Naam{" "}
                {sortOrder.field === "klantnaam" &&
                  (sortOrder.order === "asc" ? "▲" : "▼")}
              </button>
              <button
                onClick={() =>
                  handleSort(
                    "bedrijfsnaam",
                    sortOrder.order === "asc" ? "desc" : "asc"
                  )
                }
                className="px-4 py-2 border rounded-md shadow-sm"
              >
                Sorteer op Bedrijfsnaam{" "}
                {sortOrder.field === "bedrijfsnaam" &&
                  (sortOrder.order === "asc" ? "▲" : "▼")}
              </button>
              <button
                onClick={resetFilters}
                className="px-4 py-2 border rounded-md shadow-sm bg-red-500 text-white"
              >
                Reset Filters
              </button>
              <Link
                to="/customers/create"
                className="px-4 py-2 border rounded-md shadow-sm text-white"
                style={{ backgroundColor: "#060a24" }}
              >
                Aanmaken
              </Link>
            </div>
          </div>
          <div className="select-report-container-1">
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr>
                    <th className={thStyle}>Naam</th>
                    <th className={thStyle}>Bedrijfsnaam</th>
                    <th className={thStyle}>Telefoon</th>
                    <th className={thStyle}>Email</th>
                    <th className={thStyle}>Website</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={5} className="px-4 py-2 text-center">
                        Laden...
                      </td>
                    </tr>
                  ) : (
                    customerList()
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customers;
