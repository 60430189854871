import React, { useState, useEffect } from "react";
import Select from "react-select";

// Get Teksten
import { get_all_normen } from "../../functions/api";

function Normen(props) {
  const [loading, setLoading] = useState(true);

  let [uitgangspunten, setUitgangspunten] = useState([]);
  let [options, setOptions] = useState();
  let [selectedNormen, setSelectedNormen] = useState();

  let array1 = props.defaults;

  function onSelectedKenmerkenZichtbaarChange(
    SelectedItemsUitgangspuntentZichtbaarChange
  ) {
    // get array of actual

    let index = 0;
    array1.forEach((item) => {
      let founded = false;

      SelectedItemsUitgangspuntentZichtbaarChange.forEach((item_a) => {
        if (item_a.value === item.normid) {
          founded = true;
        }
      });

      // if not founded, delete
      if (!founded) {
        array1.splice(index, 1);
      }

      index++;
    });

    SelectedItemsUitgangspuntentZichtbaarChange.forEach((item_a) => {
      let founded = false;

      array1.forEach((item) => {
        if (item_a.value === item.normid) {
          founded = true;
        } else {
        }
      });

      if (!founded) {
        // get uitgangspunten omschrijving
        let value_uitgangspunten = uitgangspunten.find(
          (object) => object.normid === item_a.value
        );

        let omschrijving_waarde = value_uitgangspunten.omschrijving;
        let toelichting_waarde = value_uitgangspunten.toelichting;
        let hoofdstuk = value_uitgangspunten.hoofdstuk;
        // create object
        let object_aansluitgegeven = {
          normid: item_a.value,
          omschrijving: omschrijving_waarde,
          toelichting: toelichting_waarde,
          hoofdstuk: hoofdstuk,
        };
        array1.push(object_aansluitgegeven);
      }
    });
    // set the object to the state
    props.setArray(array1);
  }

  let array = props.defaults;

  const renderUitgangspunten1 = () => {
    if (array && array !== undefined) {
      const uitgangspunten1 = array.map((object) => {
        let index_uitgangspunten = array.findIndex(
          (object_uitgansgspunten) =>
            object_uitgansgspunten.normid === object.normid
        );
        return (
          <div id={object.normid} key={object.normid}>
            <div className="rapportage-input-col">
              <label>{object.omschrijving}</label>

              <textarea
                spellCheck="false"
                className="rapportage-textarea-normen"
                defaultValue={object.toelichting}
                onChange={(text) => {
                  let arrayUitgansgspunten = array;
                  arrayUitgansgspunten[index_uitgangspunten] = {
                    normid: object.normid,
                    omschrijving: object.omschrijving,
                    toelichting: text.target.value,
                    hoofdstuk: object.hoofdstuk,
                  };

                  props.setArray(arrayUitgansgspunten);
                }}
              />
            </div>
          </div>
        );
      });
      return uitgangspunten1;
    }
  };
  useEffect(() => {
    get_all_normen()
      .then((normen) => {
        if (normen.ok) {
          return normen.json();
        }
        throw normen;
      })
      .then((data) => {
        setUitgangspunten(data);

        const options = data.map((object) => {
          return {
            value: object.normid,
            label: object.omschrijving,
          };
        });
        setOptions(options);
        let selectedNormen = undefined;
        // Check if the defaults prop is empty or not
        if (props.defaults && props.defaults !== undefined) {
          // Map through the array and filter to check if there's an option selected already
          selectedNormen = props.defaults.map((object) => {
            let currentOption = options.filter(
              (obj_option) => obj_option.value === object.normid
            );

            return {
              value: currentOption[0].value,
              label: currentOption[0].label,
            };
          });
        }
        setSelectedNormen(selectedNormen);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading === true) {
    return <div></div>;
  }
  if (loading === false) {
    return (
      <div>
        <Select
          defaultValue={selectedNormen}
          options={options}
          onChange={(e) => {
            onSelectedKenmerkenZichtbaarChange(e);
            props.toParent(e.map((object) => object.value));
          }}
          isClearable={false}
          isMulti
          closeMenuOnSelect={false}
        />
        <div className="rapportage-input-row">{renderUitgangspunten1()}</div>
      </div>
    );
  }
}
export default Normen;
