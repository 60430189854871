import React from 'react'
import { NavLink } from "react-router-dom";


//css
import '../../scss/registration/registration_layout.scss';

//Components
import LoginForm from './Login_forum';

const Login_layout = () => {
    return (
        <div className='page-wrapper'>
            <div className="register-content-wrapper">
                <div className="left-section">
                    <div className="left-title">
                        <h1><strong>E<span>Z</span></strong> Inspect</h1>
                    </div>
                    <div className="left-text">
                        <h2>Login</h2>
                        <p>Gebruik onze inspectie module om eenvoudig inspecties aan te maken. Voeg je gebreken toe, stel een rapport op en klaar!</p>
                        <div className="already-account-alert">
                            <p>Heb je nog geen account? Registreer je dan <NavLink to="/">hier</NavLink></p>
                        </div>
                    </div>
                </div>
                <div className="right-section-login">
                    <div className="login-wrapper">
                        <LoginForm />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login_layout
