import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";

//Css
import "../../scss/registration/registration_forum.scss";

//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

//Modal
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

//Api functions
import { resetPassword } from "../../functions/api";

const Forgot_password_renew = () => {
  const [passwordShown, setPasswordShown] = useState("");
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);

  const handleOpenPasswordRequirements = () =>
    setShowPasswordRequirements(true);
  const handleClosePasswordRequirements = () =>
    setShowPasswordRequirements(false);

  let [userInput, setUserInput] = useState([
    {
      form_step_1: {
        password1: "",
        password2: "",
      },
    },
  ]);
  let [passwordStatus, setPasswordStatus] = useState("");
  const navigate = useNavigate();

  const addUserInput = (inputName, inputValue) => {
    switch (inputName) {
      case "password1":
        userInput[0].form_step_1.password1 = inputValue;
        setUserInput(userInput);
        break;
      case "password2":
        userInput[0].form_step_1.password2 = inputValue;
        setUserInput(userInput);
        break;
      default:
        break;
    }
  };

  const togglePassword = (passwordField) => {
    setPasswordShown(passwordField);
  };

  const reset_password = async () => {
    const password1 = userInput[0].form_step_1.password1;
    const password2 = userInput[0].form_step_1.password2;
    let params = new URL(document.location).searchParams;
    let token = params.get("id");
    const resetPasswordResponse = await resetPassword(
      password1,
      password2,
      token
    );
    console.log();
    setPasswordStatus(resetPasswordResponse.message);

    if (resetPasswordResponse.message === "success") {
      navigate("/login");
    }
  };

  //Submit on enter key
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter") {
        event.preventDefault();
        reset_password();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <div className="page-wrapper">
      <div className="register-content-wrapper">
        <div className="left-section">
          <div className="left-title">
            <h1>
              <strong>
                E<span>Z</span>
              </strong>{" "}
              Inspect
            </h1>
          </div>
          <div className="left-text">
            <h2>Reset uw wachtwoord</h2>
            <p>Vul het formulier in om je wachtwoord te veranderen.</p>
            <div className="already-account-alert">
              <p>
                Wil je inloggen? Klik dan op{" "}
                <NavLink to="/login">deze link</NavLink>
              </p>
            </div>
          </div>
        </div>
        <div className="right-section-forget-password">
          <div className="forget-password-form-wrapper">
            <div className="registration-body">
              <div className="input-section">
                <label htmlFor="">
                  Wachtwoord{" "}
                  <i
                    onClick={() => handleOpenPasswordRequirements(true)}
                    className="fa-solid fa-circle-question"
                  ></i>
                </label>
                <div className={"input-color"}>
                  <input
                    type={passwordShown === "1" ? "text" : "password"}
                    id="password"
                    onChange={(e) => addUserInput("password1", e.target.value)}
                  />
                  <div
                    onClick={
                      passwordShown !== "1"
                        ? () => togglePassword("1")
                        : () => togglePassword("")
                    }
                    className="password-toggle"
                  >
                    <i
                      className={
                        passwordShown === "1"
                          ? "fa-solid fa-eye"
                          : "fa-solid fa-eye-slash"
                      }
                    ></i>
                  </div>
                </div>
                {/* <p className="validationMessage" id="password1">{displayValidationErrorMessage(1, "password1")}</p> */}
              </div>
              <div className="input-section">
                <div className={"input-color"}>
                  <input
                    type={passwordShown === "2" ? "text" : "password"}
                    id="password"
                    onChange={(e) => addUserInput("password2", e.target.value)}
                  />
                  <div
                    onClick={
                      passwordShown !== "2"
                        ? () => togglePassword("2")
                        : () => togglePassword("")
                    }
                    className="password-toggle"
                  >
                    <i
                      className={
                        passwordShown === "2"
                          ? "fa-solid fa-eye"
                          : "fa-solid fa-eye-slash"
                      }
                    ></i>
                  </div>
                </div>
                {/* <p className="validationMessage" id="password1">{displayValidationErrorMessage(1, "password1")}</p> */}
              </div>
              <div
                className={
                  passwordStatus === "password-not-equal"
                    ? "login-error-message"
                    : "hide-error-message"
                }
              >
                <div className="text-wrapper">
                  <p>De wachtwoorden komen niet overheen</p>
                </div>
              </div>
              <div
                className={
                  passwordStatus === "password-not-strong-enough"
                    ? "login-error-message"
                    : "hide-error-message"
                }
              >
                <div className="text-wrapper">
                  <p>Het wachtwoord is niet sterk genoeg</p>
                </div>
              </div>
              <div
                className={
                  passwordStatus === "invalid_id"
                    ? "login-error-message"
                    : "hide-error-message"
                }
              >
                <div className="text-wrapper">
                  <p>Ongeldige user id</p>
                </div>
              </div>
            </div>

            <div className="registration-footer">
              <div className="footer-wrapper">
                <button
                  className="next-step-btn"
                  onClick={(e) => reset_password()}
                >
                  Submit
                  <svg
                    width="23"
                    height="16"
                    viewBox="0 0 23 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.7071 8.77595C23.0976 8.38543 23.0976 7.75227 22.7071 7.36174L16.3431 0.99778C15.9526 0.607255 15.3195 0.607255 14.9289 0.99778C14.5384 1.3883 14.5384 2.02147 14.9289 2.41199L20.5858 8.06885L14.9289 13.7257C14.5384 14.1162 14.5384 14.7494 14.9289 15.1399C15.3195 15.5304 15.9526 15.5304 16.3431 15.1399L22.7071 8.77595ZM0 9.06885H22V7.06885H0V9.06885Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showPasswordRequirements}
        onHide={handleOpenPasswordRequirements}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Uw wachtwoord moet voldoen aan de volgende eisen:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Minimaal 10 karakters</p>
          <p>Minimaal 1 hoofdletter</p>
          <p>Minimaal 1 kleineletter</p>
          <p>Minimaal 1 speciaal teken (@$!%*?&)</p>
          <p>Minimaal 1 cijfer</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePasswordRequirements}>
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Forgot_password_renew;
