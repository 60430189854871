import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { get_one_inspection, update_inspectie } from "../../../functions/api";
import Menu from "../../navigation/Menu";
import Nav from "../../navigation/Nav";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MediaItem } from "./MediaItem";
export default function EditInspections() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [inspection, setInspection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const fetchInspection = async (id) => {
    if (id) {
      const response = await get_one_inspection(id);
      if (response.ok) {
        const data = await response.json();
        if (await data) {
          setIsLoading(false); // Stop loading regardless of success/failure
          setInspection(data); // Set the resolved data to the state
        }
      } else {
        throw new Error("Failed to fetch inspection data");
      }
    }
  };

  useEffect(() => {
    fetchInspection(id); // Call the async function within the useEffect
  }, [id]);

  // const handleDateChange = (field, date) => {
  //   setInspection((prevInspection) => ({
  //     ...prevInspection,
  //     [field]: date ? format(date, "yyyy-MM-dd'T'HH:mm") : "",
  //   }));
  // };

  const handleObjectFieldChange = (subField, value) => {
    setInspection((prevInspection) => ({
      ...prevInspection,
      object: {
        ...prevInspection.object,
        [subField]: value,
      },
    }));
  };

  const handleGebrekChange = (index, field, value) => {
    const updatedGebreken = [...inspection.gebreken];
    updatedGebreken[index] = { ...updatedGebreken[index], [field]: value };
    setInspection((prevInspection) => ({
      ...prevInspection,
      gebreken: updatedGebreken,
    }));
  };

  const renderGebrekColor = (gebrek) => {
    switch (gebrek.gebrekcode.naam) {
      case "Rood":
        return "red";
      case "Oranje":
        return "orange";
      case "Geel":
        return "#FFC72C";
      case "Groen":
        return "green";
      case "Paars":
        return "purple";
      default:
        return "black";
    }
  };

  // const handleRemoveGebrek = (index) => {
  //   const updatedGebreken = inspection.gebreken.filter((_, i) => i !== index);
  //   setInspection((prevInspection) => ({
  //     ...prevInspection,
  //     gebreken: updatedGebreken,
  //   }));
  // };

  const handleSubmit = async () => {
    const updatedInspection = {
      ...inspection,
    };

    await update_inspectie(updatedInspection)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data) {
          alert("Inspectie is succesvol bijgewerkt.");
          return navigate("/inspections");
        }
      })
      .catch((error) => {
        console.error("Error updating inspection:", error);
      });
  };

  const renderMedia = (media) => {
    if (!media) return null;
    return media.map((mediaItem) => {
      return <MediaItem key={mediaItem.mediaid} mediaItem={mediaItem} />;
    });
  };

  const renderGebreken = () => {
    if (!isLoading && inspection.gebreken) {
      return inspection.gebreken.map((gebrek, index) => (
        <div
          key={gebrek.gebrekid}
          className="flex flex-col w-full space-y-4 border-b pb-4 mb-4"
        >
          <p
            className="text-base font-semibold"
            style={{
              color: renderGebrekColor(gebrek),
            }}
          >
            Gebrek {gebrek.gebrekid}
          </p>
          <div className="flex flex-col space-y-2">
            <label className="text-base font-semibold text-gray-700">
              Omschrijving:
            </label>
            <input
              type="text"
              value={gebrek.omschrijving}
              onChange={(e) =>
                handleGebrekChange(index, "omschrijving", e.target.value)
              }
              className="p-3 border rounded-md shadow-sm focus:ring focus:ring-blue-300"
            />
          </div>
          <div className="flex flex-col space-y-2">
            <label className="text-base font-semibold text-gray-700">
              Toelichting:
            </label>
            <input
              type="text"
              value={gebrek.toelichting}
              placeholder="Vul hier een toelichting in"
              onChange={(e) =>
                handleGebrekChange(index, "toelichting", e.target.value)
              }
              className="p-3 border rounded-md shadow-sm focus:ring focus:ring-blue-300"
            />
          </div>

          {/* Locatie rendereing */}
          {gebrek.locaties && gebrek.locaties.length > 0 && (
            <h2 className="text-base font-semibold text-gray-700">Locatie:</h2>
          )}
          <div className="flex flex-row flex-wrap gap-2">
            {gebrek.locaties &&
              gebrek.locaties.length > 0 &&
              gebrek.locaties.map((locatie) => (
                <div
                  key={locatie.locatieid}
                  className="flex flex-row flex-wrap gap-2"
                >
                  <input
                    type="text"
                    value={locatie.locatienaam}
                    disabled
                    className="p-3 border rounded-md shadow-sm bg-gray-200"
                  />
                </div>
              ))}
          </div>
          {/* Media rendering */}
          <div className="flex flex-col space-y-2">
            {gebrek.media && gebrek.media.length > 0 && (
              <h2 className="text-base font-semibold text-gray-700">Media:</h2>
            )}
            {gebrek.media && gebrek.media.length > 0 && (
              <div className="flex flex-wrap gap-4">
                {renderMedia(gebrek.media)}
              </div>
            )}
            {gebrek.gevaarlijkesituatie &&
              gebrek.gevaarlijkesituatie.length > 0 && (
                <>
                  <h2 className="text-base font-semibold text-gray-700">
                    Gevaarlijke situatie gevonden
                  </h2>
                  <p>Gevaarlijke situatie is ondertekend door:</p>
                  <div className="flex flex-wrap">
                    {renderMedia(gebrek.media)}
                  </div>
                </>
              )}
          </div>

          {/* <div className="flex justify-end">
            <button
              onClick={() => handleRemoveGebrek(index)}
              className="px-4 py-2 bg-red-500 text-white font-semibold rounded-md shadow-sm hover:bg-red-600"
            >
              Verwijder gebrek
            </button>
          </div> */}
        </div>
      ));
    }
    return null;
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return ""; // Return empty string for null or invalid dates
    if (dateString === "0000-00-00 00:00:00") return ""; // Return empty string for invalid dates
    const date = new Date(dateString);
    return date;
  };

  if (isLoading) {
    return <p className="text-center mt-10 text-black">Loading...</p>;
  }

  if (!inspection) {
    return (
      <div className="select-report-container-main">
        <Nav page="Inspecties" />
        <div className="page-wrap-row">
          <Menu className="menu-container" />
          <div className="ml-25 px-20 w-full mt-20 text-black bg-gray-100 flex flex-col space-y-8">
            <h1 className="text-4xl font-semibold text-center">
              Inspectie data niet gevonden
            </h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="select-report-container-main">
      <Nav page="Inspecties" />
      <div className="page-wrap-row">
        <Menu className="menu-container" />
        <div className="ml-40 px-20 w-full mt-20 text-black bg-gray-100 flex flex-col space-y-8">
          <div className="flex flex-row justify-between">
            <h1 className="text-3xl font-semibold">Inspectie bewerken</h1>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-4 ">
              <h1 className="text-xl font-semibold text-black">
                Inspectie gegevens:
              </h1>
              {/* Display but disable editing for klant */}
              <label className="text-base font-semibold text-gray-700">
                Klantnaam:
              </label>
              <input
                type="text"
                value={inspection?.klant?.naam || ""}
                className="p-3 border rounded-md shadow-sm bg-gray-200" // Disabled styling
                disabled
              />
              <label className="text-base font-semibold text-gray-700">
                Objectnaam:
              </label>
              <input
                type="text"
                value={inspection?.object?.naam || ""}
                onChange={(e) =>
                  handleObjectFieldChange("naam", e.target.value)
                }
                disabled
                className="p-3 border rounded-md shadow-sm bg-gray-200" // Disabled styling
              />
              <div className="flex flex-col">
                <label className="text-base font-semibold text-gray-700">
                  Startdatum:
                </label>
                <DatePicker
                  disabled
                  selected={formatDateForInput(inspection.datumstart)}
                  showTimeSelect
                  placeholderText="Er is geen startdatum bekend"
                  dateFormat="Pp"
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  className="p-3 border rounded-md shadow-sm bg-gray-200 focus:ring focus:ring-blue-300 w-full"
                  locale="nl"
                />
              </div>
            </div>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col space-y-2">
                <h1 className="text-xl font-semibold text-black">Gebreken:</h1>
                {renderGebreken()}
              </div>
            </div>

            <div className="flex justify-start space-x-4 my-4">
              <button
                onClick={handleSubmit}
                className="px-8 py-2 bg-red-500 text-white font-semibold rounded-md shadow-sm hover:bg-red-600"
              >
                Opslaan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
