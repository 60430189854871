import React from 'react'
import { NavLink } from "react-router-dom";

//css
import '../../scss/registration/registration_layout.scss';

//Components
import RegistrationForum from './Registration_forum';

const Registration_layout = () => {
    return (
        <div className='page-wrapper'>
            <div className="register-content-wrapper">
                <div className="left-section">
                    <div className="left-title">
                        <h1><strong>E<span>Z</span></strong> Inspect</h1>
                    </div>
                    <div className="left-text">
                        <h2>Registratie</h2>
                        <p>De eenvoudige inspectie app voor scope 8, scope 10 en scope 12! Registreer je nu en probeer 14 dagen gratis.</p>
                        <div className="already-account-alert">
                            <p>Heb je al een account? Log dan in via <NavLink className="login-nav-link" to="/login">deze link</NavLink></p>
                        </div>
                    </div>
                </div>
                <div className="right-section">
                    <div className="registration-form-wrapper">
                        <RegistrationForum />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Registration_layout
