import React from "react";
import { NavLink } from "react-router-dom";

//css
import "../../scss/registration/registration_layout.scss";

const Forgot_password_finish = () => {
  // let registrationSuccess = sessionStorage.getItem('registrationStatus');

  sessionStorage.removeItem("registrationStatus");

  return (
    <div className="page-wrapper">
      <div className="register-content-wrapper">
        <div className="left-section">
          <div className="left-title">
            <h1>
              <strong>
                E<span>Z</span>
              </strong>{" "}
              Inspect
            </h1>
          </div>
          <div className="left-text">
            <div className="finish-text-wrapper">
              <h2>Dankjewel!</h2>
              <h3>Er is een email naar u verzonden</h3>
              <div className="trial-message">
                <p>
                  Klik <NavLink to="/login">hier</NavLink> om in te loggen
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="right-section-forget-password">
          <div className="forgot-password-finish">
            <div className="finish-title">
              <h1>Er is een email verzonden</h1>
            </div>
            <div className="finish-body-text">
              <p>
                Het kan 1 a 2 minuten duren voordat de mail binnenkomt. Na 1 of
                2 minuten nog steeds niet ontvangen? Check dan uw spam.
              </p>
            </div>
            <div className="finish-body-text">
              {/* <p>Download nu de app om te starten met de eenvoudigste inspectie app.</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot_password_finish;
