import React, { useState, useEffect } from "react";
import Select from "react-select";

// Get object functies
import { get_all_aansluitgegevens } from "../../functions/api";
import ObjectFunctions from "./ObjectFunctions";

function Aansluitgegevens(props) {
  const [loading, setLoading] = useState(true);

  let [options, setOptions] = useState();
  let [aansluitgegevensOptions, setAansluitgegevensOptions] = useState([]);
  let [selectedAansluitid, setSelectedAansluitid] = useState();
  var aansluitgegevens_array1 = props.defaults;

  function onSelectedKenmerkenZichtbaarChange(
    SelectedItemsKenmerkenZichtbaarChange
  ) {
    // get array of actual

    let index = 0;
    aansluitgegevens_array1.forEach((item) => {
      let founded = false;

      SelectedItemsKenmerkenZichtbaarChange.forEach((item_a) => {
        if (item_a.value === item.aansluitgegevenid) {
          founded = true;
        }
      });

      // if not founded, delete
      if (!founded) {
        aansluitgegevens_array1.splice(index, 1);
      }

      index++;
    });

    SelectedItemsKenmerkenZichtbaarChange.forEach((item_a) => {
      let founded = false;

      aansluitgegevens_array1.forEach((item) => {
        if (item_a.value === item.aansluitgegevenid) {
          founded = true;
        } else {
        }
      });

      if (!founded) {
        // get aansluitgegevens omschrijving
        let value_aansluitobject = aansluitgegevensOptions.find(
          (object) => object.aansluitgegevenid === item_a.value
        );

        let omschrijving_waarde = value_aansluitobject.omschrijving;
        let toelichting_waarde = value_aansluitobject.toelichting;

        // create object
        let object_aansluitgegeven = {
          aansluitgegevenid: item_a.value,
          omschrijving: omschrijving_waarde,
          toelichting: toelichting_waarde,
        };
        aansluitgegevens_array1.push(object_aansluitgegeven);
      }
    });
    // set the object to the state
    props.setArray(aansluitgegevens_array1);
  }

  const array = props.defaults;
  // This function will
  const aansluitgegevenRender = () => {
    const aansluitgegeven_onderdelen = array.map((object) => {
      let index_aansluitgegeven = array.findIndex(
        (object_aansluitgegeven) =>
          object.aansluitgegevenid === object_aansluitgegeven.aansluitgegevenid
      );
      return (
        <div className="rapportage-input-col" key={object.aansluitgegevenid}>
          <label>{object.omschrijving}</label>

          <textarea
            className="rapportage-textarea-normen"
            spellCheck="false"
            defaultValue={object.toelichting}
            onChange={(text) => {
              let array_aansluitgegevens = array;
              array_aansluitgegevens[index_aansluitgegeven] = {
                aansluitgegevenid: object.aansluitgegevenid,
                omschrijving: object.omschrijving,
                toelichting: text.target.value,
              };

              props.setArray(array_aansluitgegevens);
            }}
          ></textarea>
        </div>
      );
    });
    return aansluitgegeven_onderdelen;
  };

  useEffect(() => {
    get_all_aansluitgegevens()
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setAansluitgegevensOptions(data);
        const options = data.map((object) => {
          return {
            value: object.aansluitgegevenid,
            label: object.omschrijving,
          };
        });
        setOptions(options);
        let selectedaansluitid = undefined;

        // Check if the defaults props is empty or not
        if (props.defaults && props.defaults !== undefined) {
          // Map through the array and filter to check if there's an option selected already.
          selectedaansluitid = props.defaults.map((object) => {
            let selectedOptions = options.filter(
              (obj_options) => obj_options.value === object.aansluitgegevenid
            );

            return {
              value: selectedOptions[0].value,
              label: selectedOptions[0].label,
            };
          });
        }
        setSelectedAansluitid(selectedaansluitid);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading === true) {
    return <div></div>;
  }
  if (loading === false) {
    return (
      <div id="installatie-parent">
        <label className="label-inspectie">Functies</label>
        <ObjectFunctions
          defaults={props.defaults2}
          toParent={props.toParent2}
        />
        <label className="label-inspectie">Gegevens installatie</label>
        <Select
          defaultValue={selectedAansluitid}
          options={options}
          onChange={(e) => {
            onSelectedKenmerkenZichtbaarChange(e);
            props.toParent(e.map((object) => object.value));
          }}
          isClearable={false}
          isMulti
          closeMenuOnSelect={false}
        />
        <div className="rapportage-input-row">{aansluitgegevenRender()}</div>
      </div>
    );
  }
}

export default Aansluitgegevens;
