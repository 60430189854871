import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRouteDashboard = ({ children, is_auth }) => {
  return is_auth === 200 || is_auth === "200" ? (
    children
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

export default PrivateRouteDashboard;
